<template>
  <div class="empty-data-container ">
      <img src="@/assets/404-no-data-found.jpg" v-if="showImage"/>
      <h2 v-if="error!=1">{{$t('sorry')}}</h2>
      <p v-if="error!=1">{{msg}}</p>
      <h2 v-else>{{msg}}</h2>
  </div>
</template>

<script>
export default {
  name: "EmptyData",
  computed: {
    msg() {
      if(this.error == 1)
        return this.$t('no_data_found')
      else  if(this.error == 2)
        return this.$t('something_went_wrong')
      else 
        return this.error
    }
  },
  props: {
    error:{
      default: () => 2
    }, 
    showImage: {
      default: () => false
    }
  }
}
</script>

<style scoped>
.empty-data-container {
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 3;
  height: 96%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* background-color: white; */
}

.empty-data-container img {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .empty-data-container img {
    width: 100%;
  }
}

</style>
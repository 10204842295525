import { getCookie } from '../utils';
import ApiRequests from "./ApiRequests";
export default {

  getUserInfo() {

    return  ApiRequests.apiRequest('get','portal/getUserInfo',null,null).then(data =>{return data})

  },


  getNotifyInfo(meter) {
    return  ApiRequests.apiRequest('get','portal/getNotifyInfo',
    {params:{
      user_id:`${getCookie('user_id')}`
      ,req_pk:meter}}
      ,null)
      .then(data =>{return data})

  },

  
  async updatePersonalInfo(form) {
    delete form.re_password;
    return  ApiRequests.apiRequest('post',`portal/updatePassword`,null,form,true,true)// true => auth request , true =>//means return code in false case
    .then(data=>{
   if(data==null) return true;
   else return data;
    })
 
  },

  async updateNotificationsSettings(form) {

    return  ApiRequests.apiRequest('post',`portal/setNotifyInfo`,null,form)
    .then(data=>{
      // console.log(data);
      if(data===null) return true;
    else return data;
    })
   
  },
};
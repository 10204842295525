<template>
  <div class="cons">
    <div class="card my-3" style="min-height: 200px">
      <b-row>
        <b-col sm="12">
          <h4
            :class="{
              'title text-end mb-4': lang ==1,
              'title text-start mb-4': lang ==2,
            }"        
          >
            {{ $t("consumption") }}
            <span  :class="{
                      'text-end ' : lang ==1,
                      'text-start': lang ==2,
                    }" > {{unit}}</span>
          </h4>
        </b-col>

        <b-col md="12" class="relative">
          <div class="">
            <loading v-if="consumptionLoading" />
            <ErrorHandler v-if="consumptionError" :error="errorCode"/>

            <CustomeTable
              v-if="!consumptionError"
              :headers="headers"
              :items="consumption"
              :showPager="false"
              :perPage="4"
              :show="show"
              :lang="lang"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Loading from "@/components/Loading.vue";
import CustomeTable from "@/components/CustomeTable.vue";
import ErrorHandler from "../ErrorHandler.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    Loading,
    ErrorHandler,
    CustomeTable,
  },
  props: {
    code: {
      default: () => " ",
    },
    consumptionArray:{
      default: () => "",
    },
    lang:{
      default: () => "",
    },
    consumption_error_msg:{
      default: () => {}
    },
    cons_error:{
      default: () => {}
    },
    meter_unit:{
      default: () => {}
    },
  },
  data() {
    return {
      consumption: [],
      consumptionLoading: true,
      consumptionError: false,
      mdm_utilites:0,
      errorCode: 2,
      unit:"",
      unity:'',
      show:false,
      headers: [
        {
          locale: "mdm_billing_cycle",
          notation: true,
          notation_locale: "month_date_notation",
        },
        {
          locale: "energy_diff",
          notation: true,
          notation_locale: "kwh",
        },
        {
          locale: "est_tariff_value",
          notation: true,
          notation_locale: "egp",
        },
      ],
    };
  },
  watch: {
    consumptionArray() {
      if (this.consumptionArray && this.consumptionArray != "") {
        this.initialize();
      }
    },
    meter_unit(){
      if (this.meter_unit && this.meter_unit != "") {
        this.consumptionLoading = true;
        this.unit=this.meter_unit;
      }
    },

    cons_error(){
          this.errorCode = this.consumption_error_msg;
          this.consumptionError = this.cons_error;
      if(this.cons_error==true){
          this.show=false;
          this.consumptionLoading = false;
          this.consumption = [];
      }
    },
   
    
  },
  created(){
    if (this.meter_unit && this.meter_unit != '') {
          this.unity=this.meter_unit;
    }
  },
  methods: {
    async   getConsumptionArray(){
    return this.consumptionArray;
    },
    initialize() {
      this.consumptionLoading = true;
        this.getConsumptionArray()
        .then((data) => {
          this.consumption = this.formatNumber(data);    
        })
        .then(() => {
          this.show=true;
          // setTimeout(()=>{ this.consumptionLoading = false},1000)
          this.consumptionLoading = false;
        })
///////////////////////////////////////////
    },

    formatNumber(data) {
      return data.map(month => {
        month.est_tariff_value= new Intl.NumberFormat('en-IN').format(month.est_tariff_value)
        month.energy_diff= new Intl.NumberFormat('en-IN').format(month.energy_diff)
        return month
      })
  },
     
  }

};
</script>

<style >
.custom-tooltip-conent-container {
  padding: 10px;
}

.custom-tooltip-date-container {
    background-color: rgb(233, 233, 233);
    padding: 5px;
}

.title {
      color: #002369;
    font-size: 16px;
    font-weight: 700;
}

h4 span{
  font-size: 12px;
  font-weight: 500;
}

table,
td,
th {
  border: 1px solid #ddd !important;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
  color: black;
}

th,
td {
  padding: 15px;
}

tr:hover {
  background-color: lightgray;
}
.titleTable {
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .table-responsive {
    font-size: 10px;
  }
}

#chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card{
  min-height: 200px;
    margin: 0px auto;
    max-width: 856px;
    border-radius: 8px !important;
}
/* .cons .relative{
  padding: 0px;
  border: 0px;
} */
</style>

<template>
  <div>
    <b-row style="--bs-gutter-x: 0">
      <b-col sm="12">
        
        <div v-if="loading">
          <loading />
        </div>
        
        <!-- <div v-if="error.status" style="margin-top:24px">
          <Feedback :state="2" :message="error.message" :query_found="false"/> 
        </div> -->

        <Invoice 
          v-if="loading == false && error.status == false" 
          :transactionData="transData" 
          :company="company" 
          :lang="lang"
          :receipt_services="services"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Invoice from "@/components/Recharge/Invoice.vue";

import rechargeService from "@/services/recharge";
import Loading from '@/components/Loading.vue';
import Feedback from '@/components/Feedback.vue';

export default {
  props: ["reachrgeform", "lang", "company", "tab"],

  components: {
    Invoice,
    Loading,
    Feedback
  },

  data() {
    return {
      id: null,
      transData: {},
      loading: true,
      error: {
        status: false,
        message: ''
      },
      services: null
    };
  },

  computed: {
    isReceipt() {
      if(this.$route.query.order_id)
        return false;
      else 
        return true
    }
  },
  methods: {
    getTransactoinData() {
      this.id = this.$route.params.order_id;
        rechargeService
        .checkPayment({ order_id: this.$route.query.order_id },true)
        .then((data) => {
          // data.result='FAILURE'
          if(data.result=='FAILURE'){
            this.$router.push({ name: 'Home', query: {order_id:data.id,message:'failure' } });
          }
          else{
            let date = new Date(data.creationTime).toLocaleString("en");
          this.transData = {
            customer_name: data.customer.name,
            account_reference: data.account_reference,
            charge_token: data.charge_token,
            id: data.id,
            creationTime: date,
            status:
              data.result === "SUCCESS" ? this.$t("successfull") : data.result,
            amount: data.amount,
            total_without_tax: data.total_without_tax,
            mdm_utilities: data.mdm_utilities,
            description: data.description,
            brand: data.brand,
            number: data.number,
            payment_method: data.payment_operator,
            result:'FAILURE'
          };
          }
        })
        .then(() => {this.loading = false})
        .catch(err => {
          this.loading = false;
          this.$router.push({ name: 'Home', query: {message:err.message } });
          // this.error.status = true;
          // this.error.message = err.message
          // console.log(err.message)//
          })
    
    },

    getReceiptData() {
      this.id = this.$route.query.receiptId;
      let date = this.$route.query.date

      rechargeService
      .checkPaymentReceipt(this.id, this.lang)
      .then((data) => {
        this.transData = {
          customer_name: data[0].user_name,
          account_reference: this.tab.account_reference,
          charge_token: data.filter(ele=>{ return ele.charge_token!= null  && ele.charge_token!= ''}).length?data[0].charge_token:null,
          id: this.id,
          creationTime: date,
          amount: null,
          total_without_tax: null,
          payment_method: null,
        }
        // console.log(this.transData,data.filter(ele=>{ return ele.charge_token!= null }).length)
        if(( Number(data[0].total_with_fees) && Number(data[0].total_with_fees) >= Number(data[0].cash_value))) {
          this.transData.amount = Number(data[0].total_with_fees);
          this.transData.total_without_tax =  Number(data[0].cash_value);
        } else {
          this.transData.amount = Number(data[0].cash_value);
          this.transData.total_without_tax = Number(data[0].cash_value);
        }
        return data
      })
      .then((data) => {
        this.services = data.map(service => {
        
          return {
            name: service.item_name,
            tax: service.tax_value ? service.tax_value : 0,
            value: service.total ? service.total : service.total_after_discount_tax
          }
        })
      })
      .then(() => {this.loading = false})
      .catch(err => {
        this.loading = false;
        // this.error.status = true;
        // this.error.message = err.message;
        // console.log(err.message);
        this.$router.push({ name: 'Home', query: {reciept_status:-1,message:err.message } });
        })
    
    },

  },
  created() {
    if(this.isReceipt) {
      this.getReceiptData();
    }
    else {
      this.getTransactoinData();
    }
  },
};
</script>

<style scoped>
</style>

import ApiRequests from "./ApiRequests";
import { getCookie } from "../utils";

export default {
  getSingleMeter(req_pk){
    const lang=getCookie('lang'); 
     return  ApiRequests.apiRequest('get','portal/meter',{params:{
       req_pk:req_pk,
       user_lang:lang,
     }},null).then(data =>{return data});

   }
};
import axios from "../axios";
import { getCookie } from "../utils";
import authentication from "./authentication";

export default {
    apiRequest(type,url,params,body = null,Auth=true,returnCODEInFalseSTATUS=false){
        if(getCookie('user_id') == null && Auth)
        {
          authentication.logout();
          location.reload();
          // return 'session expired';
          
        }
        else 
        {
          return this.returnRes(type,url,params,body).then(res => {
              if (res.data.STATUS == true)  {
                // if(type==='get' && url=='portal/consumption' && params.params.req_pk!= '124585' ) {console.log("hhhhhhh");res.data.Data=[]};
                if(type=='get' && res.data.Data.length==0){
                  // 1 mean no data found
                  throw new Error(1);
                }
                
                else return res.data.Data;
              }
              else {
                if (res.data.CODE == -8 || res.data.CODE == -9  ||res.data.CODE == -102 || res.data.CODE == -120)
                {
                  authentication.logout();
                  location.reload();
                }
                else if(res.data.CODE == -98){
                 throw new Error(res.data.Data.error[0]);
                }
                else if(returnCODEInFalseSTATUS){ 
                  throw new Error(res.data.Msg, { cause: res.data.CODE });
                }
                else{
                 throw new Error(res.data.Msg);
                }         
              }
            })
        }
    },

   
  returnRes(type,url,params,body){
    if(type=="post") return axios().post(url, body, params );
    else return axios().get(url,params );
  }
}

<template>
  <div class="loading-spinner-container">
      <div class="loading-spinner">
          <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
          <p>{{$t('loading')}}...</p>
      </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
export default {
    name: 'Loading'
}
</script>

<style scoped>
.loading-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.loading-spinner {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
</style>
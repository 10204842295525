import ApiRequests from "./ApiRequests";

export default {
  getConsumption(req_pk) {

        return  ApiRequests.apiRequest('get','portal/consumption',{params : {
          req_pk:req_pk,
          }},null).then (data=>{return data})
  
  },


};
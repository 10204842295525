<template>
  <div >
    <b-modal
    id="paymentConfirmation"
    ref="paymentConfirmation"
    size="m"
    centered
    :header-class="{'ltr' :lang==2 , 'rtl' :lang==1}"
    >
      <template #modal-header>
        <div class="payment-confirmation__header">
          <Feedback v-if="error" :state="2" :message="`${$t('fast_recharge.shortAmount')}`"   @unmountFeedComponent="error=false" />
          {{$t('confirmation_message') + ' '}} {{code}}
        </div>
      </template>

      <div  class="last-due-element" :class="{'rtl': lang ==1, 'ltr': lang ==2}">
        <DetailedPayment :company="company" :due_payments="due_payments" :chargeAmount="chargeAmount" :financial_dues="financial_dues" :collection_fees="collection_fees" :total_amount="total_amount" :payment_mode="payment_mode"/>
      </div>

      <template #modal-footer>
        <div class="payment-confirmation__actions">
          <button class="btn-secondary" @click="hideModal('paymentConfirmation')"> {{$t('cancel')}} </button>
          <button :disabled="error"  class="btn-primary" @click="confirm()"> {{$t('pay')}} </button>
        </div>
      </template>
      
    </b-modal>
    </div>
</template>

<script>
import DetailedPayment from "@/components/Recharge/DetailedPayment.vue"
import Feedback from "@/components/Feedback.vue"

export default {
    props: ["company","lang", "code", "due_payments", "chargeAmount", "collection_fees", "financial_dues","total_amount", "error","payment_mode"],
    components: {
        DetailedPayment,
        Feedback,
    },
    data() {
      return{
        loading: true,
      }
    },
    methods: {
        hideModal(ref) {
            this.$refs[ref].hide()
        },

        confirm() {
          this.hideModal('paymentConfirmation');
            
          if(!this.error)
            this.$emit('confirm')
        },
    },
}
</script>

<style scoped>
#paymentConfirmation___BV_modal_outer_ .close {
  /* color: #4F4D61;
  background-color: transparent;
  border: 0; */
  display: none;
 }
/deep/ #paymentConfirmation___BV_modal_body_.modal-body{
  min-height: 190px !important;
 }
 /deep/ #paymentConfirmation___BV_modal_header_.modal-header{
  height: auto;
  padding: 24px;
  padding-bottom: 0px;
 }
  /deep/ #paymentConfirmation___BV_modal_footer_.modal-footer{
  border-top: 0px;
  display: flex;
  padding: 24px;
 }
.payment-confirmation__header {
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 40px;
  color: #2C3E50; 
  /* padding: 24px; */
}

.payment-confirmation__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.payment-confirmation__actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
}

.payment-confirmation__actions .btn-primary {
  background-color: var(--main-color);
  color: var(--inverse-text-color);
  border: 1px solid transparent;
  
}

.payment-confirmation__actions .btn-primary:disabled {
  background-color: var(--bs-gray-500) !important;
  color: var(--inverse-text-color);
  border: 1px solid transparent;
}

.payment-confirmation__actions .btn-secondary {
  background-color: white;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
</style>

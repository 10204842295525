import { getCookie } from '../utils';
import ApiRequests from "./ApiRequests";
export default {
  getUser() {
    return  ApiRequests.apiRequest('get','portal/user',{params : {
      user_id:`${getCookie('user_id')}`,
        
    }},null).then(data =>{return data})

  },
};

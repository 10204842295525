import {getLov} from "./buduLov";

export default{

    getComplaints(){
      return getLov(1,null,1,null,null,null,null);
    },

    getInquiries(){
      return getLov(1,null,2,null,null,null,null);
    },
}
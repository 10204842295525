const company_config = {
    // GENERAL INFO
    company_name:"MC",
    name : {
        arabic: 'ميدار',
        english: 'Midar',
    },
    description: {
        arabic: '',
        english: '',
    },
    logo_path : {
        arabic: 'MC/logo/logo-ar.png',
        english: 'MC/logo/logo-en.png',
        footer_arabic: 'MC/logo/logo-ar.png',
        footer_english: 'MC/logo/logo-en.png',
        dark_logo_ar:'MC/logo/dark-logo.png',
        dark_logo_en:'MC/logo/dark-logo.png',
    },
    customer_service_numbers : [
        '01206666789',
        '01206666798'
    ],
    customer_service_emails : [
        'info.elect@midar.org',
    ],
    socialmedia: [
        {
            type: 'facebook',
            url: 'https://facebook.com/profile.php?id=61557605083462',
            name: 'Midar'
        },
        {
            type: 'instagram',
            url: 'https://www.instagram.com/MIDAR.eg/',
            name: 'midar.eg'
        },
        // {
        //     type: 'twitter',
        //     url: 'https://twitter.com/Mostakbal_city',
        //     name: '@Mostakbal_City'
        // },
        {
            type: 'LinkedIn',
            url: 'https://www.linkedin.com/company/35710333/admin/dashboard/',
            name: 'Midar'
        },
        {
            type: 'email',
            url: 'info.elect@midar.org',
            name: 'info.elect@midar.org'
        },
        {
            type: 'youtube',
            url: 'https://www.youtube.com/@MIDAReg',
            name: 'MIDAR'
        },
        
    ],
    
    showLocationDetails:true,
    locations: {
        ar:['القطعة  AD-1, مبنى A ','مستقبل سيتي-القاهرة الجديدة ' ],
        en:["Plot AD-1, building A", '  Mostaqbal City - New Cairo']
    },
    location_map:"Mostakbal City electricity company",
    location_map_link:"https://maps.app.goo.gl/9nJzXWbf8oSsUDHU6",  
    colors: [
        '#e94c28',
        '#002751',
        '#2870b9'
    ],
    PortalLangs:{
        firstLang:'ع',
        secondLang:'En'
    },
    defaultLang:1,
    // LOGIN PAGE
    unAuth_request:true,
    login_faq:true,
    fastRecharge:true,
    fastRecharge_elements:{
        unit_code:false, // changed to account_reference
        account_reference: true,
        meter_code:true,
        phone_number:false,
        amount:true,
        meter_placeholder_ar:"مثال 123456",
        meter_placeholder_en:"e.g. 123456",
        unit_placeholder_ar:"", // changed to account_reference_placeholder_ar
        unit_placeholder_en:"", // changed to account_reference_placeholder_en
        account_reference_placeholder_ar:"مثال EARKGSB13100",
        account_reference_placeholder_en:"e.g. EARKGSB13100",
        phone_number_placeholder_en:"e.g 01234567890",
        phone_number_placeholder_ar:"مثال 01234567890",
    },
    fastRechargeLimit:{
        min:100,
        max:50000,
    },

    first_login_pass_msg_en:"",
    first_login_pass_msg_ar:"",
    
    forgot_pass:true,

    login_footer:true,
    login_footer_elements:{
        numbers:true,
        email:true,
        location:true,
        headQuarters:true,
    },

    
    // NAVBAR
    requests:true,
    faq:true,
    notifications:true,
    raise_complaint_inquiry:true,

    // FOOTER
    mobile_app:true,
    mobile_app_QRs:{
        ios:'https://apps.apple.com/eg/app/midar/id6466739593',
        android:'https://play.google.com/store/apps/details?id=com.pylon.customers.mc',
    },
    footer_back_img_path:'MC/logo/footer-background.png',


    // HOME PAGE

    // meter section 
    remaining_credit:true,
    last_update_date_time:true,
    consumption_chart:true,
    recharge_from_home:true,

    // payment histroy section 
    payment_history_table:true,
    payment_receipt_history_table:true,

    //consumption section
    consumption_history_table:true,
 
    // recharging
    paytabs:false,
    nbe:true,
    response_type:'json',
    enter_total_recahrge_due:false,
    allow_recharge_without_pay_all:false,
    force_pay_all:true,
    show_due_payments:true,
    fees_precentage:0.025,
    fixed_fees:0,
    failure_Recharge_msg_ar:"فشلت بسبب فشل الإتصال بالعداد. الرجاء الاتصال بدعم العملاء على ",
    failure_Recharge_msg_en:"failed due to a failure in communication with the meter. Please contact the customer support on ",
    Invoice_img:'National_Bank_of_Egypt_Logo.png',
    
    // PROFILE

    // personal info
    changePass:true,
    addEmailToContactInfoMsg:true,

    // notification
    show_critical_part:false,
    notfication_settings:false,
    numberOfLoginFooterElements:4,
    notfication_settings_elements:{
     sms:true,
     mobile_app:true,
     customer_portal:true,
     email:true
    },
    
       
}

export default  {company_config}

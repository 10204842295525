<template>
 
  <div id="app" :class="{'arabic-font': lang==1, 'english-font': lang == 2}">
    <nav-bar :contain="contain_nav" :tab="tabs"  :lang="lang" v-if="isAuthed" :auth="isAuthed" :company="company" :pageTitle="pageTitle"/>
    <router-view :tab="tabs"  :lang="lang" :company="company" @remove_nav="remove_nav"></router-view>
    <Footer v-if="isAuthed"  :lang="lang" :company="company"/>
    
  </div>
</template>

<script>
const company_config = require(`@/Projects/${process.env.VUE_APP_CLIENTNAME}/config.js`).default
require(`../public/css/${process.env.VUE_APP_CLIENTNAME}/main.css`)


import NavBar from "@/components/NavBar/NavBar.vue";
import Footer from "@/components/Footer.vue";
import { getCookie } from "./utils";
import langServices from '@/services/lang'

export default {
  name: "App",
  components: {
    NavBar,
    Footer,


  },
  data() {
    return {
      get lang() {
        // 2 second lang 1 forst lang
          return getCookie('lang') || 2;
      },
      get isAuthed() {
        return getCookie('user_id') || false;
      },
      tabs:0,
      contain_nav:false,  
      pageTitle:'',
    };
  },
 
    computed: {
    company: function() {
      return company_config.company_config
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.lang = newVal=='firstLang'?1:2;
    },
   
    $route(){
      this.injectRouteChanging();
   }
  },
  methods:{
    tabContainer(val){
      this.tabs=val;
    },
    remove_nav(val){
      this.contain_nav=val;
    },
    injectRouteChanging(){
     this.$i18n.locale=this.lang==1?'firstLang':'secondLang';
     this.pageTitle= this.$t(this.$route.meta.pagetTitle);
     this.contain_nav= this.$route.meta.containsMeterNav;
    },

  },
  created(){
    if(getCookie('lang') ==null){ //means that it is the first login to website after logout
      langServices.updateLangCookie(company_config.company_config.defaultLang);
    }
   
    this.$root.$on('changedTabContainer', this.tabContainer);
    this.injectRouteChanging();
  },
  mounted() {
    if(company_config.company_config.nbe){
      let nbeScript = document.createElement('script')
      if(process.env.VUE_APP_ENV=='test'){
        nbeScript.setAttribute('src', 'https://test-nbe.gateway.mastercard.com/checkout/version/61/checkout.js');
      }
      else{
        nbeScript.setAttribute('src', 'https://nbe.gateway.mastercard.com/checkout/version/61/checkout.js');
      
      }
      nbeScript.setAttribute('data-error', 'errorCallback')
      document.head.appendChild(nbeScript)
      
      let errCallBackScript = document.createElement('script')
      errCallBackScript.setAttribute('src', 'nbe_checkout_callbacks_functions.js')
      document.head.appendChild(errCallBackScript)
  }
}
};
</script>

<style>

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.relative {
  position: relative;
}

.modal-backdrop {
  background-color: #00000075 !important;
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl * {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}
.ltr * {
  direction: ltr;
  text-align: left;
}

/* fonts for english and arabic */
.arabic-font home, body .arabic-font *:not(i),body .arabic-font ~ div .modal *:not(i){
  font-family: var(--arabic-main-font), sans-serif !important; 
}
.english-font home, body .english-font *:not(i),body .english-font ~ div .modal *:not(i){
  font-family:  var(--english-main-font),var(--english-secondary-font)!important;
}
.english-font #footer :is(h1, h2, h3, h4, h5, h6) {
  font-family: var(--english-secondary-font), var(--english-main-font) !important;
  font-weight: 600  !important;
}
.arabic-font #footer :is(h1, h2, h3, h4, h5, h6) {
  font-family: var(--arabic-secondary-font),var(--arabic-main-font), sans-serif !important;
  font-weight: 600  !important;
}

/* Titles */
/* h1,h2,h3,h4,h5,h6 {
  font-family: var(--english-secondary-font), var(--english-main-font), var(--arabic-main-font) !important;
  font-weight: 600  !important;
}

.arabic-font-subtitle{
  font-family: var(--arabic-main-font), sans-serif !important;
  font-weight:  600  !important;
}

home, body *:not(h1, h2, h3 , h3, h4, h5 , h6, i , th), .arabic-font{
  font-family: var(--arabic-main-font), sans-serif !important;
  font-weight:  400  !important;
}

.english-font-subtitle, th{
  font-family: var(--english-main-font), var(--arabic-main-font),sans-serif !important;
  font-weight: 500 !important;
}

home, body *:not(h1, h2, h3 , h3, h4, h5 , h6, i , th), .english-font{
  font-family: var(--english-main-font), var(--arabic-main-font),  sans-serif !important;
  font-weight: 400 !important;
} */


.btn-color {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  color: var(--inverse-text-color) !important;
}

a, a:hover{
  color: var(--main-color);
}



.btn-primary {
  background-color: var(--main-color) !important;
  color: var(--inverse-text-color);
  border-color: var(--main-color);
}

.btn-primary:hover {
  background-color: var(--hover-color) !important;
}
::placeholder {
  color: #AAAAB2 !important;
}

::-webkit-input-placeholder {
      color: #AAAAB2 !important;
  }

  :-moz-placeholder { /* Firefox 18- */
      color: #AAAAB2 !important;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
      color: #AAAAB2 !important;
  }

  :-ms-input-placeholder {
  color: #AAAAB2 !important;
  }
</style>

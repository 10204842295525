<template>
  <div class="custom-table">
    <table
      :class="{
        'h-100 rtl': lang ==1,
        'h-100 ltr': lang ==2,
      }"
      v-if="show===true"
    >
      <tr class="titleTable">
        <th v-for="header in headers" :key="header.locale">
          {{ $t(header.locale) }}
          <br />
          <span
            v-if="header.notation"
            class="text-secondary"
            >{{ $t(header.notation_locale) }}</span
          >
        </th>
        <th  v-for="action in actions"  :key="action.title" class="actions-td text-align-center" :class="{
              'dont-show-reciept': company.payment_receipt_history_table === false,
            }">
             {{ action.header_title }}
          </th>
      </tr>
      <tr v-for="(item, index) in visiblePages" :key="index">
        <td v-for="i in headers" :key="i.locale" :class="{'amount-cell' : i.locale=='amount'}">
            <div v-if="i.locale=='amount'"> 
              <div v-for="(ele, i) in item[i.locale].split('-')" :key="i" class="amount-div">
                <p >{{ ele.split(':')[1] }} </p>
                <p class="text-secondary">{{ ele.split(':')[0] }} </p>
              </div>
               <!-- <PaymentTable :content="item" :index="index"></PaymentTable> -->
            </div> 
            <span v-else> {{ item[i.locale] }}</span> 
        </td>

          <td  v-for="action in actions"  :key="action.title" class="actions-td text-align-center"   :class="{
              'dont-show-reciept': company.payment_receipt_history_table === false,
            }">
              <router-link  v-if="company.payment_receipt_history_table"  :to="action.navigateTo + '?receiptId=' + item.pk + '&date=' + item.transaction_date" style="text-decoration: none">
                {{action.title}} 
              </router-link>
          </td>
      </tr>

    </table>
    
    <div class="pagination-container" v-if="showPager">
      <ul class="pages">
        <li class="page shadow-sm rounded-lg mx-2 page-back" @click="firstPage">
          <i class="fas fa-angle-double-left"></i>
        </li>
        <li class="page shadow-sm rounded-lg mx-2 page-back" @click="decreasePage">
          <i class="fas fa-angle-left"></i>
        </li>
        <li
          :class="{
            'page shadow-sm rounded-lg mx-2 active': currentPage === i,
            'page shadow-sm rounded-lg mx-2': currentPage !== i,
          }"
          v-for="i in pages"
          :key="i"
          @click="goToPage(i)"
        >
          {{ i }}
        </li>
        <li
          class="page shadow-sm rounded mx-2 page-forward"
          @click="incrementPage"
        >
          <i class="fas fa-angle-right"></i>
        </li>
        <li class="page shadow-sm rounded mx-2 page-forward" @click="lastPage">
          <i class="fas fa-angle-double-right"></i>
        </li>
      </ul>
    </div>

</div> 
</template>

<script>
import PaymentTable from "./PaymentTable.vue";
export default {
  props: ["lang","headers", "items", "showPager", "perPage","show", "actions", "company"],
  components:{
   PaymentTable
  },
  data() {
    return {
      currentPage: 1,
      visiblePages: [],
      current_Row_item:{},
    };
  },
  computed: {
    pages() {
      if (this.showPager) {
        return this.items.length / this.perPage;
      } else return 0;
    },
  },
  watch: {
    items() {
      if(this.showPager == true) {
        if(this.items.length > 0)
          this.visiblePages = this.items.slice(0, this.perPage);
      } else {
        if(this.items.length > 0)
          this.visiblePages = this.items.slice(0);
      }
    }
  },
  methods: {
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.visiblePages = this.items.slice(
        pageNumber * this.perPage - this.perPage,
        pageNumber * this.perPage
      );
    },
    incrementPage() {
      this.goToPage( Math.min(this.currentPage + 1, this.pages))
    },
    decreasePage() {
      this.goToPage(Math.max(this.currentPage - 1, 1))
    },
    lastPage() {
      this.goToPage(this.pages)
    },
    firstPage() {
      this.goToPage(1)
    },
    setCurrentRow(item){
      this.current_Row_item=item;
    }
  },
};
</script>

<style scoped>
.custom-table{
  overflow: auto;
}
@media (max-width:600px){
table th,table td {
padding : 15px !important;
font-size: 12px !important;
}
}
th ,td{
  padding: 16px 24px !important;
  vertical-align: middle;
}

.actions-td {
  width: 200px;
  text-align: center  ;
}

.pages {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: 0;
}
.page {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 5px;
}
.active,
.page:hover {
  background-color:var(--main-color);
  color: white;
  cursor: pointer;
}
.actions-td.dont-show-reciept{
  display: none;
}

th,td{
  background: #F0F0F0;
  color:  #4F4D61;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

}
td{
font-weight: 500;
background: #fff;
}
td p{
  text-align: center;
  margin-bottom: 0px;
}
.text-secondary {
  color: #AAAAB2;
  font-size: 12px;
  font-weight: 500;
}
table, td, th{
  border: 1px solid #E0E0E0 !important;
}
.amount-cell{
  padding:0px !important;
}
.amount-cell .amount-div{
  padding:16px 24px;
  border-bottom: 1px solid #E0E0E0 !important;
}
</style>
<template>
  <div class="home">
    <Feedback v-if="show_recharge_error" :state="state" :message="home_error_msg"  :query_found="true"   @unmountFeedComponent="show_recharge_error=false" class="info-nofication"/>
   
  
    <MeterInfo 
      v-if="selectedTab"
      :code="selectedTab" 
      :company="company"
      v-on:update-payment="updatePayment" 
      :lang="lang"
      :mdm_meters="selectedMeter" 
      :selectedMeterObject="selectedMeterObject"
      :consumptionArray="consumptionArray"
      :consumption_error_msg="consumption_error_msg"
      :cons_error="cons_error"
      :meter_error_msg="meter_error_msg"
      :meter_error="meter_error"
      :meter_unit="meter_unit"
    />
    
    <PaymentsHistory 
      v-if="selectedTab && company.payment_history_table" 
      :code="selectedTab"  
      :selectedMeter="selectedMeter"     
      :payment_mode="payment_mode"
      :company="company"
      :lang="lang"
    />

    <Consumption 
      v-if="selectedTab && company.consumption_history_table" 
      :code="selectedTab"  
      :mdm_meters="selectedMeter" 
      :consumptionArray="consumptionArray"
      :lang="lang"
      :consumption_error_msg="consumption_error_msg"
      :cons_error="cons_error"
      :meter_unit="meter_unit"
    />

  </div>
</template>

<script>

import MeterInfo from "@/components/Home/MeterInfo.vue";
import PaymentsHistory from "@/components/Home/PaymentsHistory.vue";
import Consumption from "@/components/Home/Consumption.vue";
import Feedback from "@/components/Feedback.vue";

import MeterService from "@/services/meters";
import ConsuptionService from "@/services/consumption";


export default {
  name: "App",
  components: {
    MeterInfo,
    PaymentsHistory,
    Consumption,
    Feedback
  },
  props: ['company','tab', 'lang'],
  data() {
    return {
      tabsData: [],
      selectedTab: "",
      selectedMeter: {},
      req_trx_master: null,
      payment_mode: null,
      enforce_post_paid_mode:false,
      selectedMeterObject:{},
      consumptionArray:[],
      show_recharge_error:false,
      home_error:false,
      home_error_msg:'',
      cons_error:false,
      consumption_error_msg:'',
      meter_error_msg:'',
      meter_error:false,
      state:1,
      meter_unit:"",
    };
  },
  watch:{
  async 'tab'(){
      await this.getChanged();
      this.getSingleMeter();
      this.getConsumption();
      this.injectRouteChanging();    
   },
   $route(){
    this.injectRouteChanging();
   }

  },
 async  created(){
  if(this.tab !=0){
    await this.getChanged();    
    this.getSingleMeter();
    this.getConsumption();
    this.injectRouteChanging();
  }
  },
  methods: { 
    injectRouteChanging(){
      if(this.$route.query.status==0){
      this.show_recharge_error=true;
      this.state=3;
      this.home_error_msg=
      this.selectedMeterObject.payment_mode=='2'?this.$t('canceled_recharge_request'):this.$t('canceled_pay_bills_request');          
    }
    else if(this.$route.query.reciept_status){
      this.show_recharge_error=true;
      this.state=2;
      this.home_error_msg=this.$route.query.message;
    }
   else if(this.$route.query.invalid_request || this.$route.query.message ){
      this.show_recharge_error=true;
      this.state=2;
      this.home_error_msg=
      this.$route.query.message ? this.$route.query.message =='failure'?
      this.$t("your_transaction")+" "+this.$t("number")+" "+this.$route.query.order_id +" "+this.$t('was') +" "+ this.$t("failed") : this.$route.query.message 
      : this.selectedMeterObject.payment_mode=='2'?this.$t('invalid_recharge_request'):this.$t('invalid_pay_bills_request');
    }  
   
    },
    updatePayment(req_trx_master, payment_mode) {
      this.req_trx_master = req_trx_master
      this.payment_mode = payment_mode
    },
   getChanged(){
    // console.log("this.tabs",this.tab)
        this.tabsData=this.tab.tabsData,
        this.selectedTab=this.tab.selectedTab,
        this.selectedMeter=this.tab.selectedMeter,
        this.req_trx_master=this.tab.req_trx_master,
        this.payment_mode=this.tab.payment_mode;
        this.enforce_post_paid_mode=this.tab.enforce_post_paid_mode;
        this.meter_unit=this.tab.unit;
 }, 
  async  getSingleMeter(){
    this.meter_error=false;
     await MeterService.getSingleMeter(this.selectedMeter).then((data)=>{
       
        if (data) {
            this.selectedMeterObject=data;
            this.selectedMeterObject.payment_mode=this.enforce_post_paid_mode?'1':this.selectedMeterObject.payment_mode;
          }       
      }).catch((err) => {
      // here we will send error as props to meter info component
      this.meter_error=true;
      this.meter_error_msg=err.message;
      });
    },
   async getConsumption(){
    this.cons_error=false;
    
      await ConsuptionService.getConsumption(this.selectedMeter).then((data)=>{
        if (data.length) {  
            this.consumptionArray=data;   
          }
      }).catch((err) => {
      // here we will send error as props to meter info and consumption component
      this.consumptionArray=[];   
      this.cons_error=true;
      this.consumption_error_msg=err.message;
     });
        
    },
   
  },

};
</script>

<style scoped>
.home {
  height: 100%;
  margin:0px 20px;
  margin-bottom: 200px;
}
.info-nofication{
  z-index:5;
  position:fixed ;
  top: 10% ;
  left:5%;
  width:30%;
}
</style>

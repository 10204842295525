<template>
  <div>
    <div v-if="chargeAmount" class="recharge-footer__row">
      <span v-if="payment_mode == '2'" class="recharge-footer__row-title">{{ $t("charge_amonut") }}</span>
      <span v-else class="recharge-footer__row-title">{{  $t("required_money") }}</span>
      <span v-if="chargeAmount == 0" class="recharge-footer__row-value">{{ $t("zero") }} {{ $t("egp") }}</span>
      <span v-else class="recharge-footer__row-value">{{ chargeAmount}} {{ $t("egp") }}</span>
    </div>

    <div v-if="due_payments && payment_mode=='2'" class="recharge-footer__row">
      <span class="recharge-footer__row-title">{{ $t("due_payments") }}</span>
      <span v-if="due_payments == 0" class="recharge-footer__row-value" >{{ $t("zero") }} {{ $t("egp") }}</span>
      <span v-else class="recharge-footer__row-value">{{ due_payments }} {{ $t("egp") }}</span>
    </div>

    <div v-if="financial_dues" class="recharge-footer__row">
      <span class="recharge-footer__row-title">{{ $t("credit") }}</span>
      <span v-if="financial_dues == 0" class="recharge-footer__row-value">{{ $t("zero") }} {{ $t("egp") }}</span>
      <span v-else class="recharge-footer__row-value financial_dues">-{{ financial_dues }} {{ $t("egp") }}</span>
    </div>

    <div v-if="collection_fees" class="recharge-footer__row">
      <span class="recharge-footer__row-title">{{$t("collection_fees")}}</span>
      <span v-if="collection_fees == 0" class="recharge-footer__row-value"> {{ $t("zero") }} {{ $t("egp") }}</span>
      <span v-else class="recharge-footer__row-value">{{ collection_fees }} {{ $t("egp") }}</span>
    </div>

    <div class="recharge-footer__row">
      <span class="recharge-footer__row-title">{{ $t("total_amount") }}</span>
      <span v-if="total_amount == 0" class="recharge-footer__row-value">{{ $t("zero") }} {{ $t("egp") }}</span>
      <span v-else class="recharge-footer__row-value total-amount">{{ total_amount }} {{ $t("egp") }}</span>
    </div>

  </div>
</template>

<script>
export default {
    props: ["lang", "due_payments", "chargeAmount", "collection_fees", "total_amount","company","payment_mode","financial_dues"]
    
};
</script>

<style scoped>

.recharge-footer__row{
    display: flex;
    justify-content: space-between;
    color: #4F4D61;
    margin-bottom: 8px;
}
 .recharge-footer__row-title {
  font-size: 16px;
  font-weight: 500 !important;

}

.recharge-footer__row-value {
  font-weight: 500 !important;
  font-size: 16px;
}
.recharge-footer__row-value.financial_dues{
  color:#088C03 !important;
}
.recharge-footer__row-value.total-amount {
  font-weight: 700 !important;
  font-size: 20px;
}

@media (max-width: 600px) {
  .recharge-footer__row-title {
  font-size: 14px;
  font-weight: 500 !important;

}
  .recharge-footer__row-value.total-amount {
  font-size: 16px;
}
}
</style>

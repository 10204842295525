import { getCookie } from '../utils';
import ApiRequests from "./ApiRequests";
export default {
  async updateLang(langOption) {
    return  ApiRequests.apiRequest('post','portal/updateUserLang',null,{
      user_id: getCookie('user_id'),
      // user_lang: langOption == 'ar' ? 1 : 2,
      user_lang: langOption 
    })
    .then(data=>{
     return data;
    }).then(() => {
      let d = new Date();
      d.setTime(d.getTime() + 3600 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = 'lang=' + langOption + ';' + expires + ';path=/';
    });
 
  },

  async updateLangCookie(langOption) {
    let d = new Date();
    d.setTime(d.getTime() + 3600 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = 'lang=' + langOption + ';' + expires + ';path=/';
  },
};
import ApiRequests from "./ApiRequests";

export default {
  getPayment(req_trx_master) {

    return  ApiRequests.apiRequest('get','portal/paymentHistory',{params:{
      req_pk:req_trx_master
    }},null).then(data =>{return data})

  },
};
<template>
  <div :class="{'nav-font-size': lang == 2 }">
 <b-navbar toggleable="sm" type="dark" class="navBig login-nav w-100" :class="{ 'rtl': lang ==1, 'ltr': lang == 2 }" >
    <!-- <b-navbar-brand href="#" class="col-sm-4 col-xs-6 "> -->
       <img v-if="lang == 2" class="logo col-sm-4 col-xs-6 " :src="require(`@/Projects/${company.logo_path.english}`)"/>
        <img v-if="lang == 1" class="logo col-sm-4 col-xs-6 " :src="require(`@/Projects/${company.logo_path.arabic}`)" />
        <!-- </b-navbar-brand> -->

        <b-navbar-toggle target="nav-collapse" class="togglable-button" >
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav   :class="{ 'rtl': lang ==1, 'ltr': lang == 2 }">
      <div class="col-md-1"></div>
    
       <b-navbar-nav v-if="isApply!==true"  class="d-flex justify-content-end"  :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }" style="padding: 0;display: contents; align-items:center;min-width:200px;margin:16px 30px">
            <b-nav-item v-if="company.unAuth_request" class="apply-button" to="unauth-request" >
                <button  >{{ $t("requests") }}</button>      
            </b-nav-item>
            <b-nav-item v-if="company.login_faq" class="apply-button" to="FAQLOGIN" >
                <button  >{{ $t("faq") }}</button>      
            </b-nav-item>
            <b-dropdown
              class="change-lang-drop"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              :class="{'marginR' : lang == 1,'marginL': lang == 2}"
            >
              <template #button-content>
                <i :class="{'pylon-icon-Lang rtl': lang ==1, 'pylon-icon-Lang': lang==2 }"></i>
                <span style="color: white"  v-if="lang==2">{{company.PortalLangs.secondLang}}</span>
                <span style="color: white;"   v-if="lang==1">{{company.PortalLangs.firstLang}}</span>
              </template>
              <b-dropdown-item href="#" @click="changeLang(2)"
                >{{company.PortalLangs.secondLang}}</b-dropdown-item
              >
              <b-dropdown-item href="#" @click="changeLang(1)"
              >{{company.PortalLangs.firstLang}}</b-dropdown-item
              >
            </b-dropdown>

            

          </b-navbar-nav>
        
</b-collapse>
  </b-navbar>
  </div>
</template>
<script>
import langServices from '@/services/lang';

export default {

  props: {
    company: {
      default: () => {},
    },
    isApply:{
      default: () => {},
    },
    lang:{
      default: () => {},
    },
  
    
  },
  methods: {
    changeLang(langOption) {
      langServices.updateLangCookie(langOption)
      .then(() => this.$router.go())
    },
  },
};
</script>

<style scoped>
.login-nav.navbar-dark .navbar-nav .nav-link.router-link-exact-active:after{
 display: none !important;

}
.navBig.navbar-dark .navbar-nav .nav-link,.navbar-nav .nav-link:hover{
  padding:0px;
  margin:0px 8px;
}
.navbar-expand {
    justify-content: space-between;
   
}
.navBig {
    background-color: var(--secondary-color);
    color: var(--inverse-text-color);
    padding: 10px;
    min-height: 14vh;
}
.navbar-brand {
  margin-right: 0px !important;
}
.logo{
    margin: 0px 48px;
    margin-top: 4px;
    width: 143px;
    height: 64px;
    object-fit: contain;
}
#nav-collapse {
  justify-content: space-between;
}
.navBig.ltr .navBigFonts{
  margin-left: 35%;
}
.navBig.rtl .navBigFonts{
  margin-right: 25%;
}
.body:lang(1) {
  direction: rtl;
}

.navBig.login-nav .btn{
  padding:0px !important;
}
.nav-font-size{
  font-size: 15px;
}

.marginR{
  /* margin-right:25px; */
  position: relative;
}
.marginL{
    position: relative;
}
.marginL .dropdown-menu{
  position: absolute;
    left: -80px;
    top: 30px
}
.marginR .dropdown-menu{
  position: absolute;
  right: -110px;
    top: 30px
}
.rtl .dropdown-toggle{
  margin-left:10px;
  padding: 7px 8px;
}
.apply-button .nav-link:hover{
  background: transparent !important;
}
.apply-button button {
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 600 !important;
  font-size: 14px;
}

@media (max-width:575px) {
  .logo{
    margin:0px 30px
    }
  .btn-link{
    margin:0px 10px !important;
  }
  .navBig.login-nav .navbar-nav{
    margin: 16px 3px !important;
    flex-direction: row;
    justify-content: flex-start !important;
  }

 .apply-button button{
    margin-right: 30px ;
    margin-left:    30px;
  }
  .marginR{
    margin: 0px;
  }
}
@media (max-width:600px) {
  .navBig.login-nav .dropdown-toggle {
    margin: 0px !important;
  }
.navbar-expand-sm .navbar-nav {
    margin: 16px 0px !important;

}
}
</style>
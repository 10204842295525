<template>
    <div class="relative">
      <Feedback v-if="error" :state="2" :message="error_message"   @unmountFeedComponent="error=false" />
      

       <b-form class="fast-recharge">
         
          <div class="d-flex justify-content-between" style="margin-bottom: 80px;">
          
            <b-form-group
                  v-if="company.fastRecharge_elements.phone_number"
                  id="input-group-2"
                >
                <label for="phone_number">{{$t('phone_number')}}</label>
                  <b-form-input
                    id="phone_number"
                    v-model="rechargeForm.mobile"
                    type="text"
                    :placeholder="addPlaceHolder(3)"
                    required
                  >
                  </b-form-input>
            </b-form-group>
        

            <b-form-group               
                  v-if="company.fastRecharge_elements.unit_code"
                  id="input-group-3"
                >
                <label for="unit_code">{{$t('unit_code')}}</label>
                <b-form-input
                  id="unit_code"
                  v-model="rechargeForm.unit_code"
                  type="text"
                  :placeholder="addPlaceHolder(2)"
                  required
                ></b-form-input>
             </b-form-group>
            
            <b-form-group               
                  v-if="company.fastRecharge_elements.account_reference"
                  id="input-group-3"
                >
                <label for="account_reference">{{$t('account_reference')}}</label>
                <b-form-input
                  id="account_reference"
                  v-model="rechargeForm.account_reference"
                  type="text"
                  :placeholder="addPlaceHolder(4)"
                  required
                ></b-form-input>
             </b-form-group>
             
             <b-form-group
              v-if="company.fastRecharge_elements.meter_code"
              id="input-group-1"   
           
             >
            <label for="meter_code">{{$t('meter_code')}}</label>
                <b-form-input
                  id="meter_code"
                  v-model="rechargeForm.meter_code"
                  type="text"
                  :placeholder="addPlaceHolder(1)"
                  required
                ></b-form-input>
             </b-form-group>

          </div>
       
          <div>

            <!-- for paytabs fast recharge handle it laterrr -->
            <!-- <b-button
              v-if="rechargeForm.payment_method=='2'"
              v-b-modal.modal-sm
              class="w-100 btn-color"
              style="text-align: center;"
              @click="handleRecharge"
              >{{ $t("Recharge") }}
            </b-button> -->

            <b-button
              :disabled="!validateForm()"
              v-if="rechargeForm.payment_method=='1'"
              class="w-100 btn-color"
              style="text-align: center;"
              @click="getMeterDuePayments()"
              >{{ $t("next") }}
            </b-button>

            <!-- <b-modal     
              class="model-example"
              id="modal-sm"
              size="sm"
              no-close-on-esc
              no-close-on-backdrop
              :header-class="{
                'right-close': lang == 1,
                'left-close': lang ==2,
              }">
            <div id="payTabs"></div>
            </b-modal> -->
          </div>
      
      </b-form>
       
       <div class="accepted-payments__container" >
           
          <span>{{$t("We_accept")}}</span>
          
          <img src="../../assets/payment_methods/Visa-logo.png" alt="visa payment method logo"
            style="width: 32.22px;
              height: 26px;">

          <img src="../../assets/payment_methods/Mastercard-logo.png"  alt="mastercard payment method logo"
            style="width: 31.76px;
              height: 24.67px;">

          <img src="../../assets/payment_methods/Meeza-logo.png" alt="meeza payment method logo"
            style="width: 27.83px;
              height: 18px;">
       
       </div>

      <!-- recharge modal -->
      <b-modal 
            id="FastRechargeModal" 
            ref="FastRechargeModal"
            @hide="reset"
            size="m" 
            centered 
            scrollable 
            :header-class="{'right-dir' : lang==2 , 'left-dir' : lang==1}" 
      >
          <template #modal-header>
              <p>{{$t('recharge_meter')}} # {{rechargeForm.meter_code}}</p>
              <i class="pylon-icon-Close" @click="hideModal('FastRechargeModal')"></i>
          </template>

          <div :class="{'rtl recharge-body': lang ==1,'recharge-body': lang ==2,}">
            
            <div v-if="recharge_error" class="recharge-alret alert alert-danger "  style="min-height: 80px;" role="alert">
              <b >{{$t('error')}}</b>
              <span style="display:block" >{{msg}}</span> 
            </div>

            <div class="mb-2 prepaid" v-if="payment_mode=='2'">   
              <div  class='recharge-fields' style="display:flex;justify-content:space-between">
                <div>
                    <p style="font-weight:500 !important">{{$t("amount")}}</p>                
                    <div class="relative">
                      <input  class=" chargeInput" id="min-recahrge-amount" v-model="Amount"  :placeholder="AmountPlaceHolder" />
                      <span  class="min-span">  {{$t('min_amount')}} ( {{ min_payment_value}} {{$t('egp')}} )</span>                            
                    </div>
                </div>
                <div class="exchange-icons">
                  <i class="pylon-icon-ArrowRight" style="margin-bottom: -4px;"></i>
                  <i class="pylon-icon-Arrow-Left" style=" margin-top: -3px;"></i>
                </div>
                <div>
                    <p style="font-weight:500 !important">{{$t("Net_Charge")}}</p> 
                    <div class="relative">
                      <input  class=" chargeInput" id="net-recahrge" v-model="netCharge" :placeholder="AmountPlaceHolder"/>
                    </div>
                </div>
              </div> 
            </div>

            <div class="mb-2 postpaid" v-else>   
                <div>
                    <p style="font-weight:500 !important">{{$t("required_money")}}</p>                
                    <div class="relative">
                      <input  disabled class=" chargeInput" id="min-recahrge-amount" v-model="Amount"  :placeholder="AmountPlaceHolder" />                           
                    </div>
                </div>
            </div>
          </div>
          
          <template #modal-footer >
            <div  class="last-due-element" :class="{'rtl': lang ==1, 'ltr': lang ==2}">
                <DetailedPayment
                  :company="company" 
                  :lang="lang" 
                  :due_payments="PaymentConfirmation.due_payments"
                  :financial_dues="PaymentConfirmation.financial_dues" 
                  :chargeAmount="Amount && payment_mode=='1'?Amount:netCharge" 
                  :collection_fees="collection_fees" 
                  :total_amount="total_amount" 
                  :payment_mode="payment_mode"/>

                <b-button
                  id="recharge-handling-btn"
                  class="btn-color"
                  :disabled="disable_pay"
                  @click='handleRecharge()'
                  >{{ $t("Recharge") }}
                </b-button>            
            </div>

          </template>
             
      </b-modal>
      <!-- end recharge modal -->
    </div>

  
</template>

<script>
import Feedback from "@/components/Feedback.vue";
import DetailedPayment from "@/components/Recharge/DetailedPayment.vue";
import rechargeService from "@/services/recharge";

export default {

  components: {
    Feedback,
    DetailedPayment
  },

  props:["company","lang"],

  data() {
    return {
      rechargeForm:{
        meter_code:"",
        mobile:"",
        unit_code:"",
        account_reference:"",
        min_charge_amount: 0,
        max_charge_amount:0,
        amount:"",
        checkout:"embedded",
        payment_method:"",
        response_type:"json",
        redirect:true,
      },
      PaymentConfirmation: {
        due_payments: 0,
        financial_dues:0,
      },
      checkoutReady:false,
      error_message:'',
      error:false,
      recharge_error:false,
      payment_mode:'2',
      Amount:'',
      netCharge:'',
      AmountPlaceHolder: this.$t('recharge_value_placeholder'),
      min_payment_value:0,
      max_payment_value:0,
      modal_closed:true,
      emptyAmountFrom:false,
      min_NET_val:0,
      emptyAmountFrom:false,
      comefromEmpytedAmountByNetCond:false,
      disable_pay:false,
    }
  },

  created(){
    if(this.company.paytabs==true ) {
      this.rechargeForm.payment_method="2";
    }
    else  this.rechargeForm.payment_method ="1";

    this.rechargeForm.min_charge_amount=this.company.fastRechargeLimit.min;
    this.rechargeForm.max_charge_amount=this.company.fastRechargeLimit.max;
    
  },

  computed: {
   
    collection_fees() {
      let total_paid='';
      if(this.payment_mode=='2' && Number(this.Amount) ==0){
            total_paid= Number(this.PaymentConfirmation.due_payments) ;
      }
      else{
        total_paid = Number(this.Amount);
      }
        
      if( total_paid > 0){
        return parseFloat(total_paid *this.company.fees_precentage ).toFixed(2);
      }
      else {
        return 0 ;
      }
    },

    total_amount() {
      let total_paid='';
      if(this.payment_mode=='2' && Number(this.Amount) ==0){
            total_paid= Number(this.PaymentConfirmation.due_payments) ;
      }
      else{
        total_paid = Number(this.Amount);
      }
       return parseFloat( total_paid + Number(this.collection_fees) ).toFixed(2);
     }
  },
  watch: {
    Amount(newAmount,old) {
      if(!this.modal_closed && this.payment_mode=='2'){// to make watch affet just work when modal is opened not closed and prepaid
         // don't accept -ve number or strings (empty the feild instead)
         newAmount =Number(newAmount) && Number(newAmount)> 0 ? Number(newAmount) : '';
         this.Amount=newAmount;
 
         // work on amount validation only if 
          // 1- newAmount not empty
                // A  N
                // 10 -
          // 2- newAmount is empty but there is financial_dues come from changing in netRecharge Feild
                // A  N
                // -  20
                // and min_charge_val < 0
         if(newAmount!=''|| (this.PaymentConfirmation.financial_dues>0 && newAmount=='' )){
          
          // to check if empyted amount d=field result from empty amount isself or from condition in netcharge watch
          this.emptyAmountFrom=this.comefromEmpytedAmountByNetCond?false:true;
  
          // handle errors
          this.recharge_error=newAmount<this.min_payment_value||newAmount>this.max_payment_value?true:false;
          this.disable_pay=newAmount<this.min_payment_value||newAmount>this.max_payment_value?true:false;
          this.msg=newAmount<this.min_payment_value?this.$t("min_payment_amount") +" "+this.min_payment_value:this.$t("max_payment_amount")+" "+this.max_payment_value;

             //  apply change on net charge only if 
            //  1- newAmount not empty and >= min_payment
            //  2- newAmount is empty or less than or euqal min_payment and 
            //    => 1* netcharge changed and affect on amount only when (netCharge that changed not empty and there is no instalment)
            //    => 2* in case that newAmount is empty in Specific cases when there is installemts
            if((newAmount>=this.min_payment_value && newAmount!='')||(newAmount<=this.min_payment_value && ((this.netCharge!='' &&this.PaymentConfirmation.due_payments>0 )||(this.emptyAmountFrom &&this.PaymentConfirmation.financial_dues>0)))){
              //Case1 =min_charge_value=0;
              if(this.PaymentConfirmation.financial_dues==0 && this.PaymentConfirmation.due_payments==0){ 
                this.netCharge=newAmount;
              } //Case2 =min_charge_value<0;
              else if(this.PaymentConfirmation.financial_dues!=0  && this.PaymentConfirmation.due_payments==0){
                this.netCharge=(newAmount+this.PaymentConfirmation.financial_dues);
              } //Case3 =min_charge_value>0;
              else{
                this.netCharge=(newAmount-this.PaymentConfirmation.due_payments);
              }
              this.netCharge=Number(this.netCharge).toFixed(2)   
          }
          
         }else{
            this.recharge_error=false;
            this.disable_pay=true;
            this.netCharge=''; 
        }
      }
      
    },
    netCharge(newNet , old){
      if(!this.modal_closed && this.payment_mode=='2'){
        this.comefromEmpytedAmountByNetCond=false;
        // used to handle errors shown when newNet is empty and Amount is empty 
        let x= Number(newNet)? Number(newNet):0;
        // empty newNet if it is less than min_net_val (which equal min_Activity || (min_Acivity + min_charge_val(if it is -ve (remainig installemts found))))
        newNet = Number(newNet) && Number(newNet) >=this.min_NET_val && Number(newNet) >0? Number(newNet) :'';
       // // assign newNet to netCharge only if Amount empty and changing of newNet come from changing in netCharge itself
        //   // cases 
        //   // 1- min_activity = 2 , due=0 => so min_pay=2 ,Amount = '' and added net is 1 so netCharge feild remainng 1 to show netCharge err
        //   // 2- min_activity = 2 , due=0 => so min_pay=2 ,addedAmount is 1 so newAmount = '' < min_net so netCharge became empty also
          if(this.Amount!='' && newNet!=''){
            this.netCharge=newNet;
          }
          else{
            this.netCharge= Number(this.netCharge)>0?Number(this.netCharge):'';   
          } 
        
        if(newNet!=''){
              //  // on =500 due=200 //min-charge =200-500=-300
              // on =200 due=500 //min-charge =500-200= 300
              // charge=net +due => net=charge-due
            if(this.PaymentConfirmation.financial_dues==0 && this.PaymentConfirmation.due_payments==0){ 
                this.Amount=newNet;
            }else if(this.PaymentConfirmation.financial_dues!=0  && this.PaymentConfirmation.due_payments==0){
              this.Amount=(newNet-this.PaymentConfirmation.financial_dues);
            }else{
              this.Amount=(newNet+this.PaymentConfirmation.due_payments);
            }
        }
        else{
           // handle errors if changing come from netCharge itself and Amount is empty
           if(this.Amount==''){
              this.recharge_error=true;
              this.disable_pay=true;
              // use the val of x above 
              if(x!=0 && x<this.min_NET_val){
                this.msg=this.$t("min_net_amount") +" "+this.min_NET_val;
              }
              // if zero meaning that netCharge is empty remove error if there is 
              else if(x==0){
                this.recharge_error=false;
                this.disable_pay=false;
              }
            }
             // if amount not empty calc new Amount according to multiple cases

              // 1- if amount is < min_charge_val and change netcharge is empty (eg. D=50 min_charge=50)
              //   A      N 
              //   52 =>  2
              //   5  =>  -  => net changed and Amount < min_charge
              //   5  <=  - 
              else if((this.Amount<this.PaymentConfirmation.due_payments&& this.PaymentConfirmation.due_payments >0 && this.netCharge=='')){
               this.Amount=this.Amount;
              }
              // 2- if amount is >= min_charge_val and min_charge>=0 and netCharge is empty
              //   A      N 
              //   52 =>  2  => net changed to - and Amount > min_charge
              //   50 =>  -  => 
              else if(this.Amount>=this.PaymentConfirmation.due_payments && this.PaymentConfirmation.due_payments >0){
                this.Amount=this.PaymentConfirmation.due_payments +Number(this.netCharge);
              }
                  
              // 2- if amount is >= min_charge_val and min_charge<0 and netCharge is not empty (eg. Acti=2 D=0 , min_charge= -500 )
              //   A      N 
              //   2 =>  502  => net changed to - and Amount > min_charge , amount became -500+50 = -450 and converted to - in AmountWatch 
              //   - => 50  => 
              //  comefromEmpytedAmountByNetCond meaning that if Amount is empty and comefromEmpytedAmountByNetCond =true dont' apply the netCharge function in AountWatch
              else if((this.Amount>=-this.PaymentConfirmation.financial_dues&&this.PaymentConfirmation.financial_dues>0&& this.netCharge!='')){
                let val=-this.PaymentConfirmation.financial_dues+Number(this.netCharge);
                this.comefromEmpytedAmountByNetCond=val<0?true:false;
                this.Amount=Number(this.netCharge)-this.PaymentConfirmation.financial_dues;            
              }

              // 2- if amount is not empty and we empty netCharge and  Acti=0  and  min_charge=-ve found  (bestCase senario)
              //   A      N 
              //   2 =>  2  => net changed to - so Amount -
              //   - => -  => 
              //  comefromEmpytedAmountByNetCond meaning that if Amount is empty and comefromEmpytedAmountByNetCond =true dont' apply the netCharge function in AountWatch
              
              else{
                this.Amount='';
                this.comefromEmpytedAmountByNetCond=true;   
              }
        }
      }  
     
  } 
  },
  methods: {
   
    validateMeterNumber() {
      return this.rechargeForm.meter_code != ''
    },
   
    validateAccountReference() {
    return this.rechargeForm.account_reference != ''
    },

    validateRechargeAmount() {
    const amount = this.rechargeForm.amount
    return (
      amount != '' && 
      amount != false && 
      amount != null && amount >= this.rechargeForm.min_charge_amount

      )
    },

    validateForm() {
      return this.validateMeterNumber() && this.validateAccountReference(); 
    },

    addPlaceHolder(tybe){
    if(tybe==1)  {
      //meter
      return this.lang==2 ? this.company.fastRecharge_elements.meter_placeholder_en 
                              : this.company.fastRecharge_elements.meter_placeholder_ar; 
    }
    else if(tybe==2){
      //unit
      return this.lang==2 ? this.company.fastRecharge_elements.unit_placeholder_en 
                              : this.company.fastRecharge_elements.unit_placeholder_ar;   
    }
    else if(tybe==3){
      return this.lang==2 ? this.company.fastRecharge_elements.phone_number_placeholder_en 
                              : this.company.fastRecharge_elements.phone_number_placeholder_ar; 
    } else if(tybe==4){
      return this.lang==2 ? this.company.fastRecharge_elements.account_reference_placeholder_en 
                              : this.company.fastRecharge_elements.account_reference_placeholder_ar; 
    }
    },

    calcTax(amount) {
      return (Number(amount) + Number.EPSILON + Number(amount *this.company.fees_precentage)).toFixed(2)
    },
    hideModal(ref){
      this.$bvModal.hide(ref);
    },
    showModal(ref) {
      this.$bvModal.show(ref);
      this.modal_closed=false;
    },
    reset(){
      this.modal_closed=true;
      this.PaymentConfirmation.due_payments=0;
      this.PaymentConfirmation.financial_dues=0;
      this.recharge_error=false;
      this.msg='';
      this.Amount='';
      this.netCharge='';
      this.meter_code='';


    },
    getMeterDuePayments() {
      this.disable_pay=false;
      rechargeService.meterDuePayments(this.rechargeForm.account_reference , this.rechargeForm.meter_code, this.lang)
      .then(data => {
        // data.payment_mode='2';
        // data.min_charge_value=-300;
        this.payment_mode=data.payment_mode;
        this.showModal("FastRechargeModal");

        this.PaymentConfirmation.financial_dues= Number(data.min_charge_value)<0 ? -Number(data.min_charge_value):0;
        if(this.payment_mode=='2'){
          this.PaymentConfirmation.due_payments = Number(data.min_charge_value)>0 ? Number(data.min_charge_value):0;
          this.min_payment_value= Number(data.min_charge_value)==0 || Number(data.min_charge_value)<0? this.rechargeForm.min_charge_amount:this.PaymentConfirmation.due_payments + this.rechargeForm.min_charge_amount;
          this.max_payment_value= Number(data.min_charge_value)==0 || Number(data.min_charge_value)<0? this.rechargeForm.max_charge_amount:this.PaymentConfirmation.due_payments + this.rechargeForm.max_charge_amount;
          this.min_NET_val=this.rechargeForm.min_charge_amount+ (data.min_charge_value< 0?-data.min_charge_value:0);
        }
       
        else{ //1
          this.disable_pay=data.min_charge_value==0 || data.min_charge_value<0?true:false;
          this.Amount=Number(data.min_charge_value)<0?0:Number(data.min_charge_value);
        }
        
      })
      .catch(err => {
        let msg = '';
           if(err.message===-102) {
            msg =  this.$t('something_went_wrong') +" "+ this.$t('try_again_later')
          } else {
            msg = err.message
          }
          this.error = true;
          this.error_message = msg;
      })
    },

    handleRecharge(){
      this.recharge_error=false;
      this.rechargeForm.amount=Number(this.Amount);
      rechargeService.fastRecharge(this.rechargeForm, this.total_amount, this.collection_fees,this.lang)
      .then(data=>{
        if(this.rechargeForm.payment_method==="1"){                
            if (data.session_id !== '') {
              checkoutPrepare(data.session_id, data.merchant, data.merch_name,data.total_amount, data.request_number, this.rechargeForm.account_reference,data.session_version)
                  Checkout.showLightbox()
            } else {
              throw new Error("");
            }
        }
        else {
          let PaymentLink=data.redirect_url;  
            if(PaymentLink != null) {
              let payTabsIframe = document.getElementById('payTabs');
              payTabsIframe.innerHTML = "";
              let frame = document.createElement('iframe');
              payTabsIframe.classList.add('iframe-show')
              frame.src = PaymentLink
              
              payTabsIframe.appendChild(frame);
            }
            else
              throw Error("No Link");
        }
      })
      .catch(err => {
          let msg = ''
          if(err.message===-102) {
            msg = this.$t('something_went_wrong')  + " " + this.$t('try_again_later')
          } else {
            msg =  err.message
          }
            this.recharge_error = true;
            this.msg = msg;
        });
        
    },

  },

};
</script>

<style scoped>
@import url("../../assets/styles/form.css");


#modal-sm___BV_modal_header_ {
  /* margin-bottom: 10px; */
  display: block;
  border-bottom: 0.5px solid #eee !important;
}

#modal-sm___BV_modal_header_ .close {
  background-color: transparent !important;
  color: #777 !important;

  margin-left: -5px !important;
  border: none !important;
  font-size: 2rem;
}



.right-close {
  justify-content: start !important;
}
.left-close {
  justify-content: end !important;
}

.modal-header {
  border: none;
  padding: 0;
  /* padding-right: 5px  !important; */
  margin: 0;
}

.modal-content {
      overflow-y: auto !important;
    width: 400px;
}
#FastRechargeModal___BV_modal_footer_{
  display: block !important;
}
.active {
  color: var(--main-color) !important;
}

/* --------------------------------------------------------- */

#payTabs{
     width: 100%;
    height: 100%;
  /* margin: auto; */
}

#payTabs iframe{
width:100%;
height:100%;
}

/* --------------------------------------------------------- */

.fast-recharge .form-control{
  width: 100% !important;
}

.fast-recharge div {
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}

.fast-recharge div .form-group{
  width: 48%;
  flex: 1 auto;
  margin-bottom: 0px;
}

/* --------------------------------------------------------- */

.currency-left {
  position: absolute;
  left: 16px;
  top: 10px;
  color: #4F4D61;
}

.currency-right {
  position: absolute;
  right: 16px;
  top: 10px;
  color: #4F4D61;
}

/* --------------------------------------------------------- */

.after-tax-amount {
  width:48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px !important;
  height: 100%;
}

.after-tax-amount__title {
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
}

.after-tax-amount__value {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-color);
}

/* --------------------------------------------------------- */

.accepted-payments__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 14px;
  color: #2C3E50;
}

.accepted-payments__container span {
  font-size: 14px;
}
/deep/ #FastRechargeModal___BV_modal_header_.modal-body{
  min-height:180px;
}
/deep/ #FastRechargeModal___BV_modal_header_.modal-header{
  height: auto;
  padding: 32px 24px;
  padding-bottom: 0px;
  font-weight: 600 !important;
  font-size: 24px;
  /* line-height: 40px; */
  align-items: center;
  color: #2C3E50;
  position: relative;
 }
 /deep/ #FastRechargeModal___BV_modal_header_.modal-header.left-dir{
  flex-direction: row-reverse;
 }
 /deep/ #FastRechargeModal___BV_modal_header_.modal-header i{
  cursor: pointer;
  position: absolute;
  top:12px;
  right:12px;
 }
 /deep/ #FastRechargeModal___BV_modal_header_.modal-header.left-dir i{
  right:unset;
  left:12px;
 }
 /deep/ #FastRechargeModal___BV_modal_header_.modal-header p{
  font-weight: 600 !important;
  font-size: 24px;
 }
 /deep/ #FastRechargeModal___BV_modal_footer_.modal-footer{
  padding: 24px;
  display: flex !important;
 }
 .recharge-body{
  min-height:130px;
 }
 .recharge-body p{
  font-size:16px;
  margin-bottom: 8px;
 }
 .postpaid input{
  width:100%;
  background-color: #F0F0F0;
 }
 @media (max-width:500px) {
  .fast-recharge >div:first-child{
    flex-direction: column;
  }
  .fast-recharge .form-group{
    width:100% !important;
  }
  
 }
</style>

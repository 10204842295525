import axios from "axios";
import { getCookie } from "./utils";

export default () => {
    const apiClient = axios.create({
        baseURL:  process.env.NODE_ENV == 'development' ? 'https://cpdev11.pylonump.com/api/index.php/api/' :  `${window.location.origin}/api/index.php/api/`,
        headers: {
            "Accept": "application/json",
            // "Auth": getCookie('Auth'),
            "X-CSRF-TOKEN": getCookie('XSRF-TOKEN'),
        },
        withCredentials: true,
        validateStatus: function (status) {
            return status < 500;
        },
    });
    
    return apiClient;
}
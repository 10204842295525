export default {
// LOGIN PAGE
  login: 'تسجيل الدخول',
  username: 'كود المستخدم',
  username_placeholder: 'مثال XXXX',
  email_placeholder: 'example@example.com',
  account_reference:"كود العميل",
  password: 'كلمة المرور',
  new_password: 'كلمة المرور الجديدة',
  password_placeholder: '***********',
  first_time_pass:"إذا كنت تسجل الدخول لأول مرة ، فستكون كلمة مرورك هي ",
  submit: 'تسجيل الدخول',
  login_error_feedback: 'من فضلك تأكد من كود المستخدم و كلمة المرور و حاول مرة آخرى',
  customer_service_numbers: 'أرقام خدمة العملاء',
  customer_support: 'خدمة العملاء',

  // ===================================================================================================================================
  // =========================================== Validation localisation ===============================================================
  // ===================================================================================================================================
  invalida_email: {
    required: 'البريد الإلكتروني مطلوب',
    format: "لا بد أن يكون بريد إلكتروني صحيح"
  },

  // ===================================================================================================================================
  // =========================================== Validation localisation ===============================================================
  // ===================================================================================================================================

  Apply_for_meter:" التقديم على عداد جديد",
  recharge_meter:"شحن العداد",
  recharge_meter_and_due:"شحن العداد & دفع المستحقات",
  or:"أو",
  meter_code:"رقم العداد",
  meter_code_placeholder:"مثال xxxxxx",
  phone_number:"رقم الهاتف",
  phone_num_placeholder:"مثال 01xxxxxxxxx",
  unit_code:"كود الوحدة:",
  unit_code_placeholder:"مثال xx",
  recharge_value:"قيمة الشحن",
  recharge_value_placeholder:"مثال 100",
  total_after_tax: "المبلغ الكلي شامل رسوم التحصيل ",
  We_accept:"نقبل",
  Recharge:"شحن الرصيد",

  back:"رجوع",
  back_to_login:"الرجوع لصفحة تسجيل الدخول",
  reset_password:"إعادة تعيين كلمة المرور",
  phone_num:"رقم المحمول",
  phone_number_placeholder:"قم بإدخال رقم المحمول",
  via_sms:"رسالة نصية",
  via_email:"ايميل",
  forgot_pass:"هل نسيت كلمة المرور؟",
  forget_msg:"تواصل مع خدمة العملاء لإعادة تعيين كلمة المرور",
  sms_note:"تم إرسال رسالة نصية لك برقم سري مؤقت, استخدم الرقم السري المؤقت لتسجيل الدخول ",
  email_note:"تم إرسال بريد إلكتروني لك برقم سري مؤقت لتسجيل الدخول",
  didnt_recieve_email:"لم يتم إستلام بريد إلكتروني؟",
  didnt_recieve_sms:"لم يتم إستلام رسالة نصية؟",
  resend_again:"أرسل مجدداً",
  after:"بعد 1:29",
  go_to_login:" صفحة تسجيل الدخول",

  home: "الرئيسية",
  meter:"عداد",
  my_meters:"العدادات",
  alarm_services: "خدمات الانذار",
  meters_screens_guide: "دليل شاشات العدادات",
  meter_screens_guide:"دليل شاشات العداد",
  requests:"طلبات",
  submit_request: 'تسجيل الطلب',
  faq:"الأسئلة الشائعة",
  faq_header:"الأسئلة الشائعة",
  customer_complaint: "شكاوي المستهلك",
  water: "مياه",
  electricity: 'كهرباء',
  gas: 'غاز',
  meter_no: "رقم العداد",
  charge_amount: "قيمة الشحن",
  payment_methods: "طرق الدفع",
  last_recharge_date: "تاريخ اخر عملية شحن :",
  last_payment_date: 'تاريخ آخر عملية دفع: ',
  thursday: "الخميس",
  date_of_meter_reading: "تاريخ اخر قراءة للعداد :",
  remaining_credit: "الرصيد الحالي",
  reflected_soon:"سيتم إضافة آخر شحن خلال ساعتين",
  last_recharge_msg:"اخر عملية شحن بقيمة",
  failure_Recharge_msg:"فشلت بسبب فشل الإتصال بالعداد. الرجاء الاتصال بدعم العملاء على ",
  required_money: 'المبلغ المطلوب',
  as_of:" آخر قراءة بتاريخ",
  recharge: "شحن الرصيد / المستحقات",
  pay_bills: 'دفع الفواتير',
  current_month: "الشهر الحالي:",
  last_month: "الشهر الماضي",
  cons:"الإستهلاك",
  val_of_cons:"قيمة الإستهلاك",
  Money_Spent_on_consumption:"الإستهلاك الخاص بك",
  chart_Alignment:"left",
  The_red_section:"الجزء الأحمر",
  is_a_prediction_of_what_you_will_consume_and_pay:"هو الاستهلاك والمبلغ المتوقع دفعه في نهاية الشهر ",
  at_the_end_of_the_month_if_you_continue:"اذا تم الاستمرار على معدل هذا الاستهلاك",
  with_your_current_consumption_pattern :"",
  payment_history: "  سجل دفع المعاملات المالية",
  payment_details_button:"التفاصيل",
  payment_details_header:"تفاصيل الدفع",
  cash_details:"القيمة",
  clause:"البند",
  consumption: "الإستهلاك الشهري",
  consumption_chart:"الرسم البياني للإستهلاكات",
  choose_your_request: 'إختر طلبك',

  pylon: " بايلون",
  is_a: "هي عبارة عن",
  comprehensive: "منصة",
  utility_management: "شاملة لادارة",
  platform: "المرافق",
  transforming: "تعمل على تحويل",
  converntional: "مرافق",
  and: " و",
  electricity_utilities: "الكهرباء التقليدية",
  in_emerging_markets: "في الاسواق الناشئة ",
  into_efficient: " الي أسواق",
  interoperable: "فعالة وقابلة للتشغيل البيني",
  and: " و",
  data: "قائمة على ",
  driven_ones: "البيانات",

  contact_us: "اتصل بنا",
  call_us_for_any_inquiry_or_help: "اتصل بنا لأي استفسار أو مساعدة",
  location:"الموقع الجغرافي",
  project_location:"موقع المشروع",
  headquarters: "المقر الرئيسي",
  navigation: "خريطة الموقع",
  Profile:"الصفحة الشخصية",
  notification:"الإشعارات",
  download_App:"تحميل التطبيق",

  enter_your: 'أدخل',
  name:'الإسم',
  account_name: "إسم المستخدم",
  account_type: "نوع الحساب",
  email_address: "البريد الإلكتروني",
  email:"البريد الإلكتروني",
  building:"المبنى",
  group:"المشروع",
  unit:"الوحدة",
  block:"القطعة",
  TMG_block:'بلوك',
  TMG_group:'مجموعة',
  TMG_building:'مجمع',
  mobile_number: "رقم الهاتف",
  current_password:"كلمة المرور الحالية",
  new_password_err:'قم بكتابة كلمة المرور الجديدة',
  confirm_password: "أعد كتابة كلمة المرور الجديدة",
  new_curr_pass_are_qual:'كلمة المرور الحالية والجديدة متساويين',
  update: "تحديث",
  update_pesonal_info_note:"إذا رغبت بتحديث أي بيانات, بالرجاء التواصل مع خدمة العملاء على",
  update_personal_info: 'تحديث المعلومات الشخصية',
  update_notifications_settings: 'تحديث اعدادات الاشعارات ',
  complaint_type: "نوع الشكوى",
  send: "ارسال",

  serial:"الرقم مسلسل",
  month:"الشهر",
  net_consumption:"صافي الاستهلاك",
  estimated_money: 'إجمالي القيمة',
  est_money:'إجمالي القيمة المتوقعة',

  energy_consumption_per_month: 'استهلاك الطاقة / شهر',
  estimated_consumption_for_next_month:'الاستهلاك المتوقع للشهر القادم',
  receipt:"الإيصال",
  receipt_no:"رقم الايصال",
  show_receipt: "عرض الإيصال",
  transaction_date:"التاريخ",
  bill_month: 'تاريخ الفاتورة',
  from_to: 'من - إلى',
  status: 'الحالة',
  payment_date: 'تاريخ الدفع',
  cash:"قيمة استلام نقدية",
  credit_card:"قيمة استلام كارت الائتمان",
  bank_transfer:"قيمة التحويل البنكي",
  checks:"قيمة الشيكات",
  on_account:"دفعات مقدمة للعميل",
  amount: 'مبلغ الدفع',
  Net_Charge:"صافي الشحن",
  
  enter_due_and_recharge_amount:"أدخل قيمة الشحن وقيمة المستحقات المرجو دفعها",

  pay: 'دفع',
  loading: 'جاري التحميل',
  sorry: '!نأسف',
  something_went_wrong: 'هناك خطأ ما',
  try_again_later: 'حاول مجددا لاحقا',
  no_data_found: 'لا يوجد بيانات',

  card_no: 'رقم البطاقة',
  expiry_date: 'تاريخ الانتهاء',

  pm: "م",
  egp: "جنيه مصري",
  kwh: "كيلو وات/ساعة",
  kw: 'كيلو وات',
  m3:'متر مكعب',
  full_date_notation: '(HH:MM DD/MM/YYYY)',
  date_notation: '(DD/MM/YYYY)',
  month_date_notation: '(MM/YYYY)',

  mdm_billing_cycle: 'الشهر ',
  energy_diff: 'صافي الاستهلاك', 
  est_tariff_value: 'إجمالي القيمة',
  code: 'رقم العداد',

  password_description: "كلمة السر الخاصة بك يجب ان تكون مكونة من 8 الى 20 حرف,  تحتوي على أحرف و أرقام, و لا تحتوي على مسافات فارغة أو رموز غريبة",

  subject: 'الموضوع',
  description: 'الوصف',
  choose_meter_no: 'اختر رقم العداد',
  recharge_balance:"شحن الرصيد",
  due_note:"لاحظ أنه يتعين عليك سداد جميع المستحقات حتى تتمكن من شحن عدادك",
  you_have_dues:"عليك مستحقات بقيمة",
  due_note:"يجب دفعها لتستطيع شحن العداد",
  you_have_installement:"لديك مستحقات",
  installment_note:"في الحساب نتيجة دفعات مالية",
  Show_Details:"أظهر التفاصيل",
  Hide_Details:"أخفي التفاصيل",
  total:"الإجمالي",
  credit:"الحساب",
  post_due_note:"في الأسفل المستحفات المطلوب سدادها",
  service_payments:"مدفوعات الخدمة",
  due_payments:"المستحقات",
  collection_fees:"رسوم التحصيل",
  select_due:"حدد المستحقات التي تريد دفعها",
  min_charge_amount: 'أقل قيمة للشحن هي',
  min_payment_amount:"أقل قيمة للدفع هي",
  min_net_amount:"أقل قيمة لصافي الشحن هي",
  min_amount:"أقل قيمة",
  charge_amonut:"صافي شحن العداد",
  enter_amount:"ادخال قيمة",
  confirmation_message:"تأكيد شحن الشحن العداد رقم:",
  cancel:"إلغاء",
  save:"حفظ",
  total_amount:"المبلغ الإجمالي",
  make:"إنشاء",
  complaint: 'شكاوي',
  complaints: 'شكاوي',
  inquiry: 'استفسارات',
  personal_info: 'البيانات الشخصية',
  notification_settings: 'إعدادات الإشعارات',
  critical_credit:"الرصيد الحرج",
  critical_desc:" الرصيد الذي تريد أن يتم إرسال رسالة تحذيرية عند الوصول إليه ",
  critical_amount:"المبلغ الحرج",
  critical_amount_desc:" المبلغ الذي تريد أن يتم إرسال رسالة تحذيرية عند الوصول إليه ",
  sms: 'رسالة قصيرة',
  mobile_app: 'تطبيق جوال',
  customer_portal: 'بوابة العملاء',

  current: 'الحالي',
  expected: 'المتوقع',
  current_month: 'الشهر الحالي',
  last_month: 'الشهر الماضي',

  language: 'اللغة',

  success: 'تم بنجاح',
  warning: 'تحذير',
  error: 'خطأ',

  logout: 'تسجيل الخروج',

  // Meter Screen Guide
  meter_type:"نوع العداد",
  screen: 'الشاشة',
  screen_no: 'رقم الشاشة',

  desc_0_0_19: 'شاشة لعرض الرصيد المتبقي على العداد ( جنيه مصري )',
  desc_C_1_0: 'شاشة لعرض رقم العداد',
  desc_C_1_5: 'شاشة لعرض رقم التطبيق المربوط بالعداد',
  desc_2_9_0: ' شاشة لعرض التاريخ على العداد ( يوم-شهر-سنة )',
  desc_1_9_0: 'شاشة لعرض الساعة على العداد ( ساعة:دقيقة:ثانية )',
  desc_0_8_1: ' شاشة لعرض الاستهلاك الكلي ( كيلو وات/ساعة )',
  desc_0_9_1: ' شاشة لعرض الاستهلاك الحالي ( كيلو وات/ساعة )',
  desc_0_7_1: 'شاشة لعرض الكمية الكلية للطاقة المدخلة للعداد ( كيلو وات )',
  desc_0_7_3: 'شاشة لعرض الكمية الكلية للطاقة الفعالة على العداد ( كيلو وات/ فار )',
  desc_0_6_15: 'شاشة لعرض أقصى حمل  ( كيلو وات ) ',
  desc_F_F_0: 'شاشة لعرض أكواد التلاعبات',
  desc_C_6_18: 'شاشة لعرض الاستهلاك الحالي  ( جنيه مصري )',
  desc_C_14_0: 'شاشة لعرض كود التعريفة الفعالة على العداد',
  desc_0_2_0: 'شاشة لعرض كود نسخة البرنامج المثبتة حاليا على العداد',
  desc_0_7_31: ' شاشة لعرض قيمة التيار اللحظية للفاز 1 ( أمبير )',
  desc_0_7_51: 'شاشة لعرض قيمة التيار اللحظية للفاز 2 ( أمبير )',
  desc_0_7_71: 'شاشة لعرض قيمة التيار اللحظية للفاز 3 ( أمبير ) ',
  desc_0_7_32: 'شاشة لعرض قيمة الفولت اللحظية للفاز 1 ( فولت ) ',
  desc_0_7_52: 'شاشة لعرض قيمة الفولت اللحظية للفاز 2 ( فولت ) ',
  desc_0_7_72: ' شاشة لعرض قيمة الفولت اللحظية للفاز 3 ( فولت )',

  // Water meters
  water_notice_1: "إذا تم قراءة الكارت بنجاح ستقوم اللمبة بالإضاءة مرة واحدة و الشاشة بعرض Cd xx.",
  water_notice_2: "إذا لم  يتم قراءة الكارت بنجاح ستقوم اللمبة بالإضاءة مرتان و الشاشة بعرض Er xx.",
  
  water_desc_1: 'تظهر هذه الشاشة عند وضع كارت العميل على العداد لتحديث بيانات الكارت لإجراء عملية الشحن أو للإستعلام على الإستهلاك أو الرصيد (ملحوظة: الرقم 70 يكون 71 إذا كان كارت العميل يحتوي على شحنة لتفرغ في العداد)',
  water_desc_2: 'يظهر في هذه الشاشة آخر ستة أرقام من رقم العداد المسلسل',
  water_desc_3: 'يظهر في هذه الشاشة الرصيد المتبقي ( الجنيه المصري )',
  water_desc_4: 'يظهر في هذه الشاشة الإستهلاك الكلي ( الجنيه المصري )',
  water_desc_5: 'يظهر في هذه الشاشة الإستهلاك الكلي ( المتر المكعب ) ',
  water_desc_6: 'يظهر في هذه الشاشة إستهلاك الشهري الحالي ( الجنيه المصري )',
  water_desc_7: 'يظهر في هذه الشاشة إستهلاك الشهري الحالي ( المتر المكعب ) ',

  //codes_of_parameters
  codes_of_parameters:'اكواد العداد',
  card_read_code:"كود قراءة الكارت",
  card_read_code_description:"وصف كود قراءة الكارت",
  error_code:"كود الخطأ",
  error_code_description:"وصف كود الخطأ",


  code_desc_1:"تم ادخال الكارت",
  code_desc_2:"اكتملت عملية ضبط وقت الكارت",
  code_desc_3:"اكتملت عملية ادخال الكارت",
  code_desc_4:"اكتملت عملية الإستعلام عن الكارت (وظيفةالإستعلام)",
  code_desc_5:" اكتملت عملية الإستعلام عن الكارت (تغيير وظيفةالجدول)",
  code_desc_6:"اكتملت عملية إضافة كارت المياه",
  code_desc_7:"اكتملت عملية كارت المستخدم غير الصالحة",
  code_desc_8:"اكتملت علية تفعيل كارت المستخدم(إضافة شحنة مياه) ",
  code_desc_9:"اكتملت عملية إعادة المياه لكارت المستخدم",
  code_desc_10:"اكتملت عملية اعداد وضع الدفع المسبق/اللاحق للكارت ",
  
  error_desc_1:"خطأ في قراءة الكارت",
  error_desc_2:"تعديل خاطئ",
  error_desc_3:"خطأ في التحقق من كلمة المرور",
  error_desc_4:"بطاقة غير قانونية (خطأ في تحديد البطاقة) ",
  error_desc_5:"خطأ في كود النظام / خطأ في إعادة كتابة البطاقة",
  error_desc_6:"خطأ في إضافة كارت المياه",
  error_desc_7:"خطأ في كود النظام (بطاقة المستخدم)",
  error_desc_8:"رقم عدادالمياه غير موجود (كارت المستخدم)",
  error_desc_9:"لا تخطئ في الكارت (كارت المستخدم)",
  error_desc_10:"",

  // inhemeter desc
  inhemeter_desc_0_9_1:"الوقت الحالي",
  inhemeter_desc_0_9_2:"التاريخ الحالي",
  inhemeter_desc_1_6_0:"Positive active maximum demand and occurrence time (A+)",
  inhemeter_desc_1_6_0_1:"اقصى حمل الشهر السابق",
  inhemeter_desc_1_6_0_2:"Last 2nd month forward active maximum demand and occurrence time",
  inhemeter_desc_1_6_0_3:"Last 3rd month forward active maximum demand and occurrence time",
  inhemeter_desc_1_8_1:"إجمالي الطاقة الإيجابية النشطة (A+) في التعريفة 1",
  inhemeter_desc_3_8_1:"إجمالي الطاقة التفاعلية الإيجابية (Q+) في التعريفة 1",
  inhemeter_desc_2_8_1:"إجمالي الطاقة السلبية النشطة (A-) في التعريفة 1",
  inhemeter_desc_4_8_1:"إجمالي الطاقة التفاعلية السلبية (Q-)  في التعريف 1",
  inhemeter_desc_1_8_2:"إجمالي الطاقة الإيجابية النشطة (A+) في التعريفة 2",
  inhemeter_desc_3_8_2:"إجمالي الطاقة التفاعلية الإيجابية (Q+) في التعريفة 2",
  inhemeter_desc_2_8_2:"إجمالي الطاقة السلبية النشطة (A-) في التعريفة 2",
  inhemeter_desc_4_8_2:"إجمالي الطاقة التفاعلية السلبية (Q-) في التعريفة 2",
  inhemeter_desc_15_6_1:"Active maximum demand and occurance time (A) in traiff 1",
  inhemeter_desc_15_6_2:"Active maximum demand and occurance time (A) in traiff 2",
  inhemeter_desc_15_8_0:"إجمالي الطاقة النشطة (|+A|+|-A|)",
  inhemeter_desc_15_8_1:"إجمالي الطاقة النشطة (|+A|+|-A|) في التعريفة 1",
  inhemeter_desc_15_8_2:"إجمالي الطاقة النشطة (|+A|+|-A|) في التعريفة 2",
  inhemeter_desc_1_8_0:"(+A) الطاقة النشطة المدخلة",
  inhemeter_desc_2_8_0:"إجمالي الطاقة النشطة السلبية (A-)",
  inhemeter_desc_3_8_0:"إجمالي الطاقة التفاعلية الإيجابية (Q+)",
  inhemeter_desc_4_8_0:"إجمالي الطاقة التفاعلية السلبية (Q-)",
  inhemeter_desc_42_0_0:"COSEM logical device name",
  inhemeter_desc_C_53_12:"Cumulative tripping times caused by reverse phase sequence current",
  inhemeter_desc_81_7_4:"زاوية الطور من I(L1) إلى U(L1)",
  inhemeter_desc_81_7_15:"زاوية الطور من I(L2) إلى U(L2)",
  inhemeter_desc_81_7_26:"زاوية الطور من I(L3) إلى U(L3)",
  inhemeter_desc_13_7_0:"معامل القدرة اللحظي (+A/+VA)",
  inhemeter_desc_31_7_0:"التيار اللحظي L1(A)",
  inhemeter_desc_51_7_0:"التيار اللحظي L2(A)",
  inhemeter_desc_71_7_0:"التيار اللحظي L3(A)",
  inhemeter_desc_32_7_0:"التيار اللحظي L1(V)",
  inhemeter_desc_52_7_0:"التيار اللحظي L2(V)",
  inhemeter_desc_72_7_0:"التيار اللحظي L2(V)",
  inhemeter_desc_33_7_0:"معامل القدرة اللحظي (PF) L1",
  inhemeter_desc_53_7_0:"معامل القدرة اللحظي (PF) L2",
  inhemeter_desc_73_7_0:"معامل القدرة اللحظي (PF) L3",
  inhemeter_desc_0_4_2:"CT بسط محول التيار",
  inhemeter_desc_0_4_5:"CT مقام محول التيار",

  // POSTPAID required bills
  payment_details:"التفاصيل",
  TName: 'الفئة',
  Type: 'النوع',
  Date: 'التاريخ',
  Name: 'الاسم',
  Value: 'القيمة',


  // request page
request_steps:"خطوات اتمام الطلب",
needed_docs:"الأوراق المطلوبة",
paper_1:"قم بتحميل نموذج الطلب ثم قم بطباعته وإستيفاء البيانات المطلوبة",
paper_2:"البطاقة الشخصية",
paper_3:"paper ...",
paper_4:"paper .....",
step_1:"ملئ النموذج المطبوع ثم الإمضاء عليه",
step_2:"الذهاب إلى مركز الإدارة وتسليم جميع الأوراق المطلوبة",
Download:"تحميل النموذج",  
Upload:"رفع النموذج",
confirm:"تأكيد",
notes_placeholder:"اكتب ملاحظاتك",
// خطوات طلب التنازل
step11:"قم بتحميل نموذج طلب التنازل",
step12:"قم بطباعة النموذج",
step13:"قم بلمئ طلب التنازل ثم الامضاء عليه",
step14:"قم برفع الطلب",
step15:"قم بتدوين ملاحظاتك",
step16:"قم بتأكيد الطلب",

// Invoice 
payment_voucher: "سند سداد",
payment_receipt: "إيصال سداد",

hello: "مرحبا, ",
your_transaction: "عملية الدفع الخاصة بك",
number: "رقم",
successfull: "ناجحة",
was: "كانت",
on: "بتاريخ",
transaction: "عملية الدفع",
amount_payed: "المبلغ المدفوع",
service: "الخدمة",
details: "نفاصيل",
service_details: "تفاصيل الخدمة",
card: "كارت",
customer_code: "كود العميل",
charge_token: "رقم الشحنة",
mostakbal_city: "مستقبل سيتي",
service_value: "قيمة الخدمة",
the_price: "السعر",
the_tax: "الضريبة",
id: "الرقم المرجعي",
order_summary: "ملخص الطلب",
payment_method: 'طريقة الدفع',
tax_on: "ضريبة على",
invoice_additional_message: "للمزيد من المعلومات الخاصة بعملية الدفع برجاء التواصل مع مقدم الخدمة و إستخدام الرقم المرجعي ",
invoice_failure_additional_message:"للمزيد من المعلومات الخاصة بفشل عملية الدفع برجاء التواصل مع مقدم الخدمة و إستخدام الرقم المرجعي",
invoice_powered_by: "بواسطة",
failed:"فاشلة",

// Requests 
request_feedback: {
  your_request: "طلبك رقم",
  successfull: "تم تسجيله بنجاح",
  failed: "فشل",
  check: "مع ملاحظة أنه لن يتم التعامل على الطلب إلا بعد استيفاء نموذج الطلب والمستندات المطلوبة، ثم إرسالها على إيميل خدمة العملاء",
  another_request: "هل تريد تسجيل طلب جديد؟",
},

// Fast Recharge
fast_recharge: {
  shortAmount: 'عذرا, يبدوا ان لديك مستحقات مالية مطلوبة, لذا أقل قيمة للشحن هي: (100 + مبلغ المستحقات المالية) جنيه مصري '
},
invalid_recharge_request:"طلب شحن العداد غير صالح ",
canceled_recharge_request:"تم إلغاء طلب شحن العداد",
invalid_pay_bills_request:"طلب دفع الفاتورة غير صالح",
canceled_pay_bills_request:"تم إلغاء طلب دفع الفاتورة",

max_recharge_amount:"أقصى قيمة للشحن هي",
max_payment_amount:"أقصى قيمة للدفع هي",
// search
search_placeholder:"ابحث عن كلمات...",
search:"بحث",

change_password:"تغيير كلمة المرور",
success_password_msg:"!تم تغيير كلمة المرور بنجاح",

submit_request_for_registered_user_note_1:"ملاحظة: اذا كنت عميل مسجل برجاء ",
submit_request_for_registered_user_note_2:"أولا حتى تتمكن من إكمال الطلب اونلاين",
no_docs_required:"لا يوجد أوراق مطلوبة",
copy_rights:"Pylon © 2024. جميع الحقوق محفوظة",
enter_amount_for_payment:"يجب ادخال قيمة لإتمام عملية الدفع ",
critical_credit_note:"تأكد أن رصيدك الحرج غير فارغ ورقم أكبر من 100",
mobile_validation:[ "تأكد أن رقم هاتفك المحمول:",
"غير فارغ","يحتوي على أرقام فقط","يتكون من 11 رقم"
],
enter_current_pass:"أدخل الرقم السري الحالي",
email_validation:[ "تأكد أن رقم بريدك الإلكتروني:",
"غير فارغ","في صيغة 'something@example' "],
password_validation:["بين 8 إلى 20 حرف ","على الأقل حرف صغير واحد",
"على الأقل حرف كبير واحد","على الأقل رقم واحد","على الأقل حرف خاص واحد ($,%,% مثل) "],
no_matching_pass:"غير متطابق, تأكد من كتابة نفس كلمة المرور",
supplied_data_wrong:"البيانات المدخلة خطأ",
required_meter:"كود العداد مطلوب",
subject_required:"الموضوع مطلوب",
desc_required:"الوصف مطلوب",

your_inquiry:"استفسارك",
your_complaint:"شكوتك",
registered_success:"تم تسجيلها بنجاح",
case_number:"رقم الحالة هو :",
non_refelected_recharge_1:" اخر شحن بقيمة",
non_refelected_recharge_2:"سيتم إضافته خلال ساعتين",

no_complaints_found:"لا توجد شكاوى متاحة",
no_inquiries_found:"لا توجد استفسارات متاحة",

should_Add_due_payment_value:"يجب عليك اضافة قيمة المستحقات المرجو دفعها وهي:",
faild_login_msg:"حدث خطأ ما أثناء محاولة تسجيل الدخول",

zero:"صفر",

};

<template>
  <div class="meter-info" style="max-width:856px; margin:auto">
    <div class=" my-3">
      <b-row style=" padding: 0px;margin: 0px;" v-bind:class="{ rtl: lang ==1, ltr: lang ==2 }">
        <div class=" relative mb-3">
          <Loading v-if="infoLoading" />
          <ErrorHandler v-if="infoError" :error="errorMessage" />
          
          <div v-if="meter">
            <div  v-if="meter.payment_mode == '2' && show_reflected_soon && meter.non_reflected_charge_value!='0'" class=" alert alert-warning "  ref="warningAlret" style="padding: 12px;border:0px" role="alert">
                  <div>
                    <i class="pylon-icon-Warning"  style="color: #FFCC1F;"></i>
                    <span style="display:block; min-width: 92%;" > {{ $t("non_refelected_recharge_1") }} ({{meter.non_reflected_charge_value}}) {{ $t('non_refelected_recharge_2') }}</span> 
                    <i class="pylon-icon-Close" style="align-self: baseline; cursor:pointer" @click="hide(2)" ></i>
                </div>
                </div>

                <div   v-if="meter.payment_mode == '2'  && meter.non_reflected_charge_value !=0 && not_reflected_error==true " ref="dangerAlret"  class=" alert alert-danger alert-dismissible"  style="padding: 12px;border:0px" role="alert">
                  <div>
                    <i class="pylon-icon-Warning"  style="color: #D83B57;"></i>
                    <span class="non-reflected">
                      {{$t("last_recharge_msg")}} ({{meter.non_reflected_charge_value}} {{$t("egp")}}) {{$t("failure_Recharge_msg")}}
                      <a  v-for="(number,index) in company.customer_service_numbers" :key="number" :href="`tel:${number}`">
                        <span class="mx-2 ">{{number}} </span>
                        <span v-if="index != company.customer_service_numbers.length-1">,</span>
                      </a>
                    </span>
                    <i class="pylon-icon-Close" style="align-self: baseline; cursor:pointer" @click="hide(1)"></i>
                    </div>
              </div>
            <div class="">
              <div v-if="company.remaining_credit">
              <div
                class="remaining"
                :class="{
                  'text-end': lang ==1,
                  'text-start': lang ==2,
                }">

                <b v-if="meter.payment_mode == '1'">{{
                  $t("required_money")
                }}</b>

                <b  v-if="meter.payment_mode == '2'">{{
                  $t("remaining_credit")
                }}</b>

              </div>
                
                <div class="credit__value">
                  <span class='val' :class="{'floating-right': lang==1}" v-if="meter.payment_mode == '1'" >
                    {{ meter.min_charge_value }}
                  </span>

                  <span class='val' v-if="meter.payment_mode == '2'"  :class="{'floating-right': lang==1}" >
                    {{ meter.current_credit }}                
                  </span>

                  <span class='val' >{{  $t("egp") }}</span>
                  <b v-if="meter.payment_mode == '2'"  class="remaining-power">( <span>{{meter.remaining_kwh}}</span> <span>{{unity}}</span> )</b>
                
                </div>

               </div>
                <div class="d-flex justify-content-between flex-wrap">
                  <p v-if="company.last_update_date_time && meter.last_reading_date" class="last-update" :class="{'handle-dir-last-update' : lang==1}"> {{ $t("as_of") }} {{ meter.last_reading_date }} </p>
                  <Recharge class="recharge-com" :class="{'ltr': lang==2,'rtl': lang==1}" :code="code" :lang="lang" :meter="meter" v-if="meter && company.recharge_from_home" :company="company" />
                </div>
               
               </div>
                   
          </div>
        </div>

        <div v-if="company.consumption_chart" class=" relative" style="min-height:200px">
          <h3 class="chart-title">{{$t('consumption_chart')}}</h3>
         
          <Loading v-if="chartLoading" />
          <ErrorHandler v-if="chartError" :error="errorMessage"/>

          <div v-if="chartLoading==false && chartError == false"> 
            <p v-if="lang==1" class="mb-1" style="position: absolute;top: 17%; right: 7.5%;">{{unity}}</p>
            <p v-if="lang==2" class="mb-1" style="position: absolute;top: 17%; left: 4.5%;">{{unity}}</p>         
            <apexchart
              width="100%"
              height = "290"
              type="bar"
              :options="options"
              :series="seriess"
            >
            
            </apexchart>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>
 
<script>
import VueApexCharts from "vue-apexcharts";
import Loading from "@/components/Loading.vue";
import ErrorHandler from "@/components/ErrorHandler.vue";
import Recharge from "@/components/Recharge/Recharge.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    Recharge,
    Loading,
    ErrorHandler,
  },
  props: {
    code: {
      default: () => " ",
    },
    company: {
      default: () => {}
    },
    mdm_meters:{
      default: () => {}
    },
    selectedMeterObject:{
      default: () => {}
    },
    consumptionArray:{
      default: () => {}
    },
    lang: {
      default: () => 'en',
    },
    consumption_error_msg:{
      default: () => {}
    },
    cons_error:{
      default: () => {}
    },
    meter_error_msg:{
      default: () => {}
    },
    meter_error:{
      default: () => {}
    },
    meter_unit:{
      default: () => ''
    }
  },
  data() {
    return {
      show_reflected_soon: false,
      not_reflected_error:false,
      unity:"",
      infoLoading: true,
      infoError: false,
      chartLoading: true,
      chartError: false,
      errorMessage: "",
      meter: {},
      tool:[],
      variant_tool:{},
      options: {

          chart: {
            toolbar: {
        show: false,
        },
            stacked: true,
            id: "Consumption-chart"
          },
        
        stroke: {
              width: [0,0.5],
              colors:["transparent","#BF0404"],
              dashArray:2,
            },

      colors:["#0D456F","#bf040433"],
      xaxis: {
        categories: []
      },
      yaxis: {
        opposite: this.lang ==1 ? true: false,
        labels: {
          formatter: function (value) {
            return  Math.round(value);
          }
        },
 
      },
       dataLabels: {
          enabled: false
        },
        
      legend: {
           show: true,
            position: 'top',
            horizontalAlign: 'right'
           },
     plotOptions: {
              bar: {  
                columnWidth:"22px", 
              },
      },
      fill:{
        opacity:1
      },
       tooltip: {

           fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
      },
         style:{
           backGround:'red',
           borderRaduis:'0px',      
           direction: this.lang ==1? 'rtl' : 'ltr'   
         },
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {           
            const amount = new Intl.NumberFormat('en-IN').format(this.tool[dataPointIndex].y);
            const value = new Intl.NumberFormat('en-IN').format(this.tool[dataPointIndex].consume);    
            if(dataPointIndex!=5){
 
            return `
            <div class="custom_tooltip  tool  ${this.lang  == 1? 'rtl' : 'ltr'}" >
                
                <div class="content custom-tooltip-conent-container" >
                <p class="d-flex justify-content-between">
                    <span class="rtl" style="font-weight: 600 !important">
                          ${this.$t( "cons")}: </span>
                    <span>${value} ${this.unity}</span>
                </p>
                 <p class="d-flex justify-content-between">
                  <span style="font-weight: 600 !important">  ${this.$t("val_of_cons")}: </span>
                  <span> ${amount} ${this.$t("egp")}  </span> 
                  </p>
                   </div>
                </div>`;
            }

            else{
            return `<div class="tool  ${this.lang == 1 ? 'rtl' : 'ltr'}" >
                <div class="content">
                    <p class="d-flex justify-content-between">
                    <span class="rtl" style="font-weight: 600 !important">
                          ${this.$t( "cons")}:  </span>
                    <span>${value} ${this.unity}</span>
                </p>
                 <p class="d-flex justify-content-between">
                  <span style="font-weight: 600 !important">  ${this.$t("val_of_cons")}: </span>
                  <span> ${amount} ${this.$t("egp")}  </span> 
                  </p>
                 
                  <p class="h" style="margin-bottom:-1px !important">
                  -${this.$t("The_red_section")} (${this.variant_tool.y}${this.$t("egp")},${this.variant_tool.consume}${this.unity}) 
                  <p class="h" style="margin-bottom:-1px !important">${this.$t("is_a_prediction_of_what_you_will_consume_and_pay")}</p>
                  <p class="h" style="margin-bottom:-1px !important">${this.$t("at_the_end_of_the_month_if_you_continue")}</p>
                  <p class="h" style="margin-bottom:-1px !important">${this.$t("with_your_current_consumption_pattern")}</p>  
                   </div>
                </div>`;
            }


          }
        },
      },
    seriess: [{
      name: this.$t('current'),
      data: []
        },
    {
      name: this.$t('expected'),
     data:[],
        }
    ],

    };
  },
 created(){
    if (this.meter_unit && this.meter_unit != '') {
          this.unity=this.meter_unit;
    }
 },
  watch: {
    cons_error(){
      this.chartError = this.cons_error;
      if(this.cons_error==true){
      this.chartLoading= false;
      this.errorMessage = this.consumption_error_msg;
      }
    },
    meter_error(){
      this.infoError = this.meter_error;
      if(this.meter_error==true){
      this.errorMessage =  this.meter_error_msg;
      this.infoLoading = false;
      this.meter = false;
      }
    },
    selectedMeterObject () {
      if (this.selectedMeterObject && this.selectedMeterObject != {}) {
        this.getSingleMeter();
      }
    },  
    meter_unit(){
      if (this.meter_unit && this.meter_unit != '') {
        this.infoLoading=true;
        this.chartLoading=true;
        this.unity=this.meter_unit;
      }
    },
    consumptionArray(){
      if (this.consumptionArray && this.consumptionArray != []) {
        this.getConsumption();
      }
    
    },
  },
  methods: {
    hide(type){
     let alret=type==1?this.$refs.dangerAlret:this.$refs.warningAlret;
     alret.style.display='none';
    },
    async   getConsumptionArray(){
    return this.consumptionArray;
    },
    async getMeterData(){
      this.infoError = false;
      this.meter = this.selectedMeterObject;
      this.meter.min_charge_value=((Number(this.meter.min_charge_value)<0 || this.meter.min_charge_value ==null)&& this.meter.payment_mode=='1' )?0:this.meter.min_charge_value;
      
        this.$emit(
          "update-payment",
          this.meter.req_trx_master,
          this.meter.payment_mode
        );
        return this.meter
    },
    getSingleMeter() {
         if(this.meter_error !=true){            
          this.infoLoading = true;
          this.getMeterData()
        .then(meter => {
          if(meter.last_charge_date != null && meter.last_reading_date != null)
          {
            let lcd_date = meter.last_charge_date.split(" ")[0];
            let lcd_time = meter.last_charge_date.split(" ")[1];
            let lcd = Date.parse(lcd_date.split("/")[2] + '-'+lcd_date.split("/")[1]+'-'+lcd_date.split("/")[0] + 'T' + lcd_time + ':00')
  
            let lrd_date = meter.last_reading_date.split(" ")[0];
            let lrd_time = meter.last_reading_date.split(" ")[1];
            let lrd = Date.parse(lrd_date.split("/")[2] + '-'+lrd_date.split("/")[1]+'-'+lrd_date.split("/")[0] + 'T' + lrd_time + ':00')
  
            this.show_reflected_soon = lcd > lrd ;
            let x=new Date();
            const msInHour = 1000 * 60 * 60; //numbers of ms in one hour
            let diff= (new Date().getTime()- new Date(lcd).getTime()) / msInHour;
            if(diff>2){
              this.not_reflected_error=true;
              this.show_reflected_soon=false;
            }
            
          }

        })
        .then(() => {
          // setTimeout(()=>{this.infoLoading = false;},1000)
          this.infoLoading = false;
        })
         } 
    },
   
    getConsumption() {
      if(this.cons_error !=true){
        this.chartLoading = true;
        this.getConsumptionArray()
        .then((data) => {  
            if(data.length){
            this.seriess[0].data=[];
            this.seriess[1].data=[];
            if(data.length > 0){
              // data=data.reverse();
              if(data.length>5){
                data=data.slice(-5);
              }
              let v=data.map((item)=>{
                return {       
                  y: Number(item.est_tariff_value),
                  consume: Number(item.energy_diff),
                }
              });

              let cat=data.map((ele)=>
              { 
                let x=new Date(ele.mdm_billing_cycle.split("/").reverse().join("/"));
                return this.lang==1? x.toLocaleString('ar', { month: 'long' }): x.toLocaleString('en', { month: 'long' }); 
              })
              let dataa=[];
            if(data.length==5){
            
              var currentDate = new Date(data[4].mdm_billing_cycle.split("/").reverse().join("/"));
              currentDate.setMonth(currentDate.getMonth()+1);
            
              cat =this.lang==1 ?
              cat.concat([currentDate.toLocaleString('ar', { month: 'long' })]):
              cat.concat([currentDate.toLocaleString('en', { month: 'long' })]);
              let s2=[0,0,0,0,0];

              // concat tooltip part for expected bar
              this.tool=v.concat([{ consume: Number(data[4].current_energy_diff),y: Number(data[4].current_bp_bill)}]);

              let est_value='';
              // check if next month of last reading(expected) equal current month or not
              if(currentDate.getMonth() == new Date().getMonth()){
                //  if equal the estimation value will be the diffrence between  current energy diff and  estmation cons
                dataa=data.map((ele)=>{return Number(ele.energy_diff)}).concat([Number(data[4].current_energy_diff)]);  
                // console.log('dataa',dataa[5]); 
                est_value=data[4].estimated_consumption-dataa[5];     
              }
              else{
                //  if not equal the estimation value will be the  estmation cons
                dataa=data.map((ele)=>{return Number(ele.energy_diff)});   
                est_value=data[4].estimated_consumption;
              }
              this.seriess[1].data= est_value > 0 ? s2.concat([est_value]) : s2.concat([0]);
              this.variant_tool={
                  y:Number(data[4].est_money).toPrecision(4),
                  consume:Number(data[4].estimated_consumption),
                }
            }

            else{
              this.tool=v;
              dataa=data.map((ele)=>{return Number(ele.energy_diff)});           
              this.seriess[1].data=new Array(data.length).fill(0);
            }
              this.seriess[0].data=dataa;
              this.options= {
                ...this.options,
                ...{
                xaxis: {categories: cat }
              }} ;
              // this.chartError = false
            }
            this.chartLoading=false;
          }
        }
        )
      }
    
       
    },
 
  },

};
</script>


<style>

p {
  font-size: 14px;
}
.alert >div{
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(79, 77, 97) !important;
  font-size: 14px;
  font-weight: 500;
}
#modalPopover___BV_modal_header_ {
  display: none;
}
.rechargeModal {
  padding-top: 10px;
}

.floating-right{
  direction: ltr;
  float:right;
}
.remaining b{
    margin-bottom: 8px;
    color: #002369;
    font-size: 16px;
    font-weight: 700 !important;
}
.credit__value {
  display: flex;
  column-gap: 8px;
  row-gap: 0px;
  flex-wrap: wrap;
}

.credit__value span{
  color:#002369;
  font-weight: 600 !important;
  font-size:48px;
  line-height: 59px;
}

.remaining-power{
  color:#002369;
  display: block;
}
.remaining-power span{
  font-size: 16px;
  font-weight: 600 !important;
}
.rtl .remaining-power{
  margin-top: 0px !important;
  margin-bottom: 8px;
}
@media (max-width:992px){
  .credit__value span.val{
  font-size:30px;
}
}

.last-update{
  color: #767676;
  display: block;
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 400 !important;
  font-size: 16px;

}

.handle-dir-last-update{
  margin-top:0px;
}

.tool{
  background:#262338;
  color:white;
  font-size:14px;
  border:0px;
  border-radius: 8px;
}

.tool .content{
  padding:10px;
}
.tool .content p{
 margin-bottom:5px !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
    border: 0px !important;
    background: none !important;
    width: 63%;
}
.apexcharts-tooltip.apexcharts-theme-light.apexcharts-active{
position:absolute;
top:0 !important;
left:0 !important;
}

@media (max-width:1450px) {
  .apexcharts-tooltip.apexcharts-theme-light {
    width:72%;
}
.h{
  font-size: 13px;
}
}
@media (max-width:1350px) {
  .apexcharts-tooltip.apexcharts-theme-light {
    width:80%;
  
}

.h{
  font-size: 14px;
}
}
@media (max-width:1200px) {

  .apexcharts-tooltip.apexcharts-theme-light {
    width:95%;
  
}
.h{
  font-size: 13px;
}
}
@media (max-width:1000px) {
  .apexcharts-tooltip.apexcharts-theme-light {
    width:100%;
  
}
  .h{
  font-size: 11px;
}

}
@media (max-width:800px) {
  .apexcharts-tooltip.apexcharts-theme-light {
    width:350px;
  
}
.h{
  font-size: 13px;
}

.remaining-power span{
  font-size:16px !important;
}

}
.last-update + .recharge.recharge-com{
  margin-top:-10px;
}
@media (max-width:657px) {
  .last-update + .recharge.recharge-com.ltr{
  margin-top:8px !important
}
}
@media (max-width:500px) {
  .apexcharts-tooltip.apexcharts-theme-light {
    width:100%;
  
}
.apexcharts-tooltip.apexcharts-theme-light  p{
  font-size: 12px !important;
}

} 
@media (max-width:480px) {
  .last-update + .recharge.recharge-com.rtl{
  margin-top:8px !important
}
}
@media (max-width:440px) {

.apexcharts-tooltip.apexcharts-theme-light  p{
  font-size: 11px !important;
}
.remaining-power,.rtl .remaining-power{
  margin-top:-25px !important;
}

} 

.apexcharts-yaxis-title{
  transform: rotate(0deg);
}

.meter-info .relative{
  position: relative;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    padding: 24px;
    min-height: 150px;
}

.chart-title{
    color: #002369;
    font-size: 16px;
    font-weight: 700;
    
}
.non-reflected > a{
 color: rgb(79, 77, 97) !important;
 text-decoration: none;
}

</style>

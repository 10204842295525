<template>
  <div class="recharge">
    <b-button :disabled="meter.min_charge_value==0 || meter.min_charge_value<0 || this.meter.min_charge_value==null" v-if="meter.payment_mode == '1'" @click="initialize()"  v-b-modal.modalPopover class="btn-primary" id="post-recharge-btn">{{$t("pay_bills") }}</b-button>
    <b-button v-if="meter.payment_mode == '2'"  @click="initialize()" v-b-modal.modalPopover class="btn-primary" id="pre-recharge-btn">{{$t("recharge") }}</b-button>

    <b-modal 
      id="modalPopover" 
      ref="modalPopover"
      @hide="reset"
      size="m" 
      centered 
      scrollable 
      :header-class="{'right-dir' : lang==2 , 'left-dir' : lang==1}" 
      >
          <template #modal-title>
            <div class="recharge-header" :class="{'rtl':  lang ==1,'ltr': lang ==2 ,}" >
              <p>{{$t("meter_no")}}</p>

              <p>
                <i v-if="meter.mdm_utilities == '1'" class="pylon-icon-Electric"></i>
                <i v-if="meter.mdm_utilities == '2'" class="fas fa-burn"></i>
                <i v-if="meter.mdm_utilities == '3'" class="pylon-icon-Water"></i>
                {{ code }}           
              </p>
            </div>

            <div v-if="meter.payment_mode == '1'" class="recharge-header" :class="{'rtl': lang ==1, 'ltr': lang ==2,}" >
              <p >{{ $t("required_money") }}</p>
              <p><span :class="{'change-dir': lang ==1, }">{{ meter.min_charge_value }}</span> <span > {{$t('egp')}}  </span> </p>
            </div>

            <div v-if="meter.payment_mode == '2'" class="recharge-header" :class="{ 'rtl': lang ==1,'ltr': lang ==2,  }">
                <p >{{ $t("remaining_credit") }}</p>
                <p> <span  :class="{ 'change-dir': lang ==1,  }" > {{ meter.current_credit }}</span><span > {{$t('egp')}} </span></p>
            </div>

          </template>

          <div :class="{'rtl': lang ==1,'ltr': lang ==2,}">
            
              <div v-if="error" class="recharge-alret alert alert-danger "  style="min-height: 80px;" role="alert">
                    <b >{{$t('error')}}</b>
                    <span style="display:block" >{{msg}}</span> 
              </div>

              <div class="due-parent">
                <div  class="due-block" >
                  
                    <div v-if="meter.customer_on_account!=null && meter.customer_on_account > 0" class="alret alert-info alret-note d-flex align-items-center" :class="{'cancel-margin' : error==true}"  style="gap: 12px;color: rgb(79, 77, 97) !important;min-height: 60px;    margin-bottom: 8px;">
                      <i class="pylon-icon-Warning"  style="color: #007EFF;"></i> 
                      <span>{{$t("you_have_installement")}} <span class="dues-val">({{meter.customer_on_account}} {{ $t("egp") }})</span> {{ $t("installment_note") }} </span>
                    </div>
                    
                    <div class="alret alert-warning " :class="{'cancel-margin' : error==true}" v-if="have_due_payments" style="gap: 12px; color:#4F4D61 !important ;border-radius: 8px;min-height: 60px;padding-bottom:8px;    margin-bottom: 10px;">
                    
                      <div class="alret-note d-flex align-items-center" style="gap: 12px; color: rgb(79, 77, 97) !important;">
                          <i class="pylon-icon-Warning"  style="color: #FFCC1F;"></i> 
                          <span v-if="meter.payment_mode=='2'">{{$t("you_have_dues")}} <span class="dues-val">({{due_payments}} {{ $t("egp") }})</span> {{ $t("due_note") }}
                            <span v-if="!show_clicked"  @click="showDueDetails(false)" class="details-span">{{$t("Show_Details")}}<i class="pylon-icon-Arrow-Down"></i></span>
                            <span v-if="show_clicked" @click="showDueDetails(true)"  class="details-span"> {{$t("Hide_Details")}}<i class="pylon-icon-Arrow-Up"></i></span>
                          </span>
                          <span v-else>{{ $t("post_due_note") }}</span>
                      </div> 

                      <ul class="dues-details" v-if="(show_clicked || meter.payment_mode=='1') && company.show_due_payments">
                        <li v-for="(item,index) in due_payment_services" :key="index" >   
                            <div>
                              <span style="font-weight:600 !important">{{item.name}} </span>
                              <span class="due-payment__amount" > {{item.amount}} {{$t("egp")}}</span>
                            </div>
                        </li>
                        <div class="separator"></div>
                        <div class="total-dues-val">
                          <span style="font-weight:600 !important">{{ $t("total") }}</span>
                          <span class="due-payment__amount"> {{due_payments}} {{$t("egp")}}</span>
                        </div>
                      </ul>

                    </div>

                    <div class="mb-2" v-if="meter.payment_mode=='2'">   
                      <p class="enter_amount">{{$t("enter_amount")}}</p>                    
                      <div  class='recharge-fields' style="display:flex;justify-content:space-between">
                        <div>
                            <p style="font-weight:500 !important">{{$t("amount")}}</p>                
                            <div class="relative">
                              <input  class=" chargeInput" id="min-recahrge-amount" v-model="Amount"  :placeholder="AmountPlaceHolder" />
                              <span  class="min-span">  {{$t('min_amount')}} ( {{ min_payment_value}} {{$t('egp')}} )</span>                            
                            </div>
                        </div>
                        <div class="exchange-icons">
                          <i class="pylon-icon-ArrowRight" style="margin-bottom: -4px;"></i>
                          <i class="pylon-icon-Arrow-Left" style=" margin-top: -3px;"></i>
                        </div>
                        <div>
                            <p style="font-weight:500 !important">{{$t("Net_Charge")}}</p> 
                            <div class="relative">
                              <input  class=" chargeInput" id="net-recahrge" v-model="netCharge" :placeholder="AmountPlaceHolder"/>
                            </div>
                        </div>
                      </div> 
                    </div>
                
                </div>
              </div>
            
          </div>
          
          <template #modal-footer >
            <div  class="last-due-element" :class="{'rtl': lang ==1, 'ltr': lang ==2}">
                <DetailedPayment
                  :company="company" 
                  :lang="lang" 
                  :due_payments="due_payments"
                  :financial_dues="meter.customer_on_account" 
                  :chargeAmount="Amount && meter.payment_mode=='1'?Amount:netCharge" 
                  :collection_fees="collection_fees" 
                  :total_amount="total_amount" 
                  :payment_mode="meter.payment_mode"/>

                <b-button
                  id="recharge-handling-btn"
                  class="btn-color"
                  :disabled="disable_pay"
                  v-b-modal.paymentConfirmation
                  >{{ $t("pay") }}
                </b-button>            
            </div>

          </template>
             
    </b-modal>
       
    <PaymentConfirmation 
        :company="company"
        :lang="lang" 
        :code="code" 
        :due_payments="due_payments" 
        :chargeAmount="Amount && meter.payment_mode=='1'?Amount:netCharge" 
        :financial_dues="meter.customer_on_account"
        :collection_fees="collection_fees" 
        :total_amount="total_amount"
        :payment_mode="meter.payment_mode"
        @confirm="handleRecharge()"
    />
    <PayTabs 
        :lang="lang"
        :payLink="payLink"
        ref="paytabs"
        class="mt-3" 
        @clear_amount="clear_amount()"   
    />

  </div>

</template>
<script>
import DetailedPayment from "@/components/Recharge/DetailedPayment.vue"
import PaymentConfirmation from "@/components/Recharge/PaymentConfirmation.vue"
import PayTabs from "@/components/PaymentMethods/PayTabs.vue"
import rechargeServices from '@/services/recharge'

export default {
  name: "Recharge",
  components: {
    DetailedPayment,
    PaymentConfirmation,
    PayTabs
  },
  props: ["code", "meter","company","lang"],
  data() {
    return {
      Amount: '',
      netCharge:'',
      AmountPlaceHolder: this.$t('recharge_value_placeholder'),
      error:false,
      msg:'',
      have_due_payments:false,
      disable_pay:true,
      due_payment_services:[ ],
      payLink:"",   
      due_payments_used_for_total_amount:0,
      due_payments:0,
      min_payment_value:0,
      show_clicked:false,
      difference:0,
      emptyAmountFrom:false,
      min_NET_val:0,
      comefromEmpytedAmountByNetCond:false,
      modal_closed:true,

    };
  },
  computed: {
    collection_fees() {

      let total_paid='';
      if(this.meter.payment_mode=='2'){
        //if prepaid 
        if(Number(this.Amount) ==0 ){
          //not entered amount  (start of popup) //total amount = due payments
          total_paid = Number(this.due_payments_used_for_total_amount) 
        }
        else{
          //when entred amount //total =charge amount (net charge + due ) 
          total_paid= Number(this.Amount) ;
        }
      }
      else{
        //if post paid
        if(Number(this.Amount) > 0){
          total_paid=Number(this.Amount) ;
        }
        else{
          total_paid=0;
        }
      }

        if( total_paid > 0){
          return parseFloat(( total_paid *this.company.fees_precentage ) + this.company.fixed_fees).toFixed(2);
        }
        else {
          return 0 ;
        }
    },

    total_amount() {
      let total_paid='';
      if(this.meter.payment_mode=='2' && Number(this.Amount) ==0){
            total_paid= Number(this.due_payments_used_for_total_amount) ;
      }
      else{
        total_paid = Number(this.Amount);
      }
       return parseFloat( total_paid + Number(this.collection_fees) ).toFixed(2);
    
    }
  },
  watch: {
    "meter.v_items_json"() {
      // this.meter.min_charge_activity_value=2;
      // this.meter.max_charge_activity_value=50000;
      // this.meter.payment_mode='2';
      // this.meter.min_charge_value=-100;
      // this.meter.customer_on_account=100;
      // this.due_payment_services=[
      //   // {name:'x',
      //   //   amount:100,
      //   //   pk:1,
      //   //   select:true}
      //   ]

      this.min_NET_val=this.meter.min_charge_activity_value + (this.meter.min_charge_value< 0?-this.meter.min_charge_value:0);
      this.due_payment_services = this.meter.v_items_json.map(ele=>{
        return{
          name:ele.Name,
          amount:ele.Value,
          pk:ele.PK,
          select:true,
        }
       
      });
     
      this.have_due_payments=this.due_payment_services.length!==0;
        if(this.have_due_payments){
          // this.due_payments=300;
          this.due_payments = Number(this.due_payment_services.reduce((prevEle,curEle)=> prevEle + parseFloat(curEle.amount),0 ).toFixed(2));
          this.due_payments_used_for_total_amount=this.due_payments;
          if( this.meter.customer_on_account > 0){   // if has installment       
            // on =500 due=200 //min-charge =200-500=-300
            // on =200 due=500 //min-charge =500-200= 300
            this.due_payments_used_for_total_amount = this.meter.min_charge_value < 0 // if installment covered the due payments and remain
                                                     ? 0 
                                                     : parseFloat(Number(this.meter.min_charge_value)); //mean that installments not covered dues
           }
           this.min_payment_value=this.meter.min_charge_activity_value+ this.due_payments_used_for_total_amount ;
           this.max_payment_value=this.meter.max_charge_activity_value+this.due_payments_used_for_total_amount ;
        }
        else{
          this.min_payment_value=this.meter.min_charge_activity_value;
          this.max_payment_value=this.meter.max_charge_activity_value;
          this.due_payments_used_for_total_amount=0;
          this.due_payments=0;
        
        }
      
    },
    Amount(newAmount,old) {
      if(!this.modal_closed){// to make watch affet just work when modal is opened not closed
        // don't accept -ve number or strings (empty the feild instead)
        newAmount =Number(newAmount) && Number(newAmount)> 0 ? Number(newAmount) : '';
        this.Amount=newAmount;

        if(this.meter.payment_mode=='2'){
          // work on amount validation only if 
          // 1 -  newAmount not empty
          // 2-   newAmount is empty but there is remaining instalments (represented in - min_charge_Amount) come from changing in netRecharge Feild
          if(newAmount!='' || (this.meter.min_charge_value<0 && newAmount=='' )){

              this.emptyAmountFrom=this.comefromEmpytedAmountByNetCond?false:true;

              // show error if newAmount < min_pay
              if(newAmount<this.min_payment_value ){
                this.error=true;
                this.msg=this.$t("min_payment_amount") +" "+this.min_payment_value;
                this.disable_pay=true;
              }
              else if(newAmount>this.max_payment_value ){
                this.error=true;
                this.msg=this.$t("max_payment_amount") +" "+this.max_payment_value;
                this.disable_pay=true;
              }
              else{
                this.error=false;
                this.disable_pay=false;
              }

            //  apply change on net charge only if 
            //  1- newAmount not empty and >= min_payment
            //  2- newAmount not empty and <= max_payment
            //  3- newAmount is empty or less than or euqal min_payment and 
            //    => 1* netcharge changed and affect on amount only when (netCharge that changed not empty and there is no instalment)
            //       e.g  min_charge=100 customer_on_Accouts 0 , => min_payment = 102 //min_activity=2
            //            A  N 
            //            -  1
            //            2  - 
            //    => 2* in case that newAmount is empty in Specific cases when there is installemts
                  // e.g  min_charge=-100 customer_on_Accouts 100 , => min_payment = 2 //min_activity=2
            //            A  N 
            //            -  1
            //            1  - 
            if((newAmount>=this.min_payment_value && newAmount<=this.max_payment_value && newAmount!='')||(newAmount<=this.min_payment_value && ((this.netCharge!='' &&this.meter.min_charge_value>=0)||(this.emptyAmountFrom &&this.meter.min_charge_value<0)))){
                this.netCharge= 
                this.have_due_payments? //have dues
                  this.meter.customer_on_account == 0 ?
                      newAmount-this.due_payments_used_for_total_amount // have dues no installments
                  :  newAmount-this.meter.min_charge_value // have dues , have installemnts
                : // no dues
                this.meter.customer_on_account==0? 
                    newAmount  // no dues no install
                    :newAmount + this.meter.customer_on_account; // no dues install
                this.netCharge=Number(this.netCharge).toFixed(2)        
              }

          }
          // if newAmount is empty and no instalments found empty the netCharge
          else{
            this.error=false;
            this.disable_pay=true;
            this.netCharge=''; 
          }
        }
      }
      
    },
    netCharge(newNet , old){
      if(!this.modal_closed){
          this.comefromEmpytedAmountByNetCond=false;
          // used to handle errors shown when newNet is empty and Amount is empty 
          let x= Number(newNet)? Number(newNet):0;
          
          // empty newNet if it is less than min_net_val (which equal min_Activity || (min_Acivity + min_charge_val(if it is -ve (remainig installemts found))))
          newNet = Number(newNet) && Number(newNet) >=this.min_NET_val && Number(newNet) >0? Number(newNet) :'';
          
          // assign newNet to netCharge only if Amount empty and changing of newNet come from changing in netCharge itself
          // cases 
          // 1- min_activity = 2 , due=0 => so min_pay=2 ,Amount = '' and added net is 1 so netCharge feild remainng 1 to show netCharge err
          // 2- min_activity = 2 , due=0 => so min_pay=2 ,addedAmount is 1 so newAmount = '' < min_net so netCharge became empty also
          // this.netCharge=this.Amount!='' && newNet!=''?newNet:Number(this.netCharge)>0?Number(this.netCharge):'';
          if(this.Amount!='' && newNet!=''){
            this.netCharge=newNet;
          }
          else{
              if(Number(this.netCharge)>0){
                this.netCharge= Number(this.netCharge)
              }
              else{
                this.netCharge='';
              }
          }
        
          if(this.meter.payment_mode=='2'){
          
            if(newNet!==''){
              //  // on =500 due=200 //min-charge =200-500=-300
              // on =200 due=500 //min-charge =500-200= 300
              // charge=net +due => net=charge-due
              this.Amount= 
                this.have_due_payments? //have dues
                  this.meter.customer_on_account==0?
                    newNet+this.due_payments_used_for_total_amount // have dues no indtallments
                    :newNet + this.meter.min_charge_value // have dues , have installemnts
                : // no dues
                this.meter.customer_on_account==0? 
                    newNet  // no dues no install
                    :newNet - this.meter.customer_on_account; // no dues install
            }
            else{
              // handle errors if changing come from netCharge itself and Amount is empty
                  if(this.Amount==''){
                      this.error=true;
                      this.disable_pay=true;
                      // use the val of x above 
                      if(x!=0 && x<this.min_NET_val){
                        this.msg=this.$t("min_net_amount") +" "+this.min_NET_val;
                      }
                      // if zero meaning that netCharge is empty remove error if there is 
                      else if(x==0){
                        this.error=false;
                      }
                  }
                  // if amount not empty calc new Amount according to multiple cases

                  // 1- if amount is < min_charge_val and change netcharge is empty (eg. D=50 min_charge=50)
                  //   A      N 
                  //   52 =>  2
                  //   5  =>  -  => net changed and Amount < min_charge
                  //   5  <=  - 
                  else if((this.Amount<this.meter.min_charge_value && this.netCharge=='')){
                    this.Amount=this.Amount;
                  }
                  // 2- if amount is >= min_charge_val and min_charge>=0 and netCharge is empty
                  //   A      N 
                  //   52 =>  2  => net changed to - and Amount > min_charge
                  //   50 =>  -  => 
                  else if(this.Amount>=this.meter.min_charge_value && this.meter.min_charge_value >=0){
                    this.Amount=this.meter.min_charge_value+Number(this.netCharge);
                  }
                  
                  // 2- if amount is >= min_charge_val and min_charge<0 and netCharge is not empty (eg. Acti=2 D=0 , min_charge= -500 )
                  //   A      N 
                  //   2 =>  502  => net changed to - and Amount > min_charge , amount became -500+50 = -450 and converted to - in AmountWatch 
                  //   - => 50  => 
                  //  comefromEmpytedAmountByNetCond meaning that if Amount is empty and comefromEmpytedAmountByNetCond =true dont' apply the netCharge function in AmountWatch
                  else if((this.Amount>=this.meter.min_charge_value&&this.meter.min_charge_value <0&& this.netCharge!='')){
                    let val=this.meter.min_charge_value+Number(this.netCharge);
                    this.comefromEmpytedAmountByNetCond=val<0?true:false;
                    this.Amount=this.meter.min_charge_value+Number(this.netCharge);            
                  }

                  // 2- if amount is not empty and we empty netCharge and  Acti=0  and  min_charge=-ve found  (bestCase senario)
                  //   A      N 
                  //   2 =>  2  => net changed to - so Amount -
                  //   - => -  => 
                  //  comefromEmpytedAmountByNetCond meaning that if Amount is empty and comefromEmpytedAmountByNetCond =true dont' apply the netCharge function in AountWatch
                  
                  else{
                    this.Amount='';
                    this.comefromEmpytedAmountByNetCond=true;   
                  }
            }
            
          }
         }  
     
  } 
  },

  methods: {
    initialize() {
      this.modal_closed= this.meter.payment_mode=='2'?false:true;
      this.disable_pay=true;
      this.Amount = this.meter.payment_mode=='2'?'':this.meter.min_charge_value;
        if(this.meter.payment_mode=='1' && this.meter.min_charge_value!=0 && this.meter.min_charge_value!=null){
          this.disable_pay=false;
        }
    },
    showDueDetails(show){
     if(show){
      this.show_clicked=false;
     }
     else{
      this.show_clicked=true;
     }

    },
    reset(){
     this.modal_closed=true,
     this.Amount='';
     this.netCharge='';
     this.show_clicked=false;
     this.error=false;
    },

    clear_amount() {
      this.Amount = '';
      this.netCharge='';
      this.$bvModal.hide('modalPopover');  
    },
    handleRecharge() {
      this.error=false;   
        rechargeServices.
        rechargeMeter(this.Amount.toFixed(2),this.meter.req_trx_master,this.due_payment_services.map((ele)=>{return parseInt(ele.pk)}),this.company.paytabs?"2":"1",this.company.response_type,this.lang)
        .then(data=>{ 
          if(this.company.paytabs){
            if (data.redirect_url !== '') {
               this.payLink=data.redirect_url;
            }
          } 
          else if(this.company.nbe){
            if (data.session_id !== '') {
           checkoutPrepare(data.session_id, data.merchant,data.merch_name, data.total_amount, data.request_number, this.meter.account_reference,data.session_version)
           Checkout.showLightbox()
        }
          }  

        }).catch(err=>{
           this.error=true;
          this.msg=err.message;
      })
    },
  },

};
</script>

<style scobed>


.modal-dialog {
  min-height: 680px;
}

.modal-dialog ::-webkit-scrollbar {
  width: 4px;
}

.modal-dialog ::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

@media (min-width: 701px){
  .modal-dialog {
      max-width: 558px;
  }
}

#modalPopover___BV_modal_content_ {
    width: 558px;
    overflow-x: hidden;
    position: relative;
}

@media (max-width:700px){
  #modalPopover___BV_modal_content_ {
    width: 80vw; 
  } 
  .modal-dialog {
    max-width: 80vw;
    margin: 1rem auto;
  }
}


#modalPopover___BV_modal_header_{
  display: block !important;
  margin-bottom: 0px !important;
  background: #F0F0F0;
  height: 94px;
  padding: 18px 24px;
}
#modalPopover___BV_modal_body_{
  padding: 24px;
  height: 488px;
  /* min-height: 291px; */
} 
.modal-body {
  padding: 24px;
  max-height: unset;
  /* min-height: 291px; */
}
 #modalPopover___BV_modal_outer_ .close{
  display: none;
}

#modalPopover___BV_modal_footer_ {
  display: flex;
  justify-content: center;
  padding: 24px;
}
.modal-footer{
  display: none;
}
#modalPopover___BV_modal_footer_>* {
  margin: 0;
}


.change-dir{
float: right;
    direction: ltr;
    margin: 0px 5px;
}

.recharge-header{
  display: flex;
  justify-content: space-between;
}
.recharge-header p{
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #262338;
    margin-bottom: 12px;
}
.recharge-header span{
  font-weight: 500 !important;
} 
.recharge-block{
 
 height: 291px;
}

.last-due-element{
  width: 100%;
}

.last-due-element button{
  text-align: center;
  width: 100% !important;
  height: 40px;
  margin-bottom: 0px !important;
}


.due-block .alret-note{
  font-size: 14px;
  padding: 12px;
  border-radius:8px;
  /* margin-bottom: 16px; */
  /* color:#842029 !important; */
}
.due-block .alret-note span{
  
  font-weight: 500 !important;
}
.due-block .alret-note span.dues-val{
  font-weight: 700 !important; 
}
.due-block .alret-note .details-span{
font-weight: 600 !important;
color: var(--main-color);
cursor: pointer;
}
.dues-details{
  margin-bottom: 0;
}
.ltr .dues-details{
margin-left:36px;

}
.rtl .dues-details{
margin-right:36px;

}
.recharge-alret.alert.alert-danger {
  width: 100% !important;
  margin-bottom: 10px;
}
.recharge-alret p{
  font-weight: 400 !important;
  color:#842029
}
.recharge-block,.due-block{
  position: relative;
}
.recharge-block > p ,.due-block p{
 color: #4F4D61; 
 font-size: 16px;      
}
.due-block p.option-p{
 font-weight: 500 !important;
 font-size: 14px;      
}

.currency-left {
  position: absolute;
  left: 16px;
  top: 8px;
  color: #4F4D61;
}
.currency-right {
  position: absolute;
  right: 16px;
  top: 8px;
  color: #4F4D61;
}


.recharge-block p.charge-amount , .due-block p{
    font-weight: 600 !important;
    margin-bottom: 8px;
}
.min-span{
  margin-top: 8px;
  display: block;
  color:#AAAAB2;
  font-size: 14px;
  font-weight: 500 !important;
}

.chargeInput {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #AAAAB2;
}
.due-block .amount-apearance{
  display: flex;
  justify-content: space-between;
}
.due-block .cancel{
  color:var(--main-color);
  font-weight: 600 !important;
  font-size: 14px;
  cursor: pointer;
}
.due-block .enter{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  background: white;
  border-radius: 4px;
  padding: 8px 16px;
  min-width: 147px !important;
  height: 33px;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 1.2;
}
.due-block button.enter[disabled]{
  opacity: 0.5;
}
/* .due-block .enter i{
  font-size: 10px;
} */
.due-block .due-payments{
  margin-bottom: 24px;
}
.dues-details li div ,.total-dues-val{
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  align-items: center; 
 font-weight: 600;
 font-size: 14px;
 margin-bottom: 8px;
}
.ltr .dues-details li div,.ltr .total-dues-val{
padding-right: 12px;

}
.rtl .dues-details li div,.rtl .total-dues-val{
padding-left: 12px;

}
.separator{
    display: block !important;
    height: 1px;
    width: 100%;
    background: #4F4D61;
    margin-bottom: 8px;
 
}
.ltr .separator ,.ltr .total-dues-val{
  margin-left: -13px;
}
.rtl .separator ,.rtl .total-dues-val{
  margin-right: -13px;
}
.due-block .due-payments div.selected{
    background: rgba(13, 69, 111, 0.1);
    border: 2px solid #0D456F;
}
.exchange-icons{
  display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    font-size: 19px;
    color: #4F4D61;
}

.due-payments .check {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top:2px;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rtl .due-payments .check{
  padding-right: 35px;
  padding-left: 0px;
}
.due-payments .checkmark {
  position: absolute;
    top: 5px;
    right: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border: 2px solid #4F4D61;
    border-radius: 2px;

}
.rtl .due-payments .checkmark{
  left: 0;
}
.due-payments .check input:checked ~ .checkmark {
  background-color: #0D456F ;
    border-color: #0D456F;
}
.due-payments .check input:checked 
.due-payments .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.due-payments input:checked ~ .checkmark:after {
  display: block;
}

.due-payments .checkmark:after {
  left: 5px;
  top: 0.5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
 
}

.due-payment__amount {
  text-align: end;
  font-weight:500 !important
}

@media(max-width:700px){
 
  .due-payments .check ,.due-payments div span {
    font-size: 13px;}
  .due-block .due-payments p{
    line-height: 2;
  }
  .total-pay span:first-child{
    font-size: 14px ;
  }
  .total-pay span:nth-child(2){
    font-size: 16px;
  }
}
@media (max-width:576px) {
  .recharge > button {
    font-size: 13px; 
}
.recharge-fields{
  flex-direction: column;

}
.exchange-icons{
  width: 50px;
  height: 50px;
  flex-direction: row;
}
.exchange-icons .pylon-icon-ArrowRight{
  margin-bottom: 0px; 
  transform: rotate(90deg);
}
.exchange-icons .pylon-icon-Arrow-Left{
  margin-top: 0px;
  transform: rotate(90deg);
  margin-left: -20px;
}
}
@media (max-width:500px){
  .due-payments .check ,.due-payments div span {
  font-size: 11px;}
  .due-block .due-payments div{
    line-height: 2;
  }
}
@media(max-width:450px){
  .min-span{
  font-size: 11px;
}
  .due-block .due-payments div {
    padding: 8px 6px;
  }
}
@media(max-width:400px){
  .due-block .due-payments div {
    line-height: 1.2;
  }
}
.due-parent .due-block{
  height: 100%;
}
.cancel-margin{
  margin-top: 0px !important;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.pylon-icon-Warning:before{
  font-size: 28px;
}
</style>

<template>

    <div class="container" :class="{'rtl' : lang=1 , 'ltr': lang==2}" >
             <div v-if="state == '1'" class="alert alert-success "  role="alert" >
                    <b>{{$t('success')}}</b>
                    <p v-if="message">{{ message }}</p> 
            </div>
              <div v-if="state == '2'" class="alert alert-danger "   role="alert">
                    <b>{{$t('error')}}</b>
                    <p  v-if="message">{{ message }}</p> 
            </div>
            <div  v-if="state == '3'" class="alert alert-warning  "   role="alert">
                    <b>{{$t('warning')}}</b>
                    <p  v-if="message" >{{ message }}</p> 
            </div>
          
    </div>


</template>

<script>
import { getCookie } from "@/utils";
export default {
    name: 'Feedback',
    props: {
      query_found: {
            type: Boolean,
            default: false
        },
        state:{
          default: () => [],
        },

        message:{
          default: () => [],
        }
    },
    data() {
      return {
        get lang() {
        return getCookie('lang') || 2
      },
      }
    },
    created(){

    },
    mounted(){
      setTimeout( () => 
      { 
        if(this.query_found){
        this.$router.replace({query: {} });
        }
        this.$emit('unmountFeedComponent')
      
      }, 8000);
  },
}
</script>

<style scoped>
.container {

}
.container , .container > div >p{
  padding:0;

}

.alert {
  white-space: pre-line;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  25%{
opacity: 1;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

</style>
import Vue from "vue";
import VueI18n from "vue-i18n";
import secondLang from "./secondLang";
import firstLang from "./firstLang";
import { getCookie } from "../utils";

// require('rtl.scss')
// let locale = VueCookie.get('locale') || 'en'
// if (locale === 'ar') {
//   document.querySelector('body').classList.toggle('rtl');
// }

Vue.use(VueI18n);

// if (newLocale === 'ar' || oldLocale === 'ar') {
//   document.querySelector('body').classList.toggle('rtl');
// }

export default new VueI18n({
  
  locale: getCookie('lang')? getCookie('lang')==1 ?'firstLang' : 'secondLang' : 'secondLang', // set default locale // 
  messages: {
    secondLang,
    firstLang,
  },
},
// console.log('ddd', getCookie('lang'))
);

<template>
  <div>

      <table >
        <thead>
          <tr>
            <th  scope="col">{{$t('screen_no')}}</th>
            <th  scope="col">{{$t('description')}}</th>
            <th  scope="col" class="img">{{$t('screen')}}</th>
          </tr>
        </thead>
      <tbody>
        <tr v-for="(screen,index) in screens" :key="index">
            <td >{{screen.number}}</td>
            <td >{{$t(`inhemeter_desc_${screen.number.split('.').join('_')}`)}}</td>
            <td >
              <img class="w-50" :src="`../../assets/meter_screens_images/electricity/${screen.src}`" />
            </td>
          </tr>
      </tbody>
      </table>
    </div>
  

  <!-- src\assets\meter_screen_images\Remaining_Money.jpg -->
</template>
<script>
import { getCookie } from '../../utils';
export default {
  data() {
    return {
      get lang() {
        return getCookie('lang')
      },
      screens:[],
    };
  },
  methods: {},
  created(){
    this.screens=[
        {number:'0.9.1',src:""},
        {number:'0.9.2',src:""},
        // {number:'1.6.0',src:""},
        {number:'1.6.0.1',src:""},
        // {number:'1.6.0.2',src:""},
        // {number:'1.6.0.3',src:""},
        {number:'1.8.1',src:""},
        {number:'3.8.1',src:""},
        {number:'2.8.1',src:""},
        {number:'4.8.1',src:""},

        {number:'1.8.2',src:""},
        {number:'3.8.2',src:""},
        {number:'2.8.2',src:""},
        {number:'4.8.2',src:""},
        // {number:'15.6.1',src:""},
        // {number:'15.6.2',src:""},
        {number:'15.8.0',src:""},
        {number:'15.8.1',src:""},
        {number:'15.8.2',src:""},
        {number:'1.8.0',src:""},

        {number:'2.8.0',src:""},
        {number:'3.8.0',src:""},
        {number:'4.8.0',src:""},
        // {number:'42.0.0',src:""},
        // {number:'C.53.12',src:""},
        {number:'81.7.4',src:""},
        {number:'81.7.15',src:""},
        {number:'81.7.26',src:""},
        {number:'13.7.0',src:""},
        {number:'31.7.0',src:""},

        {number:'51.7.0',src:""},
        {number:'71.7.0',src:""},
        {number:'32.7.0',src:""},
        {number:'52.7.0',src:""},
        {number:'72.7.0',src:""},
        {number:'33.7.0',src:""},
        {number:'53.7.0',src:""},
        {number:'73.7.0',src:""},
        {number:'0.4.2',src:""},
        {number:'0.4.5',src:""},




        
    ]
  }
};
</script>
<style scoped>
table tr td {
  font-size:1rem;
  vertical-align: middle;
}
img,td:has(img),.img{
  display: none;
}
@media (max-width:760px){
 table tr td {
  font-size:13px;
  padding: 5px !important;
} 
img{
  width:100% !important;
}
}
table * {
  text-align: center;
}

table tbody tr td:first-child {
  direction: rtl;
}

th,td{
  background: #F0F0F0;
  color:  #4F4D61;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

}
td{
font-weight: 500;
background: #fff;
}
td p{
  text-align: center;
  margin-bottom: 0px;
}
.text-secondary {
  color: #AAAAB2;
  font-size: 12px;
  font-weight: 500;
}
table, td, th{
  border: 1px solid #E0E0E0 !important;
}
</style>
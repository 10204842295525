<template >
    <div  style="min-height: 100vh;margin:0px 40px" class="mb-3">
    <div>
        <p class="faq-header" :class="{'rtl': lang==1, 'ltr': lang==2}">{{$t("faq_header")}}</p>
        <div class="search-container d-flex m-auto" :class="{'rtl': lang==1, 'ltr': lang==2}">
          <b-form-input
          v-model="searchInput"
          id="search-input"  
          :placeholder="$t('search_placeholder')"      
          ></b-form-input>
          <i :class="{'pylon-icon-Search-Arabic': lang==1, 'pylon-icon-Search-English': lang==2 }"></i>
          <i v-show="show_closing" class="pylon-icon-Close closing" @click="resetSearch(false)"></i>
          <button class="search-btn" :disabled="emptySearchValue" @click="search()">
            {{ $t('search') }}
          </button>
        </div>
       
        <div class="content">
            <div v-for="(item, index) in data" :key="index" class="questions-block" :class="{'rtl': lang==1, 'ltr': lang==2}">
                    <p @click="showAns(index)" class="question"> 
                            <span :ref="`question${index}`">{{item.question}}</span>
                            <i v-if="item.show" class="pylon-icon-Arrow-Up"></i>
                            <i v-if="item.show==false" class="pylon-icon-Arrow-Down"></i>
                    </p>
              

                     <div class="parent" :ref="`ans${index}`">
                      <div  v-for="(ans, i) in item.ans" :key="i" v-show="item.show" class="answer"  > 
                       <span class="paragraph"> {{ans.paragraph}} </span>
                       <ul class="steps">
                        <li v-for="(step,index) in ans.steps" :key="index">{{step}}</li>
                       </ul>
                       
                    </div>
                     </div>
                    
            </div>
        </div>
        
    </div>
    </div>
</template>
  
<script>
  export default {
    props: ["lang", "company"],
    
    data() {
      return {
        show_d:false,
        data:[
        {   question:"ما هو العداد الذكي؟"
            ,
            ans:
            [
              {paragraph:`العداد الذكي هو عداد مسبق الدفع يتصل بمركز التحكم في العدادات
              الذكية، وذلك للشحن عن بعد ومتابعة الإستهلاك ومتابعة الأعطال `,
              steps:[]
              }
            ]
               ,  
            show:false,
        },
        
        {   question:"ما هي إجراءات التقدم لتركيب عداد جديد للإستخدامات السكنية؟"
            ,
            ans:
            [
              {paragraph:`يتم تقديم طلب تركيب عداد سكني جديد مع استيفاء المستندات التالية: `,
              steps:[
                      `أصل موافقة المطور على توصيل المرافق `,
                      ` محضر استلام الوحدة`,
                      `أصل العقد الخاص بالوحدة (للإطلاع) وصورة منه`,
                      `أصل بطاقة الرقم القومي (للإطلاع) وصورة منها `,
                      `صورة من التوكيل (في حالة وجود توكيل) `,
                      `صورة من بطاقة الرقم القومي للوكيل (في حالة وجود توكيل) `,
                     
                    ]
              },
              {
                paragraph:`يتم تقديم الطلب والمستندات الي مركز خدمة العملاء، وسداد قيمة المقايسة بعد استيفاء المواصفات الفنية لتركيب العداد، ثم توقيع العقد وتحديد موعد تركيب العداد. `,
                steps:[]
              }
            ],
           show:false,
        },
        {   question:"ما هي إجراءات التقدم لتركيب عداد جديد للإستخدامات التجارية؟"
            ,
            ans:
            [
              {paragraph:`يتم تقديم طلب تركيب عداد تجاري جديد مع استيفاء المستندات التالية: `,
              steps:[
              `أصل موافقة المطور وخطاب استلام الوحدة `,
              `السجل التجاري والبطاقة الضريبية`,  
              `أصل العقد الخاص بالوحدة (للإطلاع) وصورة منه`,
              `أصل بطاقة الرقم القومي (للإطلاع) وصورة منها `,
              `صورة من التوكيل (في حالة وجود توكيل)`,
              `صورة من بطاقة الرقم القومي للوكيل (في حالة وجود توكيل)`,
              ]
              },
              {
                paragraph:`يتم تقديم الطلب والمستندات الي مركز خدمة العملاء، وسداد قيمة المقايسة بعد استيفاء المواصفات الفنية لتركيب العداد، ثم توقيع العقد وتحديد موعد تركيب العداد. `,
                steps:[]
              }
            ],  show:false,
        },
          {   question:`ما هي إجراءات تقديم الطلب الكترونيا؟`,
              ans:[
                {paragraph:`الدخول على المنصة الالكترونية واختيار خدمة طلب تركيب عداد وتوصيل الكهرباء ثم تحميل نموذج الطلب واستيفاء المستندات المطلوبة وارسالها لمركز خدمة العملاء بالإيميل للمراجعة ويتم سداد قيمة المقايسة بعد استيفاء المواصفات الفنية لتركيب العداد، ثم توقيع العقد وتحديد موعد تركيب العداد`,
                steps:[]},
              ],
              show:false,
        },

        {   question:`ما هي طريقة متابعة الطلب المقدم؟ `,
              ans:[{paragraph:`يمكن متابعة الطلب المقدم عن طريق التواصل مع خدمة العملاء تليفونيا اوعن طريق الايميل كما يمكن المتابعة عن طريق زيارة مركز خدمة العملاء`, steps:[]}],
              show:false,
        },

        {   question:`ما هو سعر العداد – المقايسة؟ `,
              ans:[{paragraph:`السعر يختلف باختلاف نوع العداد وطبيعة الإستخدام`, steps:[]}],
              show:false,
        },

        {   question:`ما هي المدة الزمنية التقريبية لتنفيذ تركيب العداد؟`,
              ans:[{paragraph:`يتم تركيب العداد بعد استيفاء الطلب والمستندات المطلوبة في خلال 7 أيام عمل من تاريخ سداد قيمة المقايسة، وذلك في حالة جاهزية المكان لتركيب العداد واستيفاء جميع الاشتراطات الفنية وعدم وجود معوقات.`, steps:[]}],
              show:false,
        },

        {   question:`ما هي طريقة الدفع لشحن الرصيد؟`,
              ans:[{paragraph:``, steps:[
                `الشحن عن طريق زيارة مركز خدمة العملاء`,
                `الشحن اونلاين عن طريق المنصة الالكترونية.`,
                `الشحن اونلاين عن طريق تطبيق الهاتف المحمول.`
              ]}],
                  show:false,
        },
        {   question:`كيف يمكن الشحن أونلاين؟ `,
              ans:[{paragraph:`يمكن الشحن أونلاين عن طريق المنصة الالكترونية أو تطبيق الهاتف المحمول وذلك باختيار (شحن العداد) من الشاشة الرئيسية ومن ثم إدخال كود العميل ورقم العداد وقيمة المبلغ المراد الشحن به.`, steps:[]},
                    {paragraph:`(برجاء التأكد من البيانات ومراجعتها قبل تنفيذ العملية)`, steps:[]}],
              show:false,
        },
        {   question:` كيف أعرف بيانات المستخدم الخاصة بي للدخول لحسابي الخاص؟ `,
              ans:[{paragraph:`يمكن الدخول الي الحساب عن طريق كود العميل (وهو الرقم الأول المسجل على كارت الشحن) وكلمة السر والتي يتم الحصول عليها من مركز خدمة العملاء عند التعاقد. `, steps:[]}],
              show:false,
        },
        {   question:` لدي بعض التساؤلات عن صفحات العداد، كيف يمكنني معرفتها؟  `,
              ans:[{paragraph:`يمكن معرفة معلومات عن صفحات العداد عن طريق تسجيل الدخول الي حساب المستخدم على المنصة الالكترونية واختيار (شرح صفحات العداد) من الشاشة الرئيسية`, steps:[]}],
              show:false,
        },

        {   question:` ماذا تفعل في حالة توقف العداد عن العمل؟  `,
              ans:[{paragraph:`يمكن مراجعة الصفحات على شاشة العداد لمعرفة سبب الفصل:`, 
                steps:[
                  `عدم وجود رصيد بالعداد`,
                  `زيادة الأحمال الكهربية`,
                  `التلاعب بالعداد`,
                ]},
                {paragraph:`كما يمكن التواصل مع مركز خدمة العملاء أو عن طريق المنصة الالكترونية لطلب فحص العداد`, 
                steps:[]}],
              show:false,
        },

        {   question:` ماذا تفعل في حالة فصل العداد بسبب زيادة الأحمال؟ `,
              ans:[{paragraph:`في حالة ظهور علامة زيادة الأحمال الكهربية على شاشة العداد يمكن التقدم بطلب لخدمة العملاء لزيادة القدرة التعاقدية العداد موضحاً به القدرة المطلوبة وسيتم اجراء معاينة فنية وتحديد التكلفة اللازمة لتفعيل الزيادة المطلوبة`
                , steps:[]}],
              show:false,
        },

        {   question:`هل يمكن تصحيح / تعديل البيانات بعد إتمام التعاقد ؟`,
              ans:[
                {paragraph:`يمكن تصحيح / تعديل البيانات من خلال زيارة مركز خدمة العملاء `, steps:[]}],
              show:false,
        },
/*
                {paragraph:`يمكن تصحيح / تعديل البيانات من خلال زيارة مركز خدمة العملاء أو عن طريق تسجيل الدخول على المنصة الالكترونية واختيار صفحة (تصحيح البيانات) من الشاشة الرئيسية `, steps:[]}],
*/
        {   question:` كيف يمكن استخراج بيان لمعرفة الإستهلاك خلال فترة معينة؟ `,
              ans:[
              {paragraph:`يمكن استخراج بيان لمعرفة الاستهلاك خلال فترة معينة من خلال زيارة مركز خدمة العملاء أو عن طريق تسجيل الدخول على المنصة الالكترونية واختيار صفحة (الطلبات) من الشاشة الرئيسية`, steps:[]}],
              show:false,
        },


        {   question:`ماذا تفعل في حالة فقد/ تلف كارت شحن الكهرباء؟`,
              ans:[
                {paragraph:`يمكن استخراج كارت بديل من خلال زيارة مركز خدمة العملاء أو عن طريق تسجيل الدخول على المنصة الالكترونية واختيار صفحة (الطلبات) من الشاشة الرئيسية`, steps:[]},
                {paragraph:`علما بأنه سيتم إيقاف الكارت المفقود اوتوماتيكيا بعد تفعيل الكارت الجديد على العداد`, steps:[]}], 
                show:false,
        },

        {   question:`كيف يمكن التقدم بشكوى؟`,
              ans:[{paragraph:`يمكن تقديم الشكوى عن طريق التواصل تليفونيا أو بالإيميل أو بالحضور الى مركز خدمة العملاء، كما يمكن تقديم الشكوى عن طريق تسجيل الدخول على المنصة الالكترونية واختيار صفحة (الشكاوى) من الشاشة الرئيسية`, steps:[]}],
              show:false,
        },

        {   question:`لديك تساؤل ولا تجد االإجابة عليه؟`,
              ans:[{paragraph:`يمكن التواصل تليفونيا أو بالإيميل أو بالحضور الى مركز خدمة العملاء، كما يمكن التواصل عن طريق تسجيل الدخول على المنصة الالكترونية واختيار صفحة (الاستفسارات) من الشاشة الرئيسية`,
                 steps:[]},],
              show:false,
        },
        ],
        searchInput:"",
        show_closing:false,
        emptySearchValue:true,

      }
    },
   watch:{
      searchInput(newInput){
        if(newInput==''){
          this.show_closing=false;
          this.emptySearchValue=true
          this.resetSearch(false);
        }
        else{
          this.show_closing=true;
          this.emptySearchValue=false;
        }
       
      }
   },
    methods: {
    
    showAns(index){    
     if(this.data[index].show==false){ this.data[index].show=true}
     else {this.data[index].show=false}
    },
    search(){
      this.resetSearch(true);
      let searchArray=this.searchInput.split(" ");
      
      this.data.map((ele,index)=>{
      for(let i=0;i<searchArray.length;i++){
        let searchReg=new RegExp(`${searchArray[i]}`,'gi');
       
        if(searchReg.test(ele.question)){
          ele.show=true;
          this.$refs[`question${index}`][0].innerHTML = this.$refs[`question${index}`][0].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 18px;font-weight: 600 !important;">${searchArray[i]}</span>`)
        }
  
        for(let k=0;k<ele.ans.length;k++){
          if(searchReg.test(ele.ans[k].paragraph)){
            ele.show=true;
            this.$refs[`ans${index}`][0].childNodes[k].childNodes[0].innerHTML = this.$refs[`ans${index}`][0].childNodes[k].childNodes[0].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 16px;font-weight: 500 !important;">${searchArray[i]}</span>`)
           }
           for(let m=0;m<ele.ans[k].steps.length;m++){
              if(searchReg.test(ele.ans[k].steps[m])){
              ele.show=true;
              this.$refs[`ans${index}`][0].childNodes[k].childNodes[1].childNodes[m].innerHTML =this.$refs[`ans${index}`][0].childNodes[k].childNodes[1].childNodes[m].innerHTML.replace(searchReg,`<span style="  background: yellow;font-size: 16px;font-weight: 500 !important;">${searchArray[i]}</span>`)
            }
           }
      
        }
        
      } 
     }); 
    },
    resetSearch(searchBtnCliked){
      this.searchInput=searchBtnCliked?this.searchInput:'';
      let questionElemes=Array.from(document.querySelectorAll('.question > span'));
      let answerElemes=Array.from(document.querySelectorAll('.parent'));
      questionElemes.map((ele,index)=>{
        ele.innerHTML=ele.innerHTML.replace(ele.innerHTML,this.data[index].question);    
        for(let i=0;i< answerElemes[index].childNodes.length;i++){
          answerElemes[index].childNodes[i].childNodes[0].innerHTML= answerElemes[index].childNodes[i].childNodes[0].innerHTML
          .replace(answerElemes[index].childNodes[i].childNodes[0].innerHTML,this.data[index].ans[i].paragraph)

          for(let j=0;j<answerElemes[index].childNodes[i].childNodes[1].childNodes.length;j++){
            answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML= answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML
          .replace(answerElemes[index].childNodes[i].childNodes[1].childNodes[j].innerHTML,this.data[index].ans[i].steps[j])

          }
        }
       this.data[index].show=false;
    })     

}
    
    }, 
  };
  </script>
  
  <style scoped>
  .faq-header{
    font-weight: 700 !important;
    font-size: 32px;
    line-height: 39px;
    color: #002369;
    margin-top: 48px;
    max-width: 856px;
    margin: 0px auto;
    margin-top: 32px;
    padding: 0px;
    margin-bottom: 44px;
  }
 
  .questions-block{
    /* width: 55%; */
    /* margin: auto; */
    padding:25px 0px;
    border-bottom: 1px solid #AAAAB2;

  }
  .questions-block .question {
    font-weight: 600 !important;
    font-size: 18px;
    color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    position: relative;
    cursor: pointer;
  }
 .questions-block .question span{
  font-weight: 600 !important;
    font-size: 18px;
    color: #111C4E;
    line-height: 24px;
 }
  .questions-block .question i{
    font-weight: 600 !important;
    font-size: 18px;
    position: absolute;
    top: 15%;
    left: 100%;
  }
  .questions-block.rtl .question i{
    right:100%;
    left: 0;
  }
  .questions-block .answer{
    font-weight: 500 !important;
    font-size: 16px;
    color: #4F4D61;
    line-height: 24px;
    margin-bottom:0px;
    margin-top: 16px;
  }
  
  @media(max-width:600px){
    .faq-header{
    font-size: 24px;
  }
  }
  .search-container{
    max-width: 856px;
    position: relative;
    padding: 0px;
    margin-bottom: 16px !important;
  }
  
  .search-container.rtl{
    direction:rtl;
  }
  .search-container #search-input{
    width: 288px;
    max-width: 100%;
    height: 40px;
    color: #262338;
    background: #FFFFFF;
    border: 1px solid #AAAAB3;
    border-radius: 4px;
    padding: 12px 44px;

  }
  .search-container i{
    position: absolute;
    top: 25%;
    left: 12px;
    color: #AAAAB2;
    font-size: 20px;
    font-weight: 600;
    
  }
  .search-container i.closing{
    left: 258px;
  }
  .search-container.rtl i{
    left:unset;
    right:12px;
  }
  .search-container.rtl i.closing{
    right:258px;
  }
  .search-container .search-btn{
    width: 82px;
    height: 40px;
    background: #1A3978;
    border-color: #1A3978;
    border-radius: 4px;
    color: white;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 10px;
    margin: 0px 16px;
    padding: 12px 16px;
    border-color: transparent;
  }
  .search-container.rtl .search-btn{
    font-size: 18px;
    padding-right:23px ;
    padding-top:10px;
  }
  .search-container button.search-btn[disabled]{
     background-color: #AAAAB3;
  }
  .content{
    max-width: 856px;
    margin:0px auto;
    padding:0px
    }
    @media (max-width:400px) {
      .search-container{
        flex-direction: column;
        row-gap: 8px;
      }
      .search-container .search-btn{
        margin:0px;
      }
      .search-container i{
        top:12%;
      }
    }
  </style>
import ApiRequests from "./ApiRequests";

export default {
 async addComplaint(complaint) {

    return ApiRequests.apiRequest('post',`portal/assignComplaint`,null,complaint)
    .then(data=>{
      return data;
     })
 
  }
  }
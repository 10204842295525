import axios from '../axios';
import ApiRequests from './ApiRequests';
export default {
  // ===============================
  // General
  // ===============================
  downloadPDF(file, type , name) {
    const linkSource = `data:application/${type};base64,${file}`;
    const downloadLink = document.createElement("a");
    const fileName = name + '.' + type;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  },

  upload(request, onUploadProgress) {
    let formData = new FormData();
    formData.append('file', request.file);
    request.file = formData;

    return axios().post('url', request, {
      //put url of uploading model file here
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },

  // ===============================
  // For logged in customer ( with authentication)
  // ===============================
  getReuests(lang) {
    lang = lang == 1 ? '1' : '2';
    return ApiRequests.apiRequest(
      'get',
      'portal/requestCaseType',
      {
        params: {
          user_lang: lang,
        },
      },
      null
    );
  },

  getSteps(lang, pk) {
    lang = lang == 1 ? '1' : '2';
    return ApiRequests.apiRequest(
      'get',
      'portal/requestSteps',
      {
        params: {
          user_lang: lang,
          cases_types: pk,
        },
      },
      null
    );
  },

  getDocs(lang, pk) {
    lang = lang == 1 ? '1' : '2';
    return ApiRequests.apiRequest(
      'get',
      'portal/requestDocs',
      {
        params: {
          cases_types: pk,
          user_lang: lang,
        },
      },
      null
    );
  },

  async submitRequest(email, cases_types, req_pk) {
    return ApiRequests.apiRequest('post', 'portal/addCaseEmail', null, 
      {
        email,
        cases_types,
        req_pk
      },
    ).then(data=>{
      if(data[0]==false) {throw new Error(data[1])}
      else return data;
      
    });
  },

  downloadModel(cases_types) {
    return ApiRequests.apiRequest(
      'post',
      'portal/requestDocumentsFile',
       null,
      {
        cases_types: cases_types,
      }
    ).then(files =>{
      files.forEach(file => {
        let data = file.data;
        let path = file.path.split('/').slice(-1)[0];
        let type = path.split('.').slice(-1)[0];
        let name = path.split('.').slice(-2)[0];

        this.downloadPDF(data, type, name)
    })
  });

  },
  // ===============================


  //  -------------------------------------------------------------------------------------


  // ===============================
  // For a new customer ( without authentication)
  // ===============================
  getNewCustomerRequests(lang) {
    lang = lang == 1 ? '1' : '2';

    return axios()
      .get(`portal/newCustomerCaseType`, {
        params: { user_lang: lang },
      })
      .then((res) => {
        if (res.data.STATUS == true) return res.data.Data;
        else throw Error('Server Error, Code: ' + res.data.CODE);
      });
  },

  getNewCustomerRequestDocs(lang,pk) {
    lang = lang == 1 ? '1' : '2';
    return axios()
      .get(`portal/newCustomerrequestDocs`, {
        params: { 
          user_lang: lang,
          cases_types: pk
         },
      })
      .then((res) => {
        if (res.data.STATUS == true) return res.data.Data;
        else throw Error('Server Error, Code: ' + res.data.CODE);
      });
  },

  getNewCustomerRequestSteps(lang, pk) {
    lang = lang == 1? '1' : '2';
    return axios()
      .get(`portal/newCustomerrequestSteps`, {
        params: {
          user_lang: lang,
          cases_types: pk,
        },
      })
      .then((res) => {
        if (res.data.STATUS == true) return res.data.Data;
        else throw Error('Server Error, Code: ' + res.data.CODE);
      });
  },

  downloaddNewCustomerFiles(cases_types){
    return axios()
    .get(`portal/newCustomerrequestDocumentsFile`, {
      params: {
        cases_types: cases_types,
      }
    })
    .then(res => {
      if(res.data.STATUS===false) {
        throw new Error(res.data.Msg)
      }
     else return res.data.Data;
    })
    .then(files => {
      files.forEach(file => {
        let data = file.data;
        let path = file.path.split('/').slice(-1)[0];
        let type = path.split('.').slice(-1)[0];
        let name = path.split('.').slice(-2)[0];

        this.downloadPDF(data, type, name)
      })
    })
  },
  // ===============================
};
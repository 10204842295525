<template>
  <div>
   
      <table >
        <thead>
          <tr>
            <th  scope="col">{{$t('screen_no')}}</th>
            <th  scope="col">{{$t('description')}}</th>
            <th  scope="col" class="img">{{$t('screen')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >1</td>
            <td >{{$t('water_desc_1')}}</td>
            <td >
              <img  src="../../assets/meter_screens_images/water/1.png" />
            </td>
          </tr>
          <tr>
            <td >2</td>
            <td >
              {{$t('water_desc_2')}}
            </td>
            <td >
              <img 
                src="../../assets/meter_screens_images/water/2.png"
              />
            </td>
          </tr>
          <tr>
            <td >3</td>
            <td >
              {{$t('water_desc_3')}}
            </td>
            <td >
              <img 
                src="../../assets/meter_screens_images/water/3.png"
              />
            </td>
          </tr>
          <tr>
            <td >4</td>
            <td >{{$t('water_desc_4')}}</td>
            <td >
              <img  src="../../assets/meter_screens_images/water/4.png" />
            </td>
          </tr>
          <tr>
            <td >5</td>
            <td >{{$t('water_desc_5')}}</td>
            <td >
              <img  src="../../assets/meter_screens_images/water/5.png" />
            </td>
          </tr>
          <tr>
            <td >6</td>
            <td >{{$t('water_desc_6')}}</td>
            <td >
              <img 
                src="../../assets/meter_screens_images/water/6.png"
              />
            </td>
          </tr>
          <tr>
            <td >7</td>
            <td >
              {{$t('water_desc_7')}}
            </td>
            <td >
              <img  src="../../assets/meter_screens_images/water/7.png" />
            </td>
          </tr>
        </tbody>
      </table>

      <br>
      <br>
      <h2 class="mb-5">{{ $t("codes_of_parameters") }}</h2>

         <ul class="mt-2">
        <li>
          <p style="color:green;" class="fw-bold fs-6"> {{$t('water_notice_1')}}</p>
        </li>
        <li>
          <p style="color:red;" class="fw-bold fs-6">{{$t('water_notice_2')}}</p>
        </li>
      </ul>
         <table class="parameters-table" >
        <thead>
          <tr>
            <th  scope="col">{{$t('card_read_code')}}</th>
            <th  scope="col">{{$t('card_read_code_description')}}</th>
            <th  scope="col">{{$t('error_code')}}</th>
            <th  scope="col">{{$t('error_code_description')}}</th>
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >Cd 10</td>
            <td >{{$t('code_desc_1')}}</td>
            <td>Er 00</td>
            <td >{{$t('error_desc_1')}}</td>
          </tr>
          <tr>
            <td >Cd 20</td>
            <td >
              {{$t('code_desc_2')}}
            </td>
            <td>Er 01</td>
             <td >{{$t('error_desc_2')}}</td>
          </tr>
          <tr>
            <td >Cd 40</td>
            <td >
              {{$t('code_desc_3')}}
            </td>
            <td>Er 02</td>
             <td >{{$t('error_desc_3')}}</td>
          </tr>
          <tr>
            <td >Cd 50</td>
            <td >{{$t('code_desc_4')}}</td>
            <td>Er 03</td>
             <td >{{$t('error_desc_4')}}</td>
          </tr>
          <tr>
            <td >Cd 51</td>
            <td >{{$t('code_desc_5')}}</td>
            <td>Er 04</td>
             <td >{{$t('error_desc_5')}}</td>
          </tr>
          <tr>
            <td >Cd 60</td>
            <td >{{$t('code_desc_6')}}</td>
            <td>Er 67</td>
             <td >{{$t('error_desc_6')}}</td>
          </tr>
          <tr>
            <td >Cd 70</td>
            <td >
              {{$t('code_desc_7')}}
            </td>
            <td>Er 74</td>
             <td >{{$t('error_desc_7')}}</td>
          </tr>

           <tr>
            <td >Cd 71</td>
            <td >
              {{$t('code_desc_8')}}
            </td>
            <td>Er 75</td>
             <td >{{$t('error_desc_8')}}</td>
          </tr>

           <tr>
            <td >Cd 73</td>
            <td >
             {{$t('code_desc_9')}}
            </td>
            <td>Er 76</td>
             <td >{{$t('error_desc_9')}}</td>
          </tr>

           <tr>
            <td >Cd 85</td>
            <td >
             {{$t('code_desc_10')}}
            </td>
            <td></td>
            <td >{{$t('error_desc_10')}}</td>
          </tr>

 
        </tbody>
      </table>
    </div>
  

  <!-- src\assets\meter_screen_images\Remaining_Money.jpg -->
</template>
<script>
import { getCookie } from '../../utils';
export default {
  data() {
    return {
      get lang() {
        return getCookie('lang')
      },
    };
  },
  methods: {},
};
</script>
<style scoped>
img,td:has(img),.img{
  display: none;
}
img {
    filter: brightness(2.5);
}

table tr td {
  font-size: 1rem;
  vertical-align: middle;
}
@media (max-width:760px){
 table tr td {
  font-size:12px;
} 
img{
  width:100% !important;
}
 th{
  padding: 10px !important;
  font-size: 13px;
}
}
table * {
  text-align: center;
}

table tbody tr td:first-child {
  direction: rtl;
}


.parameters-table th:nth-child(3){
  background-color: #e6252538;
  color:red;
  font-weight: bold;
}

.parameters-table th:first-child{
   background-color: #00800052;
   color:green;
}

th,td{
  background: #F0F0F0;
  color:  #4F4D61;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

}
td{
font-weight: 500;
background: #fff;
}
td p{
  text-align: center;
  margin-bottom: 0px;
}
.text-secondary {
  color: #AAAAB2;
  font-size: 12px;
  font-weight: 500;
}
table, td, th{
  border: 1px solid #E0E0E0 !important;
}
</style>
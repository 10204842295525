<template>
  <div>

    <b-button @click="loadClientInfo(content)" 
                     class="btn btn-primary h-100 d-btn"
                    :class="{ 'nav-font-size': lang === 'en' }"
                    style="font-size:14px"
    > {{$t("payment_details_button")}}</b-button> 

  <b-modal 
  no-close-on-esc
  no-close-on-backdrop
  class="model-example"
  :header-class="{
        'right-close': lang == 'ar',
        'left-close': lang == 'en',
    }"
    
  :id="modal_id" >
  <h3 style="margin-top:-20px" :class="{ rtl: lang === 'ar', ltr: lang === 'en' }">{{$t("payment_details_header")}}</h3>
   <table
      :class="{
        'h-100 rtl': lang === 'ar',
        'h-100 ltr': lang === 'en',
      }"
    >
      <tr>
        <th>{{$t("clause")}} </th>
        <th> {{$t("cash_details")}} </th>
      </tr>
      <tr>
        <td >{{$t("clause")}}</td>
        <td >{{ content.amount.split(":")[1]}}</td>
      </tr>

    </table>

  </b-modal>
  </div>
</template>

<script>

import { getCookie } from "@/utils";

export default {
    props:["content","index"],
  data() {
    return {
      get lang() {
        return getCookie("lang") || "en";
      },
      modal_id:null,
  }
  },

  methods: {
   setID(){
 this.modal_id=`model-${this.index}`;
   },
     loadClientInfo(item) {
        this.current_Row_item=item;
        this.$bvModal.show(this.modal_id);
      },
  },
  created(){
      this.setID();
  }

};
</script>

<style >

@media (max-width:600px) {
.d-btn{
  font-size:12px !important;
}  
}

.close{
  margin: 0px 28px;
    border-color: transparent;
    background: transparent;
    border-radius: 5px;
    font-size: 37px;
    color: #4F4D61;
    height: 14px;
    width: 14px;
    position: absolute;
    top: -4px;
}
.modal-header {
  border: none;
  padding: 0;
  margin: 0;
  height: 40px;
  position: relative;
}

.right-close {
  justify-content: start !important;
}
.right-close .close{
  margin:0px 5px;
}
.left-close {
  justify-content: end !important;
}

/* .modal-header {
  border: none;
  padding: 0;
  margin: 0;
} */

.nav-font-size {
  font-size: 15px;
}
</style>

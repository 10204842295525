import ApiRequests from "./ApiRequests";

function getLov(pk,term,lb1,lb2,lb3,lb4,lb5){
       return  ApiRequests.apiRequest('get',`portal/BuduLov/get/${pk}`,{params : {
        pk: pk,
        TERM:term,
        LP1:lb1,
        LP2:lb2,
        LP3:lb3,
        LP4:lb4,
        LP5:lb5
      }},null).then (data=>{return data})

    }

export{getLov}
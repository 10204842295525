<template>
   <b-modal
    @shown="recharge"
    @hide="hidePayTabs"
    id="payTabsModal"
    ref="paytabs"
    size="m"
    centered
    :header-class="{'ltr' : lang==2 , 'rtl' : lang==1}"
    >
          <div >    
            <div class="row" id="iframe-container" ref=iframe>
             
            </div>
            <Loading v-if="loading"/>
          </div>
      </b-modal>
</template>

<script>
import Loading from '@/components/Loading.vue';
export default {
  components:{
    Loading,
  },
  data() {
      return {
      loading:true,
      }
    },
  props: [ 'payLink','lang'],
  watch:{
    payLink(){
       this.$bvModal.show('payTabsModal'); 
    }
  },
  methods: {
    hidePayTabs()
  {
    
    let d=this.$refs.iframe;
    d.innerHTML = ''
    this.$emit('clear_amount');
    this.$router.replace({query: {status:0,message:'Request Cancelled'} });

  },
    recharge() {
        let d=this.$refs.iframe;
        d.innerHTML = ""
          if(this.payLink != null)
          {
            let e = document.createElement('iframe');
          
            d.classList.add('iframe-show');
            e.src = this.payLink; 
            d.appendChild(e);
            this.loading=false; 
          }
          else
          {
            let p = document.createElement('p');
            p.innerText = "PayTabs is not working, please refresh the page or try again later"
            d.innerHTML = "";
            this.loading=false;
            d.appendChild(p);
          } 

          this.$emit('open_paytabs');      
    },

  },

}

</script>
<style >
.iframe-show 
{
  min-height: 84vh;
}

.hide {
  display: none !important;
}
/* note : IMPO don't change id of modal */
#payTabsModal___BV_modal_content_ {
  margin: auto;
  height: 90vh;
  max-width: 430px;
}
#payTabsModal___BV_modal_header_{
  height: 38px;
  border: none;
  padding: 0;
  margin: 0;
}
#payTabsModal___BV_modal_header_ button{
  background: transparent;
  margin: 0;
  color: grey;
  margin: 0px 5px;
  font-size: 25px;
}
#payTabsModal___BV_modal_body_{
  min-height: 95%;
    padding: 5px;
    padding-top: 0px;
}
#payTabsModal___BV_modal_footer_{
  display: none;
}
</style>
<template>
  <div class="company-info__container" :class="{'MarginAuto': company.numberOfLoginFooterElements!=4}">
    <div v-if="company. login_footer_elements.numbers" class="company-info__block company-info__numbers">
      <span class="company-info__block-title"
        >{{ $t("customer_support") }}:
      </span>
      <a
      v-for="number in company.customer_service_numbers" :key="number"
      :href="`tel:${number}`"
        class="company-info__block-value"
      >
        <span>
          {{ number }}
        </span>
      </a>
    </div>

    <div v-if="company. login_footer_elements.email" class="company-info__block company-info__email">
      <span class="company-info__block-title">{{ $t("email_address") }}: </span>
      <a
        :href="`mailto:${company.customer_service_emails[0]}`"
        class="company-info__block-value"
      >
        <span>
          {{ company.customer_service_emails[0] }}
        </span>
      </a>
    </div>

    <div v-if="company. login_footer_elements.location" class="company-info__block company-info__location">
      <i class="pylon-icon-Location"></i>
      <a :href="company.location_map_link" target="_blank" class="company-info__block-value">
        <span class="company-info__block-title">{{ $t("project_location") }}</span>
      </a>
    </div>

    <div v-if="company. login_footer_elements.headQuarters" class="company-info__block company-info__headquarters">
      <span class="company-info__block-title">{{ $t("headquarters") }}: </span>
      <div v-if='lang==1' class="company-info__block-value d-flex flex-column">
        <span  v-for="location in company.locations.ar" :key="location">{{
          location
        }}</span>
      </div>
      <div v-if='lang==2' class="company-info__block-value d-flex flex-column">
        <span v-for="locationEn in company.locations.en" :key="locationEn">{{
          locationEn
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["company","lang"],
    computed: {
        phoneNumber() {
            this.company.customer_service_numbers[0]
        },
        
        email() {
            this.company.customer_service_emails[0]
        },
        
        mapLocation() {
            this.company.location_map_link
        },

        locations() {
            this.company.locations
        }
    },
};
</script>

<style scoped>

.company-info__container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  margin-top: auto;
  align-items: flex-start;
  padding: 16px 32px;
 
}
.company-info__container.MarginAuto{
  margin: auto;
}
.company-info__block, .company-info__block a {
  cursor: pointer;
  color: #2C3E50;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.company-info__location i{
  color: #4F4D61 ;
}

.company-info__location a:hover {
  color: var(--main-color);
}

.company-info__block-title {
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
}

.company-info__block-value {
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
  
}

@media (max-width:700px) {

    .company-info__location a span {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}




</style>
<template>
  <div class="notification-settings-container " :class="{ 'rtl': lang ==1, 'ltr': lang ==2 }" >
    <div class="notificationIp">


<!--  -->
        <!-- <label class="complaintLbl w-100" for="meter-code">
          {{$t("meter_no")}}
        </label>
        
        <br /> -->

        <!-- <b-form-select
          id="meter-code"
          class="drp w-100 mt-3 mb-1"
          :options="mdm_meter"
          v-model="form.mdm_meters"
          :state="validation.mdm_meters"
          aria-describedby="meter-code-feedback"
        ></b-form-select> -->

        <!-- <b-form-invalid-feedback v-if="lang == 2" id="meter-code-feedback">
          Meter Code is required
        </b-form-invalid-feedback>

        <b-form-invalid-feedback
          v-if="lang == 1"
          id="meter-code-feedback"
          class="rtl subLbl"
        >
          رقم العداد مطلوب
        </b-form-invalid-feedback> -->

<!--  -->

      <div v-if="company.show_critical_part">

    
        <label  v-if="payment_mode==2" for="critical-amount">{{ $t("critical_credit") }}</label>
        <p  v-if="payment_mode==2">{{ $t("critical_desc")}} </p>

        <label  v-if="payment_mode==1" for="critical-amount">{{ $t("critical_amount") }}</label>
        <p  v-if="payment_mode==1">{{ $t("critical_amount_desc")}} </p>
   
        <b-form-input
          id="critical-amount"
          v-model="form.critical_amount"
          :state="validation.critical_amount"
          aria-describedby="critical-credit-live-feedback"
          required
          placeholder="Critical credit (EGP)"
          class="notificationIpSize w-100"
        ></b-form-input>

        <b-form-invalid-feedback id="critical-credit-live-feedback">
        {{$t("critical_credit_note")}}
        </b-form-invalid-feedback>
     </div>
    </div>
  
    <b-row v-if="company.notfication_settings_elements.sms" class="mb-3 d-flex align-items-center">

      <b-col cols="4">
        <label class="check">
          {{ $t("sms") }}
          <input type="checkbox" id="sms-check" v-model="form.sms_flag" />
          <span class="checkmark"></span>
        </label>
      </b-col>

      <b-col cols="8">
        <b-form-input
          id="mobile-input"
          v-model="form.notification_mobile"
          type="tel"
          required
          :state="validation.notification_mobile"
          aria-describedby="mobile-live-feedback"
          class="ipLength"
          placeholder="01---------"
          style="direction:ltr"
        ></b-form-input>

        <b-form-invalid-feedback id="mobile-live-feedback">
          <ul style="list-style-type:none;padding:0px">
            <li v-for="(item,index) in $t('mobile_validation')" :key="index">
            <span v-if="index==0">{{item}}</span> 
            <span v-else>- {{item}}</span>
           </li>     
          </ul>
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    

    <b-row  v-if="company.notfication_settings_elements.email" class="mb-3 d-flex align-items-center">
      <b-col cols="4">
        <label class="check"
          >{{ $t("email_address") }}
          <input type="checkbox" id="email-check" v-model="form.email_flag" />
          <span class="checkmark"></span>
        </label>
      </b-col>

      <b-col cols="8">
        <b-form-input
          v-model="form.notification_email"
          id="email-input"
          required
          class="ipLength"
          style="direction: ltr !important"
          type="email"
          :state="validation.notification_email"
          aria-describedby="email-live-feedback"
          placeholder="@example.com"
        ></b-form-input>

        <b-form-invalid-feedback id="email-live-feedback">
          <ul style="list-style-type:none;padding:0px">
            <li  v-for="(item,index) in $t('email_validation')" :key="index">
            <span v-if="index==0">{{item}}</span> 
            <span v-else>- {{item}}</span>
           </li>     
          </ul>
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    
    <b-row  v-if="company.notfication_settings_elements.mobile_app" class="mb-3 d-flex align-items-center">
        <b-col cols="12">
          <label class="check"
            >{{ $t("mobile_app") }}
            <input type="checkbox" id="mobile-check" v-model="form.mobile_flag" />
            <span class="checkmark"></span>
          </label>
        </b-col>
    </b-row>

    <b-row v-if="company.notfication_settings_elements.customer_portal" class="mb-3 d-flex align-items-center">
      <b-col cols="12">
        <label class="check"
          >{{ $t("customer_portal") }}
          <input type="checkbox" id="portal-check" v-model="form.portal_flag" />
          <span class="checkmark"></span>
        </label>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-button
         id="update-notify-btn"
          @click="handleSubmit"
          class="updateButton w-100 mt-3 btn-color"
          style="text-align: center !important"
          >{{ $t("update_notifications_settings") }}</b-button
        >
      </b-col>
    </b-row>
   <Feedback
          v-if="feedback.show"
          :state="feedback.state"
          :message="feedback.message"
          :lang="lang"
          class="info-nofication"
          @unmountFeedComponent="feedback.show=false"
        />
   
  </div>
</template>

<script>
import services from '../../services/profile';
import Feedback from "../Feedback.vue";

export default {
  components: {
    Feedback,
  },

  props: ['NotificationSettings',"company","propsObj",'meter_id','lang'],
  data() {
    return {
      form: {
        sms_flag: 0,
        notification_mobile: null,
        email_flag: 0,
        notification_email: null,
        mobile_flag: 0,
        portal_flag: 0,
        critical_amount: 0,
        req_pk:null
      },
      validation: {
        notification_mobile: null,
        notification_email: null,
        critical_amount: null,
        req_pk: null,
      },
      feedback: {
        state: 0,
        message: "",
        show: false,
      },
      req_pk:[],
      payment_mode:0,

      mdm_meter: [] ,
      lang_parameter:0,
    };
  },
  watch: {
    "form.req_pk"() {
      this.clearForm();
      this.initialize(); 
    },
    

    // 'meter_id'(){
    //   this.form.mdm_meters=this.meter_id;
    //   this.clearForm();
    //   this.initialize();
    // },
    propsObj(){
      this.req_pk.push(this.propsObj.userInfo[0].req_pk);
      this.payment_mode=this.propsObj.meterInfo.payment_mode;
      let data= this.propsObj.userInfo.map((meter) => {
        var optionText=meter.code?meter.code:meter.account_reference;
        return {
          text: (meter.mdm_utilities == 1 ? (this.$t('electricity') ): 
          ( meter.mdm_utilities == 2 ? this.$t('gas') : this.$t('water') )) + " - " + optionText ,
          value: meter.req_pk,
        };
      })
      this.mdm_meter = data
      // this.form.mdm_meters = data[0].value;
      this.form.req_pk=this.meter_id;
      // this.clearForm();
      // this.initialize();
    },
  },
 
  // created() { 
  //   // this.getlangParameter();
  //     userServices
  //       .getUser()
  //       .then((data) =>      
  //       {
  //         this.mdm_meters.push ( data[0].mdm_meters);

  //         meterservices.getSingleMeter(this.mdm_meters[0],this.lang_parameter) //be handeled later for more than meter gas-water-electric)
  //         .then((data) => {
  //           if (data.length) {
  //             this.payment_mode=data[0].payment_mode;
  //           } else {
  //             throw new Error(1);
  //           }
  //         })  
  //       })
  //       .then(()=>{
  //         userServices
  //           .getUser()
  //           .then((data) =>
  //             {
  //               return data.map((meter) => {
  //               return {
  //                 text: (meter.mdm_utilities == 1 ? (this.$t('electricity') ): 
  //                 ( meter.mdm_utilities == 2 ? this.$t('gas') : this.$t('water') )) + " - " + meter.code ,
  //                 value: meter.mdm_meters,
  //               };
  //             })
  //             }
  //           )
  //           .then((data) => {
  //             this.mdm_meter = data
  //             this.form.mdm_meters = data[0].value;
  //           })
  //           .then(() => this.initialize())
  //           })
  //       .catch((err) => console.log(err.message));
  // },

  methods: {
    initialize() {
      services
        .getNotifyInfo(this.form.req_pk)
        .then(data => {
          // if(data.length == 0)
          //   throw Error(1);
          // else 
            return data ;
        })
        .then((data) => {
          this.form.notification_mobile =data.notification_mobile
          this.form.notification_email = data.notification_email
          this.form.sms_flag = Number(data.sms_flag);
          this.form.email_flag = Number(data.email_flag);
          this.form.mobile_flag = Number(data.mobile_flag);
          this.form.portal_flag = Number(data.portal_flag);
          this.form.critical_amount = Number(data.critical_amount);
        })
        .catch(err => {
          this.clearForm();
        })
    },

    handleSubmit() { 
      if (this.validateForm()) {
       services.updateNotificationsSettings(this.form)
        .then(res => {
          if (res== true) {
            this.feedback.state = 1;
            this.feedback.show = true;
            this.feedback.message='';
            // setTimeout( () => (this.feedback.show = 0), 5000);
          }
          //  else {
          //   throw Error(res.data.Msg)
          // }
        })
        .catch(err => {
            this.feedback.state = 2;
            this.feedback.message = err.message;
            this.feedback.show = true;
            // setTimeout(() => (this.feedback.show = 0), 5000);
        })  
      }
    },

    validateForm(){
      this.validation.notification_mobile = this.validateMobile()
      this.validation.notification_email = this.validateEmail()
      this.validation.critical_amount = this.validateCriticalCredit()
      this.validation.req_pk = this.validateMDM_Meters()
      
      setTimeout(() => {
        this.validation = {
          notification_mobile: null,
          notification_email: null,
          critical_amount: null,
          req_pk: null,
        }
      }, 5000);

      return this.validation.critical_amount && 
      this.validation.req_pk && 
      (this.form.sms_flag == 1 ? this.validation.notification_mobile : true ) && 
      (this.form.email_flag == 1 ? this.validation.notification_email : true );
    },

    validateCriticalCredit() {
      const CREDIT_REGEX = /^([0-9]+)$/;
      // console.log( "ffff",CREDIT_REGEX.test(this.form.critical_amount) && this.form.critical_amount >=100)
      return CREDIT_REGEX.test(this.form.critical_amount) && this.form.critical_amount >=100;
    },
    validateMDM_Meters() {
      this.validation.req_pk =
        this.form.req_pk != "" && this.form.req_pk != null;
      return this.validation.req_pk;
    },

    validateEmail() {
      if(this.form.email_flag == 0)
        return
      
      const EMAIL_REGEX = /\S+@\S+/;
      return EMAIL_REGEX.test(this.form.notification_email);
    },

    validateMobile() {
      if(this.form.sms_flag ==0 )
        return

      const MOBILE_REGEX = /^[0-9]{11}$/;
      return MOBILE_REGEX.test(this.form.notification_mobile);
    },

  

    clearForm() {
        this.form.notification_mobile = null;
        this.form.notification_email = null;
        this.form.sms_flag = false;
        this.form.email_flag = false;
        this.form.mobile_flag = null;
        this.form.portal_flag = null;
        this.form.critical_amount = 0;
    }
  },
 
};
</script>

<style>
.notification-settings-container label{
  color:#2c3e50;
}
.notificationIp {
  /* padding-top: 20px; */
  padding-bottom: 24px;
}
.notificationIp > label{
  font-size:14px;
  font-weight: bold !important;

}
.notificationIp > p{
  color: #666;
}
.notificationIpSize {
  width: 40%;
}
.check {
  display: block;
  position: relative;
  padding-left: 35px;
  height: 100%;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rtl .check{
  padding-left: 0px;
  padding-right: 35px;
}
@media (max-width:450px){
  .check{
    font-size: 13px;
  }
}
@media (max-width:380px){
  .check{
    font-size: 11px;
  }
}
/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
.rtl .checkmark{
  left:unset;
  right: 0;
}

/* On mouse-over, add a grey background color */
.check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  background-color: var(--main-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.info-nofication{
  position:fixed ;
  top: 10% ;
  left:5%;
  width:30%;
}
</style>

<template >
  <div class="login" style="min-height: 100vh" :class="{'rtl d-flex flex-column': lang==1, 'ltr d-flex flex-column': lang==2}">

    <NavBar  :company="company" :lang="lang"/>
    <div class="colored-rectangle"></div>

    <div class="login-container w-100"  :class="{'rtl': lang==1, 'ltr': lang==2}">
   
      <div  v-if="reset_container===true" class="card f-card rounded-lg ">
    
         <router-link to="/login" ><p class="back"><i :class="{'pylon-icon-ArrowRight': lang==1, 'pylon-icon-Arrow-Left': lang==2}"></i>{{$t("back")}}</p></router-link>

        <div >
          <h2 style="margin-bottom:24px">{{$t('reset_password')}}</h2>
        </div>
         
        <b-form>
         <b-form-group
          style="margin-bottom:16px"
            id="input-group-1"
            :label="$t('username')"
            label-for="customer-email"
          >
            <b-form-input
              id="customer-email"
              v-model="form.email"
              type="text"
              :placeholder="$t('username_placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
         style="margin-bottom:48px"
            id="input-group-2"
            :label="$t('phone_num')"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="form.phone"
              type="text"
              :placeholder="$t('phone_number_placeholder')"
              required
            ></b-form-input>
           
          </b-form-group>
          <b-form-group >
              <b-button
          @click="handelResetPass(1)"
            class="button sms-btn btn-color"
         
            >
            {{$t('via_sms')}} 
            </b-button >
       
             <b-button
              @click="handelResetPass(2)"
              class="button email-btn btn-color"          
            >
            {{$t('via_email')}} 
            </b-button
          >
          <!-- </router-link> -->
          </b-form-group>
        
       
        </b-form>
   
          <!-- <LoadingSpinner v-if="loading" color="white" scale="0.4" /> -->
      </div>
    

      <div v-if="mssg_container===true" class="card ff-card  rounded-lg ">
      
        <p @click="change" class="back" :class="{'rtl': lang==1, 'ltr': lang==2}"><i :class="{'pylon-icon-ArrowRight': lang==1, 'pylon-icon-Arrow-Left': lang==2}"></i>{{$t("back")}}</p>

        <div class="text-center" >
          <p class="main-text" v-if="reset_type=='sms'">{{$t("sms_note")}}</p>
          <p class="main-text" v-if="reset_type=='email'">{{$t("email_note")}}</p>
          <p class="second-text" v-if="reset_type=='sms'">{{$t("didnt_recieve_sms")}} <span class="resend">{{$t("resend_again")}}</span> {{$t("after")}}</p>
          <p class="second-text" v-if="reset_type=='email'">{{$t("didnt_recieve_email")}} <span class="resend">{{$t("resend_again")}}</span> {{$t("after")}}</p>

          <router-link :to="{name: 'Login'}"  >
              <b-button
        
            class="margin-t w-50 btn-color"
            style="text-align: center"
            >
        {{$t("go_to_login")}}
            </b-button
          >
          </router-link>
        </div>
      </div>

    </div>
      
    <div class="d-flex justify-content-center">
      <img  
          style="display: block;
          margin-top: 20px;
          width: 140px;
          height: 61.09px;"
          src="../assets/pylon.png"> 
    </div>  

    <CompanyInfo v-if="company.login_footer" 
    
    :company="company" />
         
  </div>
</template>

<script>
import NavBar from "@/components/Login/LoginNavbar.vue";
import CompanyInfo from "@/components/Login/CompanyInfo.vue";

export default {
  components: {
    NavBar,
    CompanyInfo
  },
  props: ["lang", "company"],
  data() {
    return {
      form: {
        email: "",
        phone: "",
      },
      error: false,
      loading:false,
      reset_container:true,
      mssg_container:false,
      reset_type:'',
    }
  },
  methods: {
    handelResetPass(type) {
         this.reset_container=false;
         this.mssg_container=true;
        if(type===1){
           this.reset_type="sms";
          
        }
        else if(type===2) {  
          this.reset_type="email";
        }
    },
    change(){
      this.reset_container=true;
         this.mssg_container=false;
    }
  },
  
};
</script>

<style scoped>
.navBig {
  background-color: #002751;
  color: white;
  padding: 10px;
}
.login{
   background: #f0f0f0;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -235px;
}
.row{
  margin-right:0px !important;
  margin-left:0px !important;
}
.colored-rectangle{
  width: 100%;
  height: 36vh;
  background: var(--secondary-color);
  top: 0;
}



.f-card,.ff-card{
    position: relative;
    width: 648px;
    min-height: 350px;
    padding: 20px 24px;
    margin-bottom: 50px;
    border: 0px;
}

@media (max-height:700px) {

  .f-card, .ff-card {
    margin-top:25px;
  }

}

.margin{
  margin-bottom:16px;
}
.button {
    width: 48% ;
    height: 40px;
    text-align: center;
}
@media (max-width:500px){
  .button {
  font-size: 13px;
  padding: 2px;
}
.ff-card p{
  font-size: 16px;
    line-height: 1.8;
}

}
#input-group-1__BV_label_,#input-group-2__BV_label_{
  margin-bottom: 8px !important;
}
.rtl .sms-btn,.ltr .email-btn{
  margin-left:2%;
}
.rtl .email-btn,.ltr .sms-btn{
  margin-right:2%;
}
.footerLast1 {
  background-color: var(--secondary-color) !important;
  color: var(--inverse-text-color);
  padding-top: 19px;
  padding-bottom: 3px;
}
.footerLastP1{
  text-align: center;
}


.ff-card p{
    font-family: 'Montserrat';
    font-style: normal;
     color: var(--secondary-color);
}
.main-text{
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 24px;
    text-align:center
}
.second-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 50px;
    text-align:center
}
.resend{
    color:var(--main-color);
    text-decoration: underline;
    font-weight: bold !important;
}
.margin-t {
    width: 208px !important;
    height: 40px;
}

.back{
  cursor: pointer;
    color: white !important;
    position: absolute;
    top: -35px;
    left: 0;
    right: 3%;
}
.ltr .back{
  left: 3%;
  right: 0;

}
.back i{
  font-size: 23px;
    /* margin-top: -7px; */
    position: absolute;
    left: 100%;
    top: 10%;
}

.ltr .back i{
    right: 100%;
    left:unset;
    top:4%;
}
</style>
import axios from '../axios';
import router from './../router/index';

export class AuthenticationError extends Error {
  response = null;
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export class LockoutError extends Error {
  response = null;
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export default {

  async getCSRFToken(lang) {
    let user_lang = lang;
    const res = await axios().post('portal/loginCSRF', {
      user_lang,
    })
    if(res.data.STATUS !== true) {
      throw new Error('Network Error, please Refresh the page or Try again later');
    }
  },

  async login(user) {
    
    const res = await axios().post(`portal/login`, {
      email: user.email,
      password: user.password
    })

    if(res.status == 423) {
      throw new LockoutError(res.data.Msg, res.data);
    }   

    if (res.data.STATUS !== true) {
      throw new AuthenticationError(res.data.Msg, res.data)
    }

    const data = await res.data.Data

    if (data.market_users !== null) {
      let d = new Date();
      d.setTime(d.getTime() + 3600 * 1000);
      let expires = "expires=" + d.toUTCString();
      let lang = data.lang == '1' ? 1 : 2
      document.cookie =
        "user_id=" + data.market_users + ";" + expires + ";path=/";
      document.cookie =
        "lang=" + lang + ";" + expires + ";path=/";
    }

  },

  logout() {
    return axios().post(`portal/logout`)
    .then(res => {
        this.endSession();
    })
    .catch(err => {
        this.endSession();
    })
  },
  endSession(){
    document.cookie = "Auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    router.go('/login')
  }
};
<template>
  <div>

      <table >
        <thead>
          <tr>
            <th  scope="col">{{$t('screen_no')}}</th>
            <th  scope="col">{{$t('description')}}</th>
            <th  scope="col" class="img">{{$t('screen')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >0 .0 .19</td>
            <td >{{$t('desc_0_0_19')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Remaining_Money.jpg" />
            </td>
          </tr>
          <tr>
            <td >C. 1. 0</td>
            <td >{{$t('desc_C_1_0')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Serial_Number.jpeg" />
            </td>
          </tr>
          <tr>
            <td >C. 1. 5</td>
            <td >{{$t('desc_C_1_5')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Customer_Code.jpg" />
            </td>
          </tr>
          <tr>
            <td >2. 9. 0</td>
            <td >{{$t('desc_2_9_0')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Date.jpeg" />
            </td>
          </tr>
          <tr>
            <td >1. 9. 0</td>
            <td >{{$t('desc_1_9_0')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Time.jpeg" />
            </td>
          </tr>
          <tr>
            <td >0. 8. 1</td>
            <td >{{$t('desc_0_8_1')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Total_Consumption.jpg" />
            </td>
          </tr>
          <tr>
            <td >0. 9. 1</td>
            <td >{{$t('desc_0_9_1')}}</td>
            <td >
              <img class="w-50"
                src="../../assets/meter_screens_images/electricity/Current_Consumption.jpeg"
              />
            </td>
          </tr>
          <tr>
            <td >0. 7. 1</td>
            <td >
              {{$t('desc_0_7_1')}}
            </td>
            <td >
              <img class="w-50"
                src="../../assets/meter_screens_images/electricity/Active_Power_Import_Total.jpeg"
              />
            </td>
          </tr>
          <tr>
            <td >0. 7. 3</td>
            <td >
              {{$t('desc_0_7_3')}}
            </td>
            <td >
              <img class="w-50"
                src="../../assets/meter_screens_images/electricity/Reactive_Power_Import_Total.jpeg"
              />
            </td>
          </tr>
          <tr>
            <td >0. 6. 15</td>
            <td >{{$t('desc_0_6_15')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Max_Demand_KWH.jpeg" />
            </td>
          </tr>
          <tr>
            <td >F.F. 0</td>
            <td >{{$t('desc_F_F_0')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Errors_Code.jpeg" />
            </td>
          </tr>
          <tr>
            <td >C.6 0.18</td>
            <td >{{$t('desc_C_6_18')}}</td>
            <td >
              <img class="w-50"
                src="../../assets/meter_screens_images/electricity/Current_Monthly_Consumption_Value.jpg"
              />
            </td>
          </tr>
          <tr>
            <td >C.14. 0</td>
            <td >
              {{$t('desc_C_14_0')}}
            </td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Tariff_ID.jpg" />
            </td>
          </tr>
          <tr>
            <td >0. 2. 0</td>
            <td >
              {{$t('desc_0_2_0')}}
            </td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Firmware_Version.jpg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 31</td>
            <td >{{$t('desc_0_7_31')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Current_L1.jpg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 51</td>
            <td >{{$t('desc_0_7_51')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Current_L2.jpeg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 71</td>
            <td >{{$t('desc_0_7_71')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Current_L3.jpeg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 32</td>
            <td >{{$t('desc_0_7_32')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Volt_L1.jpeg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 52</td>
            <td >{{$t('desc_0_7_52')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Volt_L2.jpeg" />
            </td>
          </tr>
          <tr>
            <td >0. 7. 72</td>
            <td >{{$t('desc_0_7_72')}}</td>
            <td >
              <img class="w-50" src="../../assets/meter_screens_images/electricity/Ins_Volt_L3.jpg" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  

  <!-- src\assets\meter_screen_images\Remaining_Money.jpg -->
</template>
<script>
import { getCookie } from '../../utils';
export default {
  data() {
    return {
      get lang() {
        return getCookie('lang')
      },
    };
  },
  methods: {},
};
</script>
<style scoped>
table tr td {
  font-size:1rem;
  vertical-align: middle;
}
img,td:has(img),.img{
  display: none;
}
@media (max-width:760px){
 table tr td {
  font-size:13px;
  padding: 5px !important;
} 
img{
  width:100% !important;
}
}
table * {
  text-align: center;
}

table tbody tr td:first-child {
  direction: rtl;
}

th,td{
  background: #F0F0F0;
  color:  #4F4D61;
  font-size: 14px;
  font-weight: 700;
  text-align: center;

}
td{
font-weight: 500;
background: #fff;
}
td p{
  text-align: center;
  margin-bottom: 0px;
}
.text-secondary {
  color: #AAAAB2;
  font-size: 12px;
  font-weight: 500;
}
table, td, th{
  border: 1px solid #E0E0E0 !important;
}
</style>
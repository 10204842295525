<template >
  <div>
    <div class="card my-3 table-container">
      <b-row>
        <b-col sm="12">
          <h4
            :class="{
              'title text-end mb-4': lang ==1,
              'title text-start mb-4': lang ==2,
            }"
        
          >
            {{ $t("payment_history") }}
          </h4>
        </b-col>
        <b-col sm="12">
          <div class="h-100 w-100 relative bg-white">
            <loading v-if="paymentLoading" />
            <ErrorHandler v-if="paymentError" :error="errorMessage" />
            <CustomeTable  :lang="lang" :company="company" :headers="headers" :items="payments" :showPager="false" :perPage="6" :show="show" :actions="actions">
            </CustomeTable>
          </div>
        </b-col>
      </b-row>
      
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import ErrorHandler from "@/components/ErrorHandler.vue";
import CustomeTable from "@/components/CustomeTable.vue";
import services from "@/services/payment";

export default {
  name: "PaymentsHistory",
  props: {
    selectedMeter: {
      default: () => "",
    },
    company:{
      default: () => {}, 
    },
    lang:{
      default: () => {}, 
    }
  },
  components: {
    Loading,
    ErrorHandler,
    CustomeTable
  },

  data() {
    return {
      
      payments: [],
      paymentError: false,
      paymentLoading: false,
      errorMessage: 2,
      show:false,
      headers: [
        {
          locale: "receipt_no",
          notation: false,
        },
        {
          locale: "transaction_date",
          notation: true,
          notation_locale: 'full_date_notation',
        },
        {
          locale: "amount",
          notation: true,
          notation_locale: 'egp',
        },
      ],
      actions: [
        {
          title: this.$t('show_receipt'),
          navigateTo: "PaymentReceipt",
          header_title:this.$t('receipt')
        }
      ]
    };
  },  
  watch: {
    selectedMeter() {
      this.payments =[];
      this.show=false;
      this.paymentError = false;
      if(this.selectedMeter && this.selectedMeter != "") {
        this.initialize();
      }
    },
 
  },
  created(){
    if(this.selectedMeter && this.selectedMeter != "") {
        this.initialize();
      }
  },
  methods: {
  async initialize() {
      this.paymentLoading = true;
      this.getPayments();
    },

   getPayments() {
     services
        .getPayment(this.selectedMeter)
        .then((data) =>{
          data.map((payment) => {
            payment.amount = payment.text;
            return payment;
          }         
          )
          this.show=true;
          this.payments = data;
        }
        )
        .then(() => (this.paymentLoading = false))
        .catch((err) => {
          this.paymentError = true;
          this.show=false;
          this.paymentLoading = false;
          this.payments = [];
          this.payments.splice(0,this.payments.length)
          this.errorMessage = err.message;
        });
    },

  //  formatNumber(data) {
  //   data = data.map(payment => {
  //     let rows = payment.amount.split("-");
  //     rows = rows.map(row => {
  //       let j= row.split(" ")[1];
  //       j = new Intl.NumberFormat('en-IN').format(j);
  //       row = row.split(" ");
  //       row[1]=j;
  //       row = row.join(" ")
  //       return row
  //     })
  //     payment = rows
  //     return payment
  //   })
  //   return data;
  // },
  },

};
</script>

<style scoped>
.title {
  color: #002369;
  font-size: 16px;
  font-weight: 700;
}
.table-container {
  /* overflow: auto; */
  min-height: 200px;
  max-width: 856px;
  margin: 0px auto;
  border-radius: 8px !important;
}
.btn-tertiary {
  background-color: transparent;
  border: 0;
  color: var(--main-color);
}
</style>

import axios from '../axios';
import ApiRequests from "./ApiRequests";
export default{
    rechargeMeter(amount , req_trx_master,due_pks,payment_method,response_type,lang){
      return  ApiRequests.apiRequest('post',`portal/paymentRequest`, null,{              
        req_pk: req_trx_master,
        due_pks:due_pks,
        amount: amount,
        payment_method:payment_method,
        checkout:"embedded",
        redirect:true,
        response_type:response_type,
        user_lang: lang,
    })
      .then(data=>{
      return data;
      })

    }
    
,

checkPayment(orderId,auth){  
  return  ApiRequests.apiRequest('post',`portal/checkPayment`,null, orderId ,auth)
  .then(data=>{
  return data;
  })
 
 },

 checkPaymentReceipt(payment_pk, lang) {
  return axios().post('portal/paymentDetails', { 
      payment_pk:payment_pk
  },{params : {
    user_lang:lang,
      
  }})
  .then(res => res.data)
    .then(res=>{
      if(res.STATUS==false) {
          throw new Error(res.Msg)
      }
       else {
        return res.Data
      }
    })
},
  fastRecharge(rechargeForm, total_amount, collection_fees,lang){
    let amount = (Number(total_amount) - Number(collection_fees)).toFixed(2);
     return axios().post(`portal/fastPaymentRequest`, {...rechargeForm, amount: amount,user_lang:lang},null  
    
      )
      .then(res => res.data)
      .then(res=>{
        if(res.STATUS==false) {
          if(res.CODE === -1 || res.CODE === -102)
            throw new Error(res.CODE);
          else 
            throw new Error(res.Msg)
        } else {
          return res.Data
        }
      })
    },

  meterDuePayments(Account_Reference, meter_id, lang) {
    return axios().get(`portal/getMinChargeValue`, {
      params: {
        account_reference: Account_Reference,
        meter_code: meter_id,
        user_lang:lang,
      }
    }
      )
      .then(res => res.data)
      .then(res=>{
        if(res.STATUS==false) {
          if(res.CODE === -1 || res.CODE === -102)
            throw new Error(res.CODE);
          else 
            throw new Error(res.Msg)
        } else {
          return res.Data
        }
      })
  },





}

<template>
  <div>

      <table >
        <thead>
          <tr>
            <th  scope="col">{{$t('screen_no')}}</th>
            <th  scope="col">{{$t('description')}}</th>
            <th  scope="col">{{$t('screen')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >C. 1. 5</td>
            <td >{{$t('desc_C_1_5')}}</td>
            <td >
              <img src="../../assets/meter_screens_images/electricity/Customer_Code.jpg" />
            </td>
          </tr>
          <tr>
            <td >2. 9. 0</td>
            <td >{{$t('desc_2_9_0')}}</td>
            <td >
              <img src="../../assets/meter_screens_images/electricity/Date.jpeg" />
            </td>
          </tr>
        
          <tr>
            <td >0. 8. 1</td>
            <td >{{$t('desc_0_8_1')}}</td>
            <td >
              <img src="../../assets/meter_screens_images/electricity/Total_Consumption.jpg" />
            </td>
          </tr>
          <tr>
            <td >0. 9. 1</td>
            <td >{{$t('desc_0_9_1')}}</td>
            <td >
              <img
                src="../../assets/meter_screens_images/electricity/Current_Consumption.jpeg"
              />
            </td>
          </tr>
        
          <tr>
            <td >0. 7. 3</td>
            <td >
              {{$t('desc_0_7_3')}}
            </td>
            <td >
              <img
                src="../../assets/meter_screens_images/electricity/Reactive_Power_Import_Total.jpeg"
              />
            </td>
          </tr>
       
          <tr>
            <td >F.F. 0</td>
            <td >{{$t('desc_F_F_0')}}</td>
            <td >
              <img src="../../assets/meter_screens_images/electricity/Errors_Code.jpeg" />
            </td>
          </tr>
          <tr>
            <td >C.6 0.18</td>
            <td >{{$t('desc_C_6_18')}}</td>
            <td >
              <img
                src="../../assets/meter_screens_images/electricity/Current_Monthly_Consumption_Value.jpg"
              />
            </td>
          </tr>
        
          <tr>
            <td >0. 2. 0</td>
            <td >
              {{$t('desc_0_2_0')}}
            </td>
            <td >
              <img src="../../assets/meter_screens_images/electricity/Firmware_Version.jpg" />
            </td>
          </tr>
        
         
       
         
        </tbody>
      </table>
    </div>
  

  <!-- src\assets\meter_screen_images\Remaining_Money.jpg -->
</template>
<script>
import { getCookie } from '../../utils';
export default {
  data() {
    return {
      get lang() {
        return getCookie('lang')
      },
    };
  },
  methods: {},
};
</script>
<style scoped>
table tr td {
  font-size: 18px;
  vertical-align: middle;
}
@media (max-width:760px){
 table tr td {
  font-size:13px;
  padding: 5px !important;
} 
img{
  width:100% !important;
}
}
table * {
  text-align: center;
}

table tbody tr td:first-child {
  direction: rtl;
}

table tr td img {
  width: 280px;
  height: 80px;
}

</style>